import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import reduceReducers from 'reduce-reducers';
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import {
	keyErrandChangeErrandArea,
	keyErrandChangeInternalState,
	keyFetchClientAddress,
	keyFetchClientsAddressList,
	keyFetchErrandNotes,
	keyFetchExtendedData,
	keyFetchExternalExpertList,
	keyFetchExternalExpertThread,
	keyFetchExternalQueries,
	keyFetchHistory,
	keyAcquireErrand,
	keyErrandContacts,
	keyErrandMyErrands,
	keyFetchLinkedErrands,
	keyErrandContactsHistories,
	keyErrandUploadAnswerAttachment,
	keyErrandRemoveTemporaryAttachment,
	keyErrandAreaData,
	keyExternalexpertEdit,
	keyLoadBasicErrand,
	keyOtherContactHistory,
	keyTurnExternalExpertLightOff,
	keySendAnswer,
	keyUpdateAnswer,
	keySendEE,
	keyUpdateAnswerEE,
	keySavedEE,
	keyUpdateManualErrand,
	keyReopenErrand,
	keyResendAnswer,
	keyPublishErrand,
	keyUnpublishErrand,
	keyErrandSuggestToLibrary,
	keyGetOneRelatedErrand,
	keyDeleteOneErrandNotes,
	keyCreateOneErrandNotes,
	keyUpdateOneErrandNotes,
	keyErrandPostponeDate,
	keyErrandPrintContent,
	keyErrandFetchAreaTags,
	keyFeaturedQuestion,
	keyErrandFBRatings,
	keyAnonymize,
	keyDataExportLog,
	keyExportContact,
	keyDeleteExportContact,
	keyHasMembership,
	keyRecordEditorSize,
	keyErrandFBEmotionlist,
	keyOpenErrandData,
	keyErrandMinLoad,
	keyFetchUserVote,
	keyPostUserVote,
	keyAgentWorkingOnErrand,
	keyCompanyContactHistories,
	keyCompanyOtherContacts,
	keyErrandSuggestedAnswer,
	keyLinkErrand,
	keyGenAIAnswer,
	keyErrandWorkflowSettings
} from '../constants/keys';
import {
	ACQUIRING_ERRANDS,
	ACTIVATE_ANSWERED_VIEW,
	ADD_FILE_ARCHIVE_TO_COLLABORATION,
	ADD_FILE_ARCHIVE_TO_MANUAL,
	AGENT_BUSY_TYPING,
	AGENTEE_BUSY_TYPING,
	AGENT_NOT_TYPING,
	AGENTEE_NOT_TYPING,
	APP_READY,
	CHANGE_OTHER_CONTACT_SHOW_IDS,
	CHANGE_REPLY_OPTION,
	CHAT_SWITCH_OC_ERRAND,
	CKEDITOR_REFORMAT_INPUT,
	CKEDITOR_REFORMAT_INPUTEE,
	CLEAR_COLLABORATE_ANS_ATTACHMENT,
	DELETE_SAVED_ATTACHMENT,
	DELETE_SAVED_COL_ATTACHMENT,
	DELETE_UPLOADED_MANUAL_ATTACHMENT,
	DELETE_ALL_UPLOADED_MANUAL_ATTACHMENT,
	CHANGE_COLLABORATION_ROLE,
	CLEAR_SELECTED_MANUAL_ARCHIVE,
	COL_CLEAR_SELECTED_ARCHIVE,
	COL_CLEAR_SELECTED_LIBRARY,
	DELETE_SAVED_INTERNAL_COMMENT_ATTACHMENT,
	DELETE_UPLOADED_COL_ATTACHMENT,
	DELETE_UPLOADED_INTERNAL_COMMENT_ATTACHMENT,
	DELETE_UPLOADED_MANUAL_INTERNAL_COMMENT_ATTACHMENT,
	DELETE_NOTE_ATTACHMENT,
	ERRAND_ATTACHMENT_LIST_DISPLAY,
	ERRAND_OPENED_AND_READY,
	HAS_OTHER_OPEN,
	INITIAL_OPEN_ERRAND_THREAD_ID,
	INSERT_COLLABORATE_ANS_ATTACHMENT,
	INVALIDATE_LAST_SAVE_START,
	SELECT_FORWARD_ALL_HISTORIES,
	SELECT_INCLUDE_COLLAB_HISTORY,
	SELECT_INCLUDE_ERRAND_HISTORY,
	SELECT_FORWARD_ALL_Q_ATTACHMENTS,
	SET_CURRENT_ERRAND,
	SET_PREVIOUS_ERRAND,
	CURRENT_ERRAND_OPENING,
	SYNC_REVIEW_DATA,
	UPDATE_CURRENT_ERRAND_HAS_EE,
	MANUAL_ERRAND_MAX_RECIPIENT,
	MANUAL_ERRAND_BUSY,
	MANUAL_CALL_BUSY,
	MANUAL_FOOTER_CHECKBOXES,
	MARK_LAST_SAVE_TRIGGERED,
	MARK_EE_LAST_SAVE_TRIGGERED,
	MOVE_OPEN_TO_ASSOCIATE,
	MOVE_LINKED_TO_ASSOCIATE,
	NO_SCROLL_TO_QUESTION,
	NOTE_SIZE,
	EXPAND_HIDE_OPTION,
	CLEAR_INTERNAL_COLLABORATORS,
	SELECT_FORWARD_HISTORY,
	SET_CLEAR_PREVIEW_SAVE_EML_BUSY,
	PRE_SELECT_FORWARD_HISTORY,
	SELECT_EXTERNALFORWARD_ATTACHMENT,
	SELECT_MANUAL_REPLY,
	SELECT_MANUAL_ERRAND,
	SELECT_MANUAL_ERRAND_CREATION,
	SELECT_MANUAL_CALL_CREATION,
	SELECT_MANUAL_SOCIAL_MEDIA_ACCOUNT,
	SELECT_EXIST_ATTACHMENT,
	SELECT_QUESTION_ATTACHMENT,
	SELECT_ALL_QUESTION_ATTACHMENT,
	SELECT_REPLY_CHANNEL,
	SELECT_COLLAB_REPLY_CHANNEL,
	SELECT_REPLY_TOGGLE_SHOW,
	SELECT_TOGGLE_REPLY_CHANNEL,
	SELECT_TOGGLE_REPLY_ACTION,
	SHOW_HIDE_OCE_POPUP,
	SYNC_ACQUIRE_DATA,
	SYNC_ACQUIRED_STATE,
	SYNC_ACQUIRED_OWNER,
	SYNC_ALL_BASIC_ERRAND_DATA,
	SYNC_EXTENDED_DATA,
	SYNC_OTHER_CONTACT_ERRANDS,
	SYNC_RELATED_ERRANDS,
	TOGGLE_SHOW_RECIPIENTS,
	TOGGLE_SHOW_REPLY_TOOLBAR,
	TOGGLE_SHOW_REPLY_ASSIST,
	TOGGLE_SHOW_SUBJECT,
	TOGGLE_REPLY_OPTION_TAB,
	UPLOAD_COLLABORATION_ATTACHMENT,
	UPLOAD_INTERNAL_COMMENT_ATTACHMENT,
	UPLOAD_MANUAL_INTERNAL_COMMENT_ATTACHMENT,
	UPLOAD_MANUAL_ERRAND_ATTACHMENT,
	UPLOAD_MANUAL_CALL_ERRAND_ATTACHMENT,
	UPLOAD_CUSTOMER_NOTE_ATTACHMENT,
	INPUT_TEXT_CHANGES,
	INPUT_TEXT_RESET,
	ERRAND_TAGS_OPERATION,
	ACQUIRE_ERRAND_OPERATION,
	CLEAR_COLLABORATION_INPUTS,
	CLEAR_MANUAL_ERRAND_INPUTS,
	CLEAR_MANUAL_CALL_INPUTS,
	DEF_MANUAL_ACC_ADDR,
	ERRAND_AREA_OPERATION,
	XFER_CHANGED_DOMAIN_AREA_DATA,
	XFER_DOMAIN_AREA_DATA,
	XFER_DOMAIN_EXTEND_DATA,
	XFER_DOMAIN_EE_EDIT,
	XFER_DOMAIN_EE_QUERY,
	XFER_DOMAIN_EE_LIST,
	RELOAD_CURRENT_EXPERT,
	SET_ERRAND_HEADER_MOBILE_VIEW,
	SET_ERRAND_OPTION_MOBILE_VIEW,
	TOGGLE_REPLYBOX_ERRANDS_MENU,
	SET_NAV_BUTTON_BUSY,
	EDIT_ERRAND_NOTES,
	ERRAND_NOTES_OPERATION,
	ERRAND_CONFIRM_CANCEL,
	TOGGLE_LOCK_TO_ME,
	TOGGLE_ERRAND_PRIORITY,
	UPDATE_COLLABORATE_CB,
	UPDATE_EXPERT_ANS_STATE,
	UPDATE_EXPERT_IDS,
	UPDATE_INPUT_TAGS,
	UPDATE_REPLY_CHECKBOXES,
	SET_ERRAND_WF_SETTINGS,
	ERRAND_CLASSIFICATION,
	CLASSIFICATION_TAGS_OPERATION,
	RECIPIENTS_CHANGE,
	ERRAND_POST_ANSWER,
	ERRAND_ANSWER_STATE,
	CONTROL_FORWARD_TO_ACTION,
	ERRAND_SHOULD_POP_PRINT,
	TOGGLE_PINTOP_ERRAND,
	ERRAND_DUEDATE_OPERATION,
	SOCIAL_MEDIA_ERRANDS_ACTIONS,
	SOCIAL_MEDIA_UI_ACTION,
	SOCIAL_MEDIA_MSG_SAVE_ACTION,
	ERRAND_ERROR_NOTIF_POPUP,
	SELECTED_PERSONALIZATION,
	SELECTED_PERSONALIZATION_DEFAULT,
	UPLOAD_ERRAND_ANSWER_ATTACHMENT,
	TOGGLE_ERRAND_ATTACHMENT_LIST,
	TOGGLE_ERRAND_VISITOR_PATH,
	DELETE_UPLOADED_ATTACHMENT,
	SHOW_UPLOADED_ATTACHMENT_BOX,
	ADD_FILE_ARCHIVE_TO_ANSWER,
	ADD_LIBRARY_FILE_TO_ANSWER,
	ADD_LIBRARY_FILE_TO_COLLABORATE,
	SELECTED_LIBRARY_QUESTION_TO_ANSWER,
	TOGGLE_FILE_ARCHIVE,
	CLEAR_SELECTED_ARCHIVE,
	CLEAR_SELECTED_LIBRARY,
	TOGGLE_ATTACHMENT_PLUS_MINUS,
	SET_COLLABORATION_TRANSLATE_TO,
	SET_TEXT_FILE_PREVIEW_CONTENT,
	SET_CSV_FILE_PREVIEW_CONTENT,
	SET_ANSWER_TRANSLATION,
	SELECT_ERRAND_LIBRARY,
	SEARCH_ERRAND_LIBRARY,
	RESET_LIBRARY_SEARCH,
	SET_SHOW_LIBRARY_ANSWER,
	SET_LIBRARY_SEARCH_TEXT,
	SHOW_ERRAND_LIBRARY_POPUP,
	SHOW_ERRAND_LIBRARY_LIST,
	CLEAR_LIBRARY_SEARCH_TIMEOUT,
	SHOW_CONTACT_BOOK,
	SHOW_CONTACT_CARD,
	SHOW_CONTACT_CARD_HISTORY,
	SHOW_CONTACT_CARD_CHANNEL,
	SET_CONTACT_CARD,
	UPDATE_CONTACT_CARD,
	UPDATE_CONTACT_BOOK,
	SELECT_CONTACT_CARD_ANSWERED_HISTORY,
	SELECT_CONTACT_CARD_UNANSWERED_HISTORY,
	SELECT_CONTACT_CARD_OPEN_ERRAND,
	SET_CUSTOMER_NOTES,
	UPDATE_CUSTOMER_NOTE,
	APPEND_REPLY_ADDRESS,
	SET_REPLY_ADDRESS,
	SET_REPLY_ADDRESS_TYPE,
	SET_CHAT_TRANSLATION,
	CHECK_ANONYMIZE,
	GRANT_EXPORT,
	REVOKE_GRANTED_ACCESS,
	SET_SELECTED_LANG,
	SET_SELECTED_TZ,
	SET_MANUAL_ERRAND_TRANSLATE_TO,
	HANDLE_POPUP_FILTER_BY_HISTORY_ELEMENTS,
	ERRAND_NOTES_COUNT,
	UPDATE_REPLY_TO_AND_CHANNEL,
	ADD_LIBRARY_FILE_TO_MANUAL,
	DELETE_KB_MANUAL_ATTACHMENT,
	UPDATE_MANUAL_ERRAND_SAVED_ATTACHMENTS,
	UPDATE_TWEET_BOX_TOGGLE_NUMBER_OF_TWEET,
	TOGGLE_EXTERNAL_DATA,
	TOGGLE_CHAT_RATING_BOX,
	SET_CHAT_SATISFACTION_INPUT,
	RESET_AGENT_SATISFACTION_INPUT,
	TOGGLE_CLIENT_CHAT_RATING_BOX,
	TOGGLE_POSTPONE_ERRAND,
	SET_CONTACT_BOOK_UI,
	KB_UPDATE_USERVOTE_COMMENT,
	KB_UPDATE_USERVOTE_VOTE,
	KB_UPDATE_USERVOTE_EDIT,
	KB_UPDATE_USERVOTE_WARNING,
	SELECT_HISTORY_ATTACHMENT,
	SELECT_ALL_HISTORY_ATTACHMENT,
	SHOW_COMPANY_INFO,
	SET_ERRAND_COUNTDOWN_TIMER,
	ERRAND_TOGGLE_RIGHT_PANEL,
	ERRAND_TOGGLE_CHAT_SIDEPANEL,
	ERRAND_SET_FOLDER_INDEX,
	ERRAND_TOGGLE_RIGHT_SIDEPANEL,
	TOGGLE_CHAT_REPLY_NAV_DD,
	SELECT_CHAT_REPLY_TABS,
	SHOW_REPLY_SEARCH_SHORTCUT_DROPDOWN,
	SHOW_REPLY_SEARCH_INPUT,
	HIDE_REPLY_SEARCH,
	INPUT_CK_RESET,
	WHATSAPP_TEMPLATE_POPUP,
	SET_WHATSAPP_TEMPLATE_CONTENT,
	SAVE_WHATSAPP_TEMPLATE_CODE,
	SHOW_HIDE_ACQUIRE_POPUP,
	SELECT_ALL_ACQUIRE_ERRAND,
	DESELECT_ALL_ACQUIRE_ERRAND,
	SELECT_MORE_ACQUIRE_ERRAND,
	SET_AREA_RECIPIENT,
	SELECT_MANUAL_CALL_REPLY,
	WS_EVENT,
	SET_QUICK_REPLY_CONTENT,
	SET_CRM_EMAILTRIGGER_CONTENT,
	SELECT_ALL_LINK_ERRAND_MY_ERRAND,
	SELECT_ALL_LINKED_ERRAND,
	SHOW_ERRAND_REWRITE_ANSWER_POPUP,
	SET_REWRITE_ANSWER_QUESTION,
	UPDATE_REWRITE_ANSWER_STATE,
	RESET_REWRITE_ANSWER,
	COLLAB_QUERY_THREAD_EXPAND,
	EXPAND_KB_PANEL,
	SET_KB_TREE_PARENT,
	SET_SUGGESTED_ANSWER_USED
} from '../constants/constants';
import {
	actionReducerCreator,
	asyncInitState,
	initWithOpr,
	createReducer,
	createReducerAsyncDataBranch,
	createReducerSubBranch,
	defAsyncReducer,
	defMCAMReducer,
	externalExpertEditDomainID,
	oprAsyncInitState,
	asyncDoneActionType,
	asyncRequestActionType,
	mcamCountersReducer,
	mcamByID,
	addNormalizedDomain,
	domainTransferReducer,
	reduceAsync,
	sameContentIdInAttachments,
	selectOprDataAsync
} from '../util';
import {
	AET_OPEN,
	JUST_LOADED_STR,
	OPEN_STR,
	ROLE_AGENT,
	COL_COMPLETE_ERRAND_HISTORIES,
	COL_SAVE_RECIPIENT,
	DEF_ALL_MANUAL_CHECKBOXES_STATE,
	DEF_REPLY_CHECKBOXES_STATE,
	ECB_INC_HISTORIES,
	ECB_INC_QUESTION,
	ECB_LOCK2ME,
	ECB_PARTIAL_ANSWER,
	ECB_SUGGEST_TO_LIBRARY,
	EOPR_ANSWERED,
	EXPERT_ANS_IDLE,
	EXT_EXPERT_EDIT_ATTACHMENT_TYPE,
	FETCH_AREA_DATA_STR,
	D_AREAS,
	D_BASIC_ERRANDS,
	D_EE_EDIT,
	D_ERRAND_NOTES,
	D_FORWARD_AGENTS,
	D_HISTORY,
	INPUTS_MANUAL_ERRAND,
	INPUTS_MANUAL_CALL,
	INPUTS_OPEN_ERRAND,
	INPUTS_COLLABORATION,
	LSSS_INVALID,
	LSSS_VALID,
	ME_CREATE,
	ME_CREATE_ALLOWED_CHANNELS,
	ME_CREATE_AS_NEW,
	ME_ST_BUSY,
	ME_ST_CREATED,
	ME_ST_IDLE,
	NEW_MANUAL_ERRAND_ID,
	OTHER_CONTACTS_FETCH_ALL_SIZE,
	OTHER_CONTACTS_FETCH_SIZE,
	RC_EMAIL,
	RC_SLACK,
	RPLY_ERRAND,
	RPLY_QUESTION,
	RPLY_EXT_FWD,
	RPLY_COLLABORATE,
	RPLY_MANUAL,
	SEL_ACT_ACQ_ERD_OTHER,
	// SEL_ACT_ACQ_ERD_HISTORY,
	SEL_ACT_ACQ_SELECT,
	SEL_ACT_OTHER_CONTACT,
	SEL_ACT_MY_ERD,
	SEL_ACT_LINKED_ERD,
	TAG_ADD,
	TAG_DELETE,
	TAG_CLEAR,
	UNSELECT,
	UNSELECT_STR,
	ICST_UNKNOWN,
	ICST_NO_DATA,
	ICST_DATA_FETCHED,
	NOTEOPR_CREATE_NEW,
	NOTEOPR_DEFAULT,
	E_A_UNKNOWN,
	RC_LINE,
	FILE_EXCEED_MESSAGE,
	DEL_ANSWER_ATTACHMENT,
	FILTER_ALL_MESSAGE,
	FILTER_BY_AGENT,
	FILTER_BY_SUMMARIZED_HISTORY,
	FILTER_BY_CUSTOMER,
	FILTER_BY_COLLABORATION,
	FILTER_BY_INTERNAL_MESSAGE,
	//REPLY_CHANNEL_TO_SERVICE_TYPE,
	emptyArray,
	emptyObject,
	RECIPIENT_TO,
	RECIPIENT_CC,
	RECIPIENT_BCC,
	RECIPIENT_FORWARD,
	RECIPIENT_INTERNAL_COLLABORATE,
	CB_CONTACT_CARD,
	CB_ALL_CONTACT,
	ADD_TO_CONTACT_CARD,
	INC_ALL_HISTORY_ATTACHMNET,
	RC_MSTEAMS,
	RC_JIRA,
	RC_GOOGLECHAT,
	AET_OPENTYPE_OWN
} from '../../common/v5/constants'
import { errand as errandActions } from '../actions/async/errand';
import { workflow as workflowActions } from '../actions/async/workflow';
import {
	ERRAND_HISTORIES,
	createDataSyncReducer,
	createSetupCondtionReducer
} from './wire';
import {
	domainWFExpertQueries,
	syncOneErrandOnNormalizedList,
	syncRelatedErrandsNormalized
} from './workflow';
import {
	isInsideAreaTags,
	IsContainObject,
	convertTagIdToTagLevelId,
	compareText
} from '../../common/v5/helpers';
import {
	boolStringToBool
	, csvStringToArray
	, csvStringToIntArray
} from '../../common/helpers';
import {
	currentChatReducer,
} from './agentsocket.js';
import { errandNotesCount, uiKnowledgebaseReducerMap } from '../actions/errand';
import { checkIfReviewExternalForward } from '../selectors/errand';
import { getUniqueChannels } from '../selectors/admin';
import {
	evtCHAT_ERRAND_ADD,
	evtCHAT_REGISTER
} from '../../redux/constants/constants';

const req = key => asyncRequestActionType(errandActions[key]);

const done = key => asyncDoneActionType(errandActions[key]);
const wfDone = key => asyncDoneActionType(workflowActions[key]);

const workingOnErrand = () => defAsyncReducer(keyAgentWorkingOnErrand,
	asyncInitState);

const suggestedAnswer = () => defAsyncReducer(keyErrandSuggestedAnswer,
	asyncInitState);

const changeErrandArea = () => defMCAMReducer(keyErrandChangeErrandArea,
	asyncInitState, 'PassiveChangeErrandArea');

const changeErrandPostponedate = () => defAsyncReducer(keyErrandPostponeDate,
	asyncInitState, 'ErrandPostponeDate');

const fetchClientAddress = () => defAsyncReducer(keyFetchClientAddress,
	asyncInitState);

const fetchPrintContent = () => defAsyncReducer(keyErrandPrintContent,
	asyncInitState);
const fetchRatings = () => defAsyncReducer(keyErrandFBRatings, asyncInitState);

const fetchReactions = () => defAsyncReducer(keyErrandFBEmotionlist, asyncInitState);

const externalExpertEdit = () => defAsyncReducer(keyExternalexpertEdit,
	asyncInitState);

const fetchClientsAddressList = () => defMCAMReducer(keyFetchClientsAddressList,
	asyncInitState, 'FetchClientsAddressList');

const clientAddressListWithOtherContact = () => defAsyncReducer(
	keyErrandContacts
	, initWithOpr
	, (state, action) => {
		const { data, time } = action.payload;
		const { clientName, clientAddress, clientNotesCount, suggestAddTo } = data;
		const obj = {
			clientName: clientName,
			clientAddress: clientAddress,
			clientNotesCount: clientNotesCount,
			suggestAddTo: suggestAddTo
		};
		return update(state, { data: { $set: obj } });
	}
);

const fetchExtendedData = () => defMCAMReducer(keyFetchExtendedData,
	asyncInitState, 'fetchExtendedData');

const fetchExtendedDataMCAM = () => defMCAMReducer(keyOpenErrandData,
	asyncInitState, 'fetchExtendedData');

const fetchHistoryMCAM = () => defAsyncReducer(
	keyOpenErrandData
	, initWithOpr
	, (state, action) => {
		const mcam = action.payload.data, preOpr = state.data.opr
			, { data } = mcamByID('fetchHistoryData', mcam)
			;
		let opr = {};
		if (data && data.length) {
			$.each(data, (i, v) => {
				const id = v.eid, o = preOpr[id];
				if (typeof o === "undefined") {
					opr[id] = { selected: false };
				} else {
					opr[id] = o;
				}
			});
		}
		return update(state, { data: { $merge: { opr, data } } });
	}
);

const fetchAreaTags = () => defMCAMReducer(keyErrandFetchAreaTags,
	asyncInitState, 'FetchAreaTags');

// const eeList = () => defAsyncReducer(keyFetchExternalExpertList,
// 	asyncInitState);

const domainEEList = (state, action) => {
	const { data, param, time } = action.payload;
	return addNormalizedDomain(state, 'externalExpertList', param.errand, data,
		time);
};

const domainEEThread = (state, action) => {
	const { data, param, time } = action.payload;
	return addNormalizedDomain(state, 'externalExpertThreads', param.thread,
		data, time);
};

const domainEEEdit = (state, action) => {
	const { data, param, time } = action.payload,
		{ errand, query, answer } = param;
	return addNormalizedDomain(state, D_EE_EDIT,
		externalExpertEditDomainID(errand, query, answer), data, time);
};

const domainErrandNotes = (state, action) => {
	const { data, param, time } = action.payload;
	return addNormalizedDomain(state, D_ERRAND_NOTES, param.errand, data, time);
};

const domainDeleteErrandNotes = (state, action) => {
	const { data, param } = action.payload;
	if (!state[D_ERRAND_NOTES].byId[param.errand]) {
		return state;
	}
	const notes = state[D_ERRAND_NOTES].byId[param.errand].notes;
	let found, index;
	$.each(notes, (i, v) => {
		if (v.id === param.id) {
			found = true;
			index = i;
			return false;
		}
	});
	if (found) {
		return update(state, {
			[D_ERRAND_NOTES]: {
				byId: {
					[param.errand]: {
						notes: {
							$splice: [[index, 1]]
						}
					}
				}
			}
		});
	}
	return state;
};

const domainUpdateErrandNotes = (state, action) => {
	const { data, param } = action.payload;
	if (!state[D_ERRAND_NOTES].byId[param.errand]) {
		return state;
	}
	const notes = state[D_ERRAND_NOTES].byId[param.errand].notes;
	if (!data.note) {
		return state;
	}
	let found, index;
	$.each(notes, (i, v) => {
		if (v.id === param.id) {
			found = true;
			index = i;
			return false;
		}
	});
	if (found) {
		return update(state, {
			[D_ERRAND_NOTES]: {
				byId: {
					[param.errand]: {
						notes: {
							$splice: [[index, 1, data.note]]
						}
					}
				}
			}
		});
	}
	return state;
};

const domainAddErrandNotes = (state, action) => {
	const { data, param, time } = action.payload;
	if (!data.note) {
		return state;
	} else if (!state[D_ERRAND_NOTES].byId[param.errand]) {
		return addNormalizedDomain(state, D_ERRAND_NOTES, param.errand,
			{ notes: [data.note], type: 'errand' }, time);
	}
	return update(state, {
		[D_ERRAND_NOTES]: {
			byId: {
				[param.errand]: {
					notes: {
						$push: [data.note]
					}
				}
			}
		}
	});
};

// const eequeryEmailsRE = /^.*?: (.*)\r?\n(\d+?) .*$/m,
// 	emailNameRE = /^(.*) &lt;(.*?)(&gt;)?$/;
//
// const fetchExternalQueries = () => defAsyncReducer(keyFetchExternalQueries,
// 	asyncInitState, (state, action) => {
// 		const { data } = action.payload, eeq = data.mcam.channels[0].content,
// 			emailList = eequeryEmailsRE.exec(eeq);
// 		if(!emailList) {
// 			return state;
// 		}
// 		// TODO: this endpoint call should return JSON data can easily be
// 		// digested to get the target experts and total answer received from
// 		// external-experts.
// 		const emailsArray = emailList[1].split('&gt;,'),
// 			answers = parseInt(emailList[2], 10);
// 		let emails = [];
// 		$.each(emailsArray, (i,v) => {
// 			const e = emailNameRE.exec(v);
// 			if(e) {
// 				emails.push({name: e[1], email: e[2]});
// 			} else {
// 				emails.push({name: v, email: v});
// 			}
// 		});
// 		return update(state, {data: {$set: {emails, answers}}});
// 	}
// );
//
// const expertQueriesReducer = (state, action) => {
// 	const processed = processExpertQueries(action);
// 	if(!processed) {
// 		return state;
// 	}
// 	return update(state, {data: {$set: processed}});
// };

const fetchExternalQueries = () => defAsyncReducer(
	keyFetchExternalQueries,
	asyncInitState
);

const fetchHistory = () => defAsyncReducer(keyFetchHistory, initWithOpr,
	(state, action) => {
		const { data } = action.payload.data, preOpr = state.data.opr;
		let opr = {};
		if (data && data.length) {
			$.each(data, (i, v) => {
				const id = v.eid, o = preOpr[id];
				if (o === undefined) {
					opr[id] = { selected: false };
				} else {
					opr[id] = o;
				}
			});
		}
		return update(state, { data: { $merge: { opr, data } } });
	}
);

const checkIfAllSelected = opr => {
	let allSelected = true;
	$.each(opr, function (k, v) {
		if (k != "all" && !v.selected) {
			allSelected = false;
			return false;
		}
	});
	return allSelected;
}

const updateForwardAllReducer = (state, action) => {
	const { opr } = state.data;
	if (checkIfAllSelected(opr)) {
		state = update(state, { data: { opr: { all: { $set: true } } } });
	} else if (opr.all) {
		state = update(state, { data: { opr: { all: { $set: false } } } });
	}
	return state;
}

function changeSelectedOprIfNotSame(opr, key, value, check) {
	if (value.selected != check) {
		opr[key] = update(value, { selected: { $set: check } });
	} else {
		opr[key] = value;
	}
}

function onlySelectSameId(state, action, opr) {
	const { id } = action.payload
		;
	opr.all = true;
	return (k, v) => {
		if (k === "all") {
			return;
		}
		if (k == id) {
			changeSelectedOprIfNotSame(opr, k, v, true);
		} else {
			if (opr.all) {
				opr.all = false;
			}
			changeSelectedOprIfNotSame(opr, k, v, false);
		}
	};
}

function allSameSelection(selected) {
	return (state, action, opr) => {
		if (typeof action.payload !== "undefined" &&
			typeof action.payload.value !== "undefined" &&
			action.payload.value) {
			opr.all = true;
		} else {
			opr.all = false;
		}
		return (k, v) => {
			if (k === "all") {
				if (v != selected) {
					opr[k] = selected;
				} else {
					opr[k] = v;
				}
			} else {
				changeSelectedOprIfNotSame(opr, k, v, selected);
			}
		};
	};
}

function getNewOprFromSelectionOpr(state, action, mutator) {
	let opr = {}
		;
	const prevOpr = state.data.opr
		, looper = mutator(state, action, opr)
		;
	$.each(prevOpr, looper);
	return opr;
}

function loopAllSelectionOprSubReducer(state, action, useAllTrueMutator) {
	let mutator;
	if (useAllTrueMutator) {
		mutator = allSameSelection(true);
	} else {
		mutator = onlySelectSameId;
	}
	return update(state, {
		data: {
			$merge: {
				opr: getNewOprFromSelectionOpr(state, action, mutator)
			}
		}
	});
}

const selectFwdReducer = (state, action) => {
	if (action.type === SELECT_FORWARD_HISTORY) {
		const { id, value } = action.payload;
		if (!state.data.opr[id]) {
			return state
		}
		return updateForwardAllReducer(
			update(state, { data: { opr: { [id]: { selected: { $set: value } } } } }),
			action
		);
	} else if (action.type === PRE_SELECT_FORWARD_HISTORY) {
		return loopAllSelectionOprSubReducer(state, action, false);
	} else if (action.type === SELECT_FORWARD_ALL_HISTORIES || action.type === SELECT_COLLAB_REPLY_CHANNEL) {
		return loopAllSelectionOprSubReducer(state, action, action.payload.value);
	}
	return state;
};

const acquireErrand = () => defAsyncReducer(
	keyAcquireErrand
	, initWithOpr
	, (state, action) => {
		const { data } = action.payload
			, acquire = mcamByID('acquireErrand', data)
			;
		if (acquire.error) {
			return update(state, { err: { $set: new Error(acquire.error) } });
		}
		const { data: mcamData, errand } = acquire
			, relatedList = mcamData.related_errands
			, preOpr = state.data.opr
			;
		let opr = {}
			, order = []
			, allSelected = true
			;
		if (relatedList && relatedList.length) {
			order = relatedList;
			$.each(order, (i, v) => {
				const o = preOpr[v];
				if (o === undefined) {
					opr[v] = { selected: v !== errand, async: asyncInitState };
				} else {
					if (allSelected && !o.selected) {
						allSelected = false;
					}
					opr[v] = o;
				}
			});
		}
		return update(state, {
			data: {
				$merge: {
					allSelected,
					order,
					opr,
					acquire
				}
			}
		});
	}
);

const getOneRelatedErrandAsyncReducer = reduceAsync(keyGetOneRelatedErrand, {},
	(state, action) => { // request
		const { id } = action.payload.param
			, obj = state.data.opr ? state.data.opr[id] : null
			;
		if (!obj) {
			return state;
		}
		return update(state, {
			data: {
				opr: {
					[id]: {
						async: {
							$merge: { wip: true, xhr: null, err: null }
						}
					}
				}
			}
		});
	},
	undefined, // start
	(state, action) => { // done
		const { param, data } = action.payload
			, { id } = param
			, obj = state.data.opr ? state.data.opr[id] : null
			;
		if (!obj) {
			return state;
		}
		return update(state, {
			data: {
				opr: {
					[id]: {
						async: {
							$merge: { wip: false }
						}
					}
				}
			}
		});
	},
	(state, action) => { // fail
		const { param, err } = action.payload
			, { id } = param
			, obj = state.data.opr ? state.data.opr[id] : null
			;
		if (!obj) {
			return state;
		}
		return update(state, {
			data: {
				opr: {
					[id]: {
						async: {
							$merge: { wip: false, err }
						}
					}
				}
			}
		});
	}
);

const getOneRelatedErrandAsyncDomainReducer = (state, action) => {
	const { data, time } = action.payload;
	return addNormalizedDomain(state, D_BASIC_ERRANDS, data.id, data, time);
};

const basicErrand = () => defAsyncReducer(keyLoadBasicErrand, asyncInitState);

const basicErrandMCAM = () => defAsyncReducer(
	keyOpenErrandData
	, initWithOpr
	, (state, action) => {
		const mcam = action.payload.data
			, { data } = mcamByID('fetchBasicData', mcam)
			;
		if (data) {
			return update(state, { data: { $set: data } });
		}
	}
);

const domainBasicErrand = (state, action) => {
	const { data, time } = action.payload;
	return addNormalizedDomain(state, D_BASIC_ERRANDS, data.id, data, time);
};

const domainBasicErrandMCAM = (state, action) => {
	const { data, param, time } = action.payload
		, basic = mcamByID('fetchBasicData', data)
		;
	return addNormalizedDomain(state, D_BASIC_ERRANDS, basic.id, basic, time);
};

const domainFetchHistory = (state, action) => {
	const { data, param, time } = action.payload;
	return addNormalizedDomain(state, D_HISTORY, param.threadId, data, time);
};

const domainFetchHistoryMCAM = (state, action) => {
	const { data, param, time } = action.payload
		, history = mcamByID('fetchHistoryData', data)
		;
	return addNormalizedDomain(state, D_HISTORY, param.threadId, history, time);
};

const initOtherContactsData = {
	allSelected: false,
	errandId: 0,
	hasOpen: false,
	notFresh: false,
	noMore: false,
	acquiringWip: false,
	opr: emptyObject,
	order: [],
	norm: emptyObject,
	list: null
}
	, initOtherContacts = update(asyncInitState, {
		data: { $set: initOtherContactsData }
	})
	;

const initErrandMyErrandsData = {
	allSelected: false,
	opr: emptyObject,
	linkedOpr: emptyObject,
	order: [],
	norm: emptyObject,
	list: null
	}
	, initErrandMyErrands = update(asyncInitState, {
		data: { $set: initErrandMyErrandsData }
	})
	;

const initErrandLinkedErrandsData = {
		allSelected: false,
		opr: emptyObject,
		linkedOpr: emptyObject,
		order: [],
		norm: emptyObject,
		list: null
		}
		, initErrandLinkedErrands = update(asyncInitState, {
			data: { $set: initErrandLinkedErrandsData }
		})
		;
const otherContactsSubReducer = (state, action, withoutOpr) => {
	const { data, param } = action.payload
		, d = state.data
		;
	if (data.parentId !== d.errandId) {
		// this request is not for current open errand
		return state;
	}
	const list = data.list
		, { offset, limit } = param
		;
	let mergeData = { list };
	if (list && list.length) {
		const preNorm = d.norm;
		let order = [], norm = {};
		$.each(list, (i, v) => {
			const id = v.id;
			if (!preNorm[id]) {
				order.push(id);
			}
			norm[id] = v;
		});
		// always merge the latest data because data may changed due to anonymize
		mergeData.norm = update(preNorm, { $merge: norm });
		if (order.length) {
			const defaultOpr = { selected: false, moved: false };
			mergeData.order = update(d.order, { $push: order });
			if (!withoutOpr) {
				let opr = {};
				$.each(order, (i, v) => {
					opr[v] = defaultOpr;
				});
				mergeData.opr = update(d.opr, { $merge: opr });
			}
		}
	}
	if (!d.noMore /*&& offset >= list.length*/ && list.length < limit) {
		mergeData.noMore = true;
	}
	if (!d.notFresh) {
		mergeData.notFresh = true;
	}
	return update(state, { data: { $merge: mergeData } });

};

const otherContacts = (key, withoutOpr) => defAsyncReducer(
	key
	, initOtherContacts
	, (state, action) => otherContactsSubReducer(state, action, withoutOpr)
);

const errandMyErrandsSubReducer = (state, action) => {
	const { data, param } = action.payload
		, d = state.data
		;
	const list = data.list
		;

	let mergeData = { list };
	if (list && list.length) {

		const preNorm = d.norm;
		let order = [], norm = {};
		$.each(list, (i, v) => {
			const id = v.id;
			order.push(id);
			norm[id] = v;
		});
		mergeData.norm = norm ;

		if (order.length) {
			const defaultOpr = { selected: false };
			mergeData.order = order ;
			let opr = {};

			$.each(order, (i, v) => {
				opr[v] = defaultOpr;
			});
			mergeData.opr = opr;
			mergeData.linkedOpr = opr;
		}
	}
	return update(state, { data: { $merge: mergeData } });
};

const errandLinkedErrandsSubReducer = (state, action) => {
	const { data, param } = action.payload
		, d = state.data
		;
	const list = data.linkedErrands
		;

	let mergeData = { list };
	if (list && list.length) {

		const preNorm = d.norm;
		let order = [], norm = {};
		$.each(list, (i, v) => {
			const id = v.id;
			order.push(id);
			norm[id] = v;
		});
		mergeData.norm = norm ;

		if (order.length) {
			const defaultOpr = { selected: false };
			mergeData.order = order ;
			let opr = {};

			$.each(order, (i, v) => {
				opr[v] = defaultOpr;
			});
			mergeData.opr = opr;
			mergeData.linkedOpr = opr;
		}
		return update(state, { data: { $set: mergeData } });
	} else {
		return update(state, { data: { $set: initOtherContactsData } });
	}
};

const errandMyErrands = (key) => defAsyncReducer(
	key
	, initErrandMyErrands
	, (state, action) => errandMyErrandsSubReducer(state, action)
);

const errandLinkedErrands = (key) => defAsyncReducer(
	key
	, initErrandLinkedErrands
	, (state, action) => errandLinkedErrandsSubReducer(state, action)
);


function moveToAssociateReducer(state, action) {
	if (action.type !== MOVE_OPEN_TO_ASSOCIATE) {
		return state;
	}
	const { errands, select } = action.payload
		, { opr } = state.data
		, moved = { moved: { $set: true } };
	;
	if (select) {
		moved.selected = { $set: true };
	}
	let newOpr = {};
	$.each(errands, (i, v) => {
		if (opr[v]) {
			newOpr[v] = moved;
		}
	});
	return update(state, { data: { opr: newOpr } });
}

function arrayOfOperandAndShallowOrder({ opr, order }) {
	if (!opr) {
		opr = emptyObject;
	}
	if (!order) {
		order = [];
	} else {
		order = order.slice();
	}
	return [opr, order];
}

function searchAndUpdateArray(arr, searchFor) {
	let found;
	$.each(arr, (i, v) => {
		if (searchFor === v) {
			arr.splice(i, 1);
			found = true;
			return false;
		}
	});
	return !!found;
}

const defOprOrder = { opr: emptyObject, order: emptyArray }
	, defAssociate = {
		related: defOprOrder
		, oc: defOprOrder
		, ocId: 0 // currently selected errand ID under other contacts.
		, initialThreadId: 0 // the firstly opened errand's thread ID
		, showId: 0
		, showThreadId: 0
		, showPopup: false
	}
	;
function acquireAssociateReducer(state = defAssociate, action) {
	if (!(action.type == MOVE_OPEN_TO_ASSOCIATE || action.type == MOVE_LINKED_TO_ASSOCIATE)) {
		return state;
	}
	const { errands, related } = action.payload;
	if (!errands || !errands.length) {
		return state;
	}
	let appendKey
		, removeKey
		;
	if (related) {
		appendKey = "related";
		removeKey = "oc";
	} else {
		appendKey = "oc";
		removeKey = "related";
	}
	const [
		appendOpr
		, appendOrder
	] = arrayOfOperandAndShallowOrder(state[appendKey])
		, [
			removeOpr
			, removeOrder
		] = arrayOfOperandAndShallowOrder(state[removeKey])
		, selected = { selected: true }
		;
	let newOpr
		, unset = []
		, notFound = []
		;
	$.each(errands, (i, v) => {
		const foundInAppend = searchAndUpdateArray(appendOrder, v)
			, foundInRemove = searchAndUpdateArray(removeOrder, v)
			;
		if (!foundInAppend) {
			notFound.push(v);
		}
		if (foundInRemove) {
			unset.push(v);
		}
		if (appendOpr[v] && appendOpr[v].selected) {
			return;
		}
		if (!newOpr) {
			newOpr = {};
		}
		newOpr[v] = selected;
	});
	let newState;
	if (notFound.length || newOpr) {
		if (!newState) {
			newState = { [appendKey]: {} };
		}
		if (notFound.length) {
			newState[appendKey].order = { $push: notFound };
		}
		if (newOpr) {
			newState[appendKey].opr = { $merge: newOpr };
		}
	}
	if (unset.length) {
		if (!newState) {
			newState = {};
		}
		newState[removeKey] = {
			opr: { $unset: unset }
			, order: { $set: removeOrder }
		};
	}
	if (!newState) {
		return state;
	}
	return update(state, newState);
}

function resetContactsOnOpenErrandReducer(state, action) {
	const { type, payload } = action;
	if (type !== SET_CURRENT_ERRAND && type !== CHAT_SWITCH_OC_ERRAND) {
		return state;
	}
	const { id } = payload;
	if (id <= 0) {
		return state;
	}
	const init = update(initOtherContactsData, { errandId: { $set: id } });
	return update(state, { data: { $set: init } });
}

function acquiringOtherErrandsReducer(state, action) {
	if (action.type !== ACQUIRING_ERRANDS) {
		return state;
	}
	const { start } = action.payload;
	return update(state, { data: { acquiringWip: { $set: start } } });
}

function hasOpenErrandReducer(state, action) {
	if (action.type !== HAS_OTHER_OPEN) {
		return state;
	}
	const { id, value } = action.payload;
	if (id !== state.data.errandId) {
		return state;
	}
	return update(state, { data: { hasOpen: { $set: value } } });
}

const domainOtherContacts = (state, action) => {
	const { data, time } = action.payload, list = data.list;
	if (list && list.length) {
		$.each(list, (i, v) => {
			state = addNormalizedDomain(state, D_BASIC_ERRANDS, v.id, v, time);
		});
	}
	return state;
};

const domainErrandMyErrands = (state, action) => {
	alert("domainErrandMyErrands")
	const { data, time } = action.payload, list = data.list;
	if (list && list.length) {
		$.each(list, (i, v) => {
			state = addNormalizedDomain(state, D_BASIC_ERRANDS, v.id, v, time);
		});
	}
	return state;
};

const uploadAnswerAttachment = () => defAsyncReducer(
	keyErrandUploadAnswerAttachment, asyncInitState);

const removeTemporaryAttachment = () => defAsyncReducer(
	keyErrandRemoveTemporaryAttachment, asyncInitState);

const getMCAMID = mcamId => mcamId, getMCAMData = (mcamId, data) => data;

const areaDataSelector = createSelector(
	[getMCAMID, getMCAMData],
	(mcamID, data) => mcamByID(mcamID, data)
);

const memoizedMCAMDoneChanger = mcamID => (state, action) => {
	let { data } = action.payload;
	data = areaDataSelector(mcamID, data)
	return update(state, { data: { $set: data } });
};

const mockInit = update(asyncInitState, { $merge: { mock: {} } });

const errandAreaData = () => defAsyncReducer(keyErrandAreaData, mockInit,
	memoizedMCAMDoneChanger(FETCH_AREA_DATA_STR));

const domainAreaData = (state, action) => {
	const { data, time } = action.payload,
		c = areaDataSelector(FETCH_AREA_DATA_STR, data);
	if (c) {
		return addNormalizedDomain(state, D_AREAS, c.id, c, time);
	}
	return state;
};

const turnExternalExpertLightOff = q => defAsyncReducer(
	keyTurnExternalExpertLightOff, asyncInitState);

const sendAnswer = () => defMCAMReducer(keySendAnswer, asyncInitState,
	'sendErrands');

const updateAnswer = () => defMCAMReducer(keyUpdateAnswer, asyncInitState,
	'UpdateErrand');

const sendEE = () => defAsyncReducer(keySendEE, asyncInitState);

const updateAnswerEE = () => defMCAMReducer(keyUpdateAnswerEE, asyncInitState,
	'UpdateAnswerEE');

const savedEE = () => defMCAMReducer(keySavedEE, asyncInitState,
	'savedEE');

const updateManualErrand = () => defMCAMReducer(keyUpdateManualErrand,
	asyncInitState, 'UpdateErrand');

const reopenErrand = () => defMCAMReducer(keyReopenErrand, asyncInitState, 'ReopenErrand');

const resendAnswer = () => defMCAMReducer(keyResendAnswer, asyncInitState, 'ResendErrand');

const publishErrand = () => defAsyncReducer(keyPublishErrand, asyncInitState);

const unpublishErrand = () => defAsyncReducer(keyUnpublishErrand,
	asyncInitState);

const errandSuggestToLibrary = () => defAsyncReducer(keyErrandSuggestToLibrary,
	asyncInitState);

const errandAnonymize = () => defAsyncReducer(keyAnonymize, asyncInitState);

const errandDataExportLog = () => defAsyncReducer(keyDataExportLog, asyncInitState);

const errandDataRevokeAccess = () => defAsyncReducer(keyDeleteExportContact, asyncInitState);

const userHasMembership = () => defAsyncReducer(keyHasMembership, asyncInitState);

const recordEditorSize = () => defAsyncReducer(keyRecordEditorSize, asyncInitState);

const errandDataExport = () => defAsyncReducer(keyExportContact, asyncInitState);

const convertEmailsToArr = (emails) => {
	let mails = [];
	if (emails) {
		let addr = emails.split(',');
		$.each(addr, (i, v) => {
			mails.push({ id: v, value: v });
		});
		return mails;
	} else {
		return mails;
	}
}

const setupOpenErrandReducer = (st, act) => {
	const acq = st.acquireErrand.data.acquire.data,
		ext = st.fetchExtendedData.data.data, tagslist = ext.errand_tags,
		area = st.errandAreaData.domain, basicData = st.basic.data.data;
	let tags, update_question_subject, prefSal = 0, prefSig = 0;
	if (tagslist && tagslist.length) {
		let allTags = (area.normal_tags && area.delete_tags ? area.normal_tags.concat(area.delete_tags) :
			(area.normal_tags ? area.normal_tags :
				(area.delete_tags ? area.delete_tags : [])));
		tags = convertTagIdToTagLevelId(allTags, tagslist);
	}
	if (basicData.iconicSubject) {
		update_question_subject = basicData.iconicSubject;
	} else {
		update_question_subject = basicData.subject;
	}
	if (typeof update_question_subject === 'undefined') {
		update_question_subject = '';
	}
	prefSal = area.area_salutation_pref;
	let selectedSal = 0;
	if (area.area_admin_tick_sal) {
		//force using area's salutation
		selectedSal = prefSal;
	} else {
		//check saved data first
		if (ext.answer_salutation > 0) {
			selectedSal = ext.answer_salutation;
		} else {
			//area/user pref
			if (prefSal) {
				selectedSal = prefSal;
			} else {
				if (area.user_salutation_pref > 0) {
					selectedSal = area.user_salutation_pref;
				}
			}
		}
	}
	prefSig = area.area_signature_pref;
	let selectedSig = 0;
	if (area.area_admin_tick_sign) {
		//force using area's signature
		selectedSig = prefSig;
	} else {
		//check saved data first
		if (ext.answer_signature > 0) {
			selectedSig = ext.answer_signature;
		} else {
			//area/user pref
			if (prefSig) {
				selectedSig = prefSig;
			} else {
				if (area.user_signature_pref > 0) {
					selectedSig = area.user_signature_pref;
				}
			}
		}
	}
	st = update(st, { inputs: { $set: errandInputsInitState } });
	let s = {
		area: area.id,
		update_subject: acq.answer_subject,
		update_answer: acq.answer_body,
		update_channel: basicData.service,
		update_question_subject,
		update_question: acq.question_body,
		original_question: acq.question_body,
		update_cc: convertEmailsToArr(ext.answer_cc),
		update_bcc: convertEmailsToArr(ext.answer_bcc),
		update_pintop: ext.errand_pintop,
		done_date: ext.donedate,
		update_priority: basicData.highpriority,
		update_salutation: selectedSal,
		update_signature: selectedSig,
		selected_library_question: 0
	};
	if (basicData.wasForwardedToExternal) {
		s.update_forward = convertEmailsToArr(ext.answer_to);
	} else {
		let replyTo;
		if (ext.answer_to && !basicData.wasClonedAndForwardedExternal) {
			replyTo = convertEmailsToArr(ext.answer_to);
		} else {
			replyTo = convertEmailsToArr(basicData.fromAddress);
		}
		s.update_to = replyTo;
	}
	if (tags) {
		s.tags = tags;
	}
	return update(st, {
		inputs: { $merge: s },
		collaborationInputs: { $set: colInputsInitState }
	});
};

// preselect all attachments for forward to external
const forwardExternalAttachmentReducer = (state, action) => {
	const list = state.fetchExtendedData.data.data.errand_attachment_list;
	let externalforward_attachments;
	if (list && list.length) {
		externalforward_attachments = {};
		$.each(list, (i, v) => {
			externalforward_attachments[v.id] = true;
		});
	} else {
		externalforward_attachments = null;
	}
	return update(state, {
		inputs: {
			selected_externalforward_attachment: { $set: externalforward_attachments }
		}
	});
};

const questionAttachmentsReducer = (state, action) => {
	const list = state.fetchExtendedData.data.data.errand_attachment_list;
	const saved = state.fetchExtendedData.data.data.answer_mail_attachments;
	let question_attachments;
	if (list && list.length) {
		question_attachments = {};
		$.each(list, (i, v) => {
			if (v) {
				if (v.embeddedImage) {
					question_attachments[v.id] = !sameContentIdInAttachments(v.contentId, saved);
				} else {
					question_attachments[v.id] = false;
				}
			}
		});
	} else {
		question_attachments = null;
	}
	return update(state, {
		inputs: {
			question_attachments: { $set: question_attachments }
		}
	});
};

const savedAttachmentsReducer = (state, action) => {
	// fetch extended data saved attachment affect uploaded attachments.
	const d = state.fetchExtendedData.data;
	if (!d
		|| !d.data
		|| !d.data.answer_mail_attachments
		|| !d.data.answer_mail_attachments.length) {
		return state;
	}
	// TODO: current uploaded attachments and saved attachments can NOT clash
	// with each other although both having different IDs - one is temporary
	// randomly generated ID meanwhile the other will be database mail
	// attachment ID.
	const inpts = state.inputs
		, existing = inpts.uploaded_attachments.concat(inpts.saved_attachments)
		;
	let newAttachments = [];
	$.each(d.data.answer_mail_attachments, (i, v) => {
		let found;
		$.each(existing, (j, w) => {
			if (parseInt(w.id, 10) === v.id) {
				found = true;
				return false;
			}
		});
		if (!found) {
			newAttachments.push(v);
		}
	});
	return update(state, {
		inputs: {
			saved_attachments: {
				$push: newAttachments
			}
		}
	});
};

const historyAttachmentsReducer = (state, action) => {
	const d = state.fetchHistory
		, checkbox = state.inputs.checkboxes
		, errand = state.currentErrand;
	if (!d || !d.data || !d.data.data) {
		return state;
	}
	let selectedAttachment = {};
	$.each(d.data.data, (i, v) => {
		if (errand.id !== v.eid) {
			$.each(v.incoming, (j, ia) => {
				selectedAttachment[ia.id] = checkbox[INC_ALL_HISTORY_ATTACHMNET];
			});
			$.each(v.outgoing, (k, oa) => {
				selectedAttachment[oa.id] = checkbox[INC_ALL_HISTORY_ATTACHMNET];
			});
		}
	});
	return update(state, {
		inputs: {
			selected_history_attachments: {
				$set: selectedAttachment
			}
		}
	});
}

const openedErroredErrandReducer = (state, action) => {
	const errandStatus = state.basic.data.data.statusMessage;
	let showExceedFileError;
	if (errandStatus === FILE_EXCEED_MESSAGE) {
		showExceedFileError = true;
	} else {
		showExceedFileError = false;
	}
	return update(state, {
		ui: {
			showErrorNotifPopup: {
				$set: showExceedFileError
			}
		}
	});
};

const setCurrentErrandReadyReducer = (state, action) => {
	if (state.currentErrand.ready) {
		return state;
	}
	return update(state, { currentErrand: { ready: { $set: true } } });
};

// any state that need modification during opened errand can be updated here.
// Only certain endpoint is guarantee fetched when this reducer trigger. Those
// need endpoint if not here then reduce the endpoint done action is the right
// way.
const openErrandReadyReducer = reduceReducers(
	setupOpenErrandReducer
	, forwardExternalAttachmentReducer
	, questionAttachmentsReducer
	, savedAttachmentsReducer
	, historyAttachmentsReducer
);

const errandReadyReducer = reduceReducers(
	openErrandReadyReducer,
	openedErroredErrandReducer,
	setCurrentErrandReadyReducer // NOTE: MUST be last!
);

const filterPopupInit = {
	allMessages: true
	, bySumHistory: false
	, byAgents: false
	, byCustomers: false
	, byCollaborations: false
	, byInternalMessages: false
};

const setPreviousErrand = (st, act) => {
	if (act.type === SET_PREVIOUS_ERRAND) {
		return update(st, {
			ui: {
				previousErrandId: { $set:  act.payload }
			},
		});
	}
	return st;
};

const setCurrentErrandOpening = (st, act) => {
	if (act.type === CURRENT_ERRAND_OPENING) {
		return update(st, {
			ui: {
				currentErrandOpening: { $set:  act.payload }
			},
		});
	}
	return st;
};

const setCurrentErrand = (st, act) => {
	if (act.type === SET_CURRENT_ERRAND) {
		const { id, isSwitching } = act.payload;
		if (id === 0) {
			return update(st, {
				errand: { $set: emptyObject },
				ui: {
					reply: {
						hideReply: { $set: true }
					}
				},
				currentErrand: { $merge: { id, type: UNSELECT_STR } }
			});
		}
		const currentUIReply = st.ui.reply
			, newUIReplyInit = {
				showAttachment: { $set: currentUIReply.showAttachment }
				, showRecipients: { $set: currentUIReply.showRecipients }
				, showReplyToolbar: { $set: currentUIReply.showReplyToolbar }
				, showReplyAssist: { $set: currentUIReply.showReplyAssist }
				, showSubject: { $set: currentUIReply.showSubject }
				, selectedReplyTab: { $set: currentUIReply.selectedReplyTab }
				, show: { $set: currentUIReply.show }
			}
			;
		if (isSwitching) {
			newUIReplyInit.showAcquire = { $set: currentUIReply.showAcquire };
			newUIReplyInit.acquireTab = { $set: currentUIReply.acquireTab };
			newUIReplyInit.openType = { $set: currentUIReply.openType };
		}
		return update(st, {
			ui: {
				reply: { $set: update(uiReplyInit, newUIReplyInit) }
				, filterPopup: { $set: filterPopupInit }
			}
			, currentErrand: { $merge: { id, type: OPEN_STR } }
			, inputs: { answer_state: { $set: E_A_UNKNOWN }, answer_id: { $set: 0 } }
			// NOTE: here wiped any previous selection of related errands
			// (grouped errands). It can give the front-end feel that clicking
			// any other contacts' grouped errands, all settings reset. It may
			// not be the wanted behaviour.
			, acquireErrand: { $set: initWithOpr }
		});
	} else if (act.type === done(keyFetchHistory)) {
		return update(st, {
			currentErrand: {
				history: { $set: true }
			}
		});
	} else if (act.type === done(keyOpenErrandData)) {
		return update(st, {
			currentErrand: {
				history: { $set: true },
				extend: { $set: true }
			}
		});
	} else if (act.type === done(keyErrandMinLoad)) {
		let question_attachments = {};
		if(act.payload.data.data.errand_attachment_list.length > 0) {
			$.each(act.payload.data.data.errand_attachment_list, (i, v) => {
					if(v) {
						question_attachments[v.id] = false;
					}
			});
		} else {
			question_attachments = null;
		}
		let newSt = update(st, {
				acquireErrand: {
					data: {
						acquire:{
							data: {
								question_body: { $set: act.payload.data.data.body }
							}
						}
					}
				},
				fetchExtendedData: {
					data: {
						data: {
							errand_attachment_list: { $set: act.payload.data.data.errand_attachment_list }
						}
					}
				},
				inputs: {
					question_attachments: { $set: question_attachments }
				}
		});
		return newSt;
	} else if (act.type === done(keyFetchClientsAddressList)) {
		return update(st, { currentErrand: { otherAddresses: { $set: true } } });
	} else if (act.type === done(keyErrandContacts)) {
		return update(st, {
			currentErrand: {
				contacts: { $set: true },
				otherAddresses: { $set: true }
			}
		});
	} else if (act.type === done(keyErrandMyErrands)) {
		return update(st, {
			currentErrand: {
				myErrands: { $set: true }
			}
		});
	} else if (act.type === ERRAND_POST_ANSWER) {
		return update(st, {
			currentErrand: { type: { $set: EOPR_ANSWERED } },
			ui: {
				reply: {
					current: { $set: 'comment' }
				}
			}
		});
	} else if (act.type === ERRAND_SHOULD_POP_PRINT) {
		let p = act.payload;
		if (typeof p.doPrint === 'boolean') {
			return update(st, { ui: { shouldPopPrint: { $set: p.doPrint } } });
		}
	} else if (act.type === SOCIAL_MEDIA_UI_ACTION) {
		let p = act.payload;
		switch (p.whom) {
			case 'ratings':
				return update(st, { ui: { reply: { showRatingPopup: { $set: !p.tgl } } } });
			case 'sendpm':
				return update(st, { ui: { reply: { showSendPMPopup: { $set: !p.tgl } } } });
			case 'updateans':
				return update(st, { ui: { reply: { showUpdateAnswerPopup: { $set: !p.tgl } } } });
			case 'reactions':
				return update(st, { ui: { reply: { showReactionPopup: { $set: !p.tgl } } } });
			default:
				console.log("dbg: unhandled action %s", p.whom);
		}
	} else if (act.type === ERRAND_ERROR_NOTIF_POPUP) {
		let p = act.payload;
		if (typeof p === 'boolean') {
			return update(st, { ui: { showErrorNotifPopup: { $set: p } } });
		}
	} else if (act.type === TOGGLE_ERRAND_ATTACHMENT_LIST) {
		const p = act.payload;
		return update(st, { ui: { showAttachment: { $set: !p } } });
	} else if (act.type === TOGGLE_ERRAND_VISITOR_PATH) {
		const p = act.payload;
		return update(st, { ui: { showVisitorPath: { $set: p } } });
	} else if (act.type === TOGGLE_FILE_ARCHIVE) {
		return update(st, { ui: { reply: { showArchiveDD: { $set: !act.payload } } } });
	} else if (act.type === TOGGLE_ATTACHMENT_PLUS_MINUS) {
		return update(st, { ui: { toggleAttachBox: { $set: !act.payload } } });
	} else if (act.type === SELECT_ERRAND_LIBRARY) {
		return update(st, { ui: { knowledgeBase: { selectedLibrary: { $set: act.payload } } } });
	} else if (act.type === SEARCH_ERRAND_LIBRARY) {
		return update(st, { ui: { knowledgeBase: { doSearch: { $set: act.payload } } } });
	} else if (act.type === SHOW_ERRAND_LIBRARY_LIST) {
		return update(st, { ui: { knowledgeBase: { show: { $set: act.payload } } } });
	} else if (act.type === SHOW_ERRAND_LIBRARY_POPUP) {
		const p = act.payload;
		let reply = (p.tgl === false ? "" : p.reply);
		return update(st, {
			ui: {
				knowledgeBase: {
					showPopup: { $set: p.tgl },
					reply: { $set: reply }
				}
			}
		});
	} else if (act.type === RESET_REWRITE_ANSWER) {
		const p = act.payload;
		let affectPopup = true;
		let newContext = true;
		if(typeof p.closePopup !== "undefined") {
			affectPopup = false;
		}
		if(p.startOver) {
			newContext = false;
		}
		const baseUpdate = {
			ui: {
				rewriteAnswerBase: {
					currentQues: { $set: '' },
					generatingAns: { $set: false },
					newContext: { $set: newContext },
					dataSrc: {
						$set: {
							questions: [],
							answers: [],
						},
					},
					llmSessionId: { $set: {} },
					agentAssistContext: { $set: '' }
				},
			},
		};
		if (affectPopup) {
			baseUpdate.ui.rewriteAnswerBase.showPopup = { $set: false };
		}
		return update(st, baseUpdate);
	} else if (act.type === SET_REWRITE_ANSWER_QUESTION) {
		return update(st, { ui: {
			rewriteAnswerBase: {
				currentQues: { $set: act.payload },
				newContext : { $set: false }
			}
		}});
	} else if (act.type === UPDATE_REWRITE_ANSWER_STATE) {
		return update(st, { ui: { rewriteAnswerBase: { generatingAns: { $set: act.payload } } } });
	} else if (act.type === SHOW_ERRAND_REWRITE_ANSWER_POPUP) {
		const p = act.payload;
		return update(st, {
			ui: {
				rewriteAnswerBase: {
					showPopup: { $set: p.tgl }
				}
			}
		});
	} else if (act.type === RESET_LIBRARY_SEARCH) {
		const removeAttachment = act.payload;
		if (!removeAttachment) {
			return update(st, {
				ui: {
					knowledgeBase: {
						shownQuestion: { $set: "" },
						shownAnswer: { $set: "" },
						searchText: { $set: "" },
						shownId: { $set: "" },
						shownShortcut: { $set: "" }
					}
				}
			});
		} else {
			return update(st, {
				ui: {
					knowledgeBase: {
						shownQuestion: { $set: "" },
						shownAnswer: { $set: "" },
						searchText: { $set: "" },
						shownId: { $set: "" },
						shownShortcut: { $set: "" },
						shownAttachments: { $set: "" }
					}
				}
			});
		}
	} else if (act.type === SET_SHOW_LIBRARY_ANSWER) {
		const p = act.payload;
		return update(st, {
			ui: {
				knowledgeBase: {
					shownQuestion: { $set: p.questionText },
					shownAnswer: { $set: p.answerText },
					shownAttachments: { $set: p.attachments },
					shownId: { $set: p.id },
					shownShortcut: { $set: p.shortcuts }
				}
			}
		});
	} else if (act.type === SET_LIBRARY_SEARCH_TEXT) {
		return update(st, { ui: { knowledgeBase: { searchText: { $set: act.payload } } } });
	} else if (act.type === CLEAR_LIBRARY_SEARCH_TIMEOUT) {
		return update(st, { ui: { knowledgeBase: { searchTimeoutId: { $set: 0 } } } })
	} else if (act.type === done(keyFeaturedQuestion)) {
		if (act.payload.data) {
			let { status } = act.payload.data;
			if (typeof status != "undefined") {
				return update(st, { ui: { knowledgeBase: { featuredQuestion: { $set: status } } } });
			}
		}
		return st;
	} else if (act.type === TOGGLE_EXTERNAL_DATA) {
		let showExtData = st.ui.showExternalData;
		return update(st, { ui: { showExternalData: { $set: !showExtData } } });
	} else if (act.type === HANDLE_POPUP_FILTER_BY_HISTORY_ELEMENTS) {
		let allMessages = false
			, byAgents = false
			, bySumHistory = false
			, byCustomers = false
			, byCollaborations = false
			, byInternalMessages = false;
		const p = act.payload;
		if (p.elem === FILTER_ALL_MESSAGE)
			allMessages = p.toggle;
		else if (p.elem === FILTER_BY_SUMMARIZED_HISTORY)
			bySumHistory = p.toggle;
		else if (p.elem === FILTER_BY_AGENT)
			byAgents = p.toggle;
		else if (p.elem === FILTER_BY_CUSTOMER)
			byCustomers = p.toggle;
		else if (p.elem === FILTER_BY_COLLABORATION)
			byCollaborations = p.toggle;
		else if (p.elem === FILTER_BY_INTERNAL_MESSAGE)
			byInternalMessages = p.toggle;
		else {
			console.log("FIXME: Unknown filter -  " + p.elem);
			return st;
		}
		return update(st, {
			ui: {
				filterPopup: {
					allMessages: { $set: allMessages },
					bySumHistory: { $set: bySumHistory },
					byAgents: { $set: byAgents },
					byCustomers: { $set: byCustomers },
					byCollaborations: { $set: byCollaborations },
					byInternalMessages: { $set: byInternalMessages }
				}
			}
		});
	} else if (act.type === TOGGLE_CHAT_RATING_BOX) {
		return update(st, {
			ui: {
				chatSatisfaction: {
					isToggleOn: { $set: act.payload }
				}
			}
		});
	} else if (act.type === TOGGLE_CLIENT_CHAT_RATING_BOX) {
		return update(st, {
			ui: {
				clientChatSatisfaction: {
					isToggleOn: { $set: act.payload }
				}
			}
		})
	}
	else if (act.type === SET_CHAT_SATISFACTION_INPUT) {
		const a = act.payload;
		if (act.payload.type === 'like' || act.payload.type === 'unlike') {
			return update(st, {
				ui: {
					chatSatisfaction: {
						isLikeIconActive: { $set: act.payload.type === 'like' },
						isUnlikeIconActive: { $set: act.payload.type === 'unlike' },
						ratingPoint: { $set: act.payload.value }
					}
				}
			});
		} else {
			return update(st, {
				ui: {
					chatSatisfaction: {
						chatRatingText: { $set: act.payload.value }
					}
				}
			});
		}
	} else if (act.type === RESET_AGENT_SATISFACTION_INPUT) {
		return update(st, {
			ui: {
				chatSatisfaction: {
					isLikeIconActive: { $set: false },
					isUnlikeIconActive: { $set: false },
					ratingPoint: { $set: "" }
				}
			}
		});
	} else if (act.type === done(keyFetchUserVote)) {
		if (act.payload.data) {
			let { uservote } = act.payload.data;
			if (uservote === false) {
				return update(
					st, { ui: { knowledgeBase: { userVote: { vote: { $set: null }, comment: { $set: '' }, editable: { $set: true } } } } }
				);
			}
			if (typeof uservote !== "undefined") {
				return update(
					st, {
					ui: {
						knowledgeBase: {
							userVote: {
								vote: { $set: uservote.vote }
								, comment: { $set: uservote.comment }
								, editable: { $set: true }
							}
						}
					}
				});
			}
		}
		return st;
	} else if(act.type === done(keyGenAIAnswer)) {
		if (!cflag.IsActive("2024-08-23.CEN-2685.implement.llm.streaming.response") || features["openai-agent-assist-uses-openai"]) {
			let { data } = act.payload;
			let { dataSrc, currentQues } = st.ui.rewriteAnswerBase;

			let eid = st.currentErrand.id ? st.currentErrand.id : 0;
			if (eid == 0) {
				if (data.errand_id) {
					eid = data.errand_id;
				}
			}

			const currentQuestions = dataSrc[eid] ? dataSrc[eid].questions || [] : [];
			const currentAnswers = dataSrc[eid] ? dataSrc[eid].answers || [] : [];

			let newDataSrc = Object.assign({}, dataSrc);
			newDataSrc[eid] = {
				questions: currentQuestions,
				answers: currentAnswers
			}

			let currentQue = currentQues;
			if (data.context === "rephrase" || data.context === "rephrase-init" || data.context === "start-over") {
				currentQue = I("Rephrase") + " : " + currentQues;
			} else {
				currentQue = data.prompt;
			}
			const qTimestamp = data.received - 5;
			const currentQ = {
				content: currentQue,
				received: qTimestamp,
				isQuestion: true
			}
			const currentA = {
				content: data.ai_answer,
				received: data.received
			}

			if (newDataSrc && newDataSrc[eid] && newDataSrc[eid].questions) {
				newDataSrc[eid].questions.push(currentQ);
			}
			if (newDataSrc && newDataSrc[eid] && newDataSrc[eid].answers) {
				newDataSrc[eid].answers.push(currentA);
			}

			let llmSessionId = st.ui.rewriteAnswerBase.llmSessionId;
			if (!llmSessionId[eid]) {
				llmSessionId[eid] = data.session;
			}

			return update(st, {
				ui: {
					rewriteAnswerBase: {
						generatingAns: { $set: false },
						dataSrc: { $set: newDataSrc },
						llmSessionId: { $set: llmSessionId },
					}
				}
			});
		}
		return st;
	} else if (act.type === "FETCH_AGENT_ASSIST_REQUEST") {
		const { errandId, context, prompt, streamId } = act.payload;
		let { dataSrc, currentQues, } = st.ui.rewriteAnswerBase;
		let eid = st.currentErrand.id ? st.currentErrand.id : errandId;

		const currentQuestions = dataSrc[eid] ? dataSrc[eid].questions || [] : [];
		const currentAnswers = dataSrc[eid] ? dataSrc[eid].answers || [] : [];

		let newDataSrc = Object.assign({}, dataSrc);
		newDataSrc[eid] = {
			questions: currentQuestions,
			answers: currentAnswers
		}
		let currentQue = prompt;
		if (context === "rephrase" || context === "rephrase-init" || context === "start-over") {
			currentQue = I("Rephrase") + " : " + currentQues;
		}

		const currentQ = {
			content: currentQue,
			streamId: streamId,
			isQuestion: true,
			received: Math.floor(Date.now() / 1000) - 5
		}

		if (newDataSrc[eid].questions) {
			let lastQuestion = newDataSrc[eid].questions[newDataSrc[eid].questions.length - 1];
			if (lastQuestion && lastQuestion.streamId === streamId) {
				newDataSrc[eid].questions = [
					...newDataSrc[eid].questions.slice(0, newDataSrc[eid].questions.length - 1),
					currentQ
				];
			} else {
				newDataSrc[eid].questions = [...newDataSrc[eid].questions, currentQ];
			}
		}
		const requestUpdate = {
			agentAssistLoading: true,
			agentAssistError: null,
			generatingAns: true,
			agentAssistContext: context,
			dataSrc: newDataSrc,
			currentEid: errandId
		};
		return update(st, { ui: { rewriteAnswerBase: { $merge: requestUpdate } } });
	} else if (act.type === "FETCH_AGENT_ASSIST_SUCCESS") {
		const { streamId, response } = act.payload;
		let { dataSrc, currentQues, currentEid } = st.ui.rewriteAnswerBase;
		let eid = st.currentErrand.id ? st.currentErrand.id : currentEid;

		if (!eid) {
			return st
		}

		const currentQuestions = dataSrc[eid] ? [...(dataSrc[eid].questions || [])] : [];
		const currentAnswers = dataSrc[eid] ? [...(dataSrc[eid].answers || [])] : [];

		let newDataSrc = {
			...dataSrc,
			[eid]: {
				questions: currentQuestions,
				answers: currentAnswers
			}
		};

		let currentQue = currentQues;
		currentQue = prompt;

		const currentA = {
			content: response,
			received: Math.floor(Date.now() / 1000),
			streamId: streamId
		};

		if (newDataSrc[eid].answers) {
			let lastAnswer = newDataSrc[eid].answers[newDataSrc[eid].answers.length - 1];

			if (lastAnswer && lastAnswer.streamId === streamId) {
				newDataSrc[eid].answers = [
					...newDataSrc[eid].answers.slice(0, newDataSrc[eid].answers.length - 1),
					currentA
				];
			} else {
				newDataSrc[eid].answers = [...newDataSrc[eid].answers, currentA];
			}
		}
		if (st.ui.rewriteAnswerBase.currentStream !== response) {
			return update(st, {
				ui: {
					rewriteAnswerBase: {
						dataSrc: { $set: newDataSrc },
						streamId: { $set: streamId },
						updateCount: { $set: (st.ui.rewriteAnswerBase.updateCount || 0) + 1 }
					}
				}
			});
		}
	} else if (act.type === 'UPDATE_AGENT_ASSIST_RECEIVED') {
		let { dataSrc, currentEid } = st.ui.rewriteAnswerBase;
		let eid = st.currentErrand.id ? st.currentErrand.id : currentEid;
		const {received, streamId } = act.payload;
		let newDataSrc = Object.assign({}, dataSrc);
		if (newDataSrc[eid].answers) {
			let lastAnswer = newDataSrc[eid].answers[newDataSrc[eid].answers.length - 1];
			if (lastAnswer && lastAnswer.streamId === streamId) {
				newDataSrc[eid].answers = [
					...newDataSrc[eid].answers.slice(0, newDataSrc[eid].answers.length - 1),
					{
						...lastAnswer,
						received: received
					}
				];
			}
		}
		return update(st, {
			ui: {
				rewriteAnswerBase: {
					dataSrc: { $set: newDataSrc }
				}
			}
		});

	} else if (act.type === 'UPDATE_AGENT_ASSIST_PROMPT') {
		return st;
	} else if (act.type === 'FETCH_AGENT_ASSIST_INFO') {
		const session = act.payload;
		const { currentEid } = st.ui.rewriteAnswerBase;
		const eid = st.currentErrand.id ? st.currentErrand.id : currentEid;

		let llmSessionId = st.ui.rewriteAnswerBase.llmSessionId;
		if (!llmSessionId[eid]) {
			llmSessionId[eid] = session;
		}
		return update(st, {
			ui: {
				rewriteAnswerBase: {
					llmSessionId: { $set: llmSessionId },
				}
			}
		});
	} else if (act.type === 'UPDATE_AGENT_ASSIST_RESPONSE') {
		const { response } = act.payload;
		return update(st, {
			ui: {
				rewriteAnswerBase: {
					currentEid: { $set: response }
				}
			}
		});
	} else if (act.type === "FETCH_AGENT_ASSIST_DONE") {
		return update(st, {
			ui: {
				rewriteAnswerBase: {
					generatingAns: { $set: false },
				}
			}
		});
	} else if (act.type === "FETCH_AGENT_ASSIST_FAILURE") {
		const errorUpdate = {
			agentAssistLoading: false,
			agentAssistError: act.payload,
			generatingAns: false ,
		};
		return update(st, { ui: { rewriteAnswerBase: { $merge: errorUpdate } } });
	} else if (act.type === done(keyPostUserVote)) {
		if (act.payload.data) {
			const { remove, add, set, comment } = act.payload.data;
			if (add || set) {
				return update(st, { ui: { knowledgeBase: { userVote: {
					editable: { $set: false }
					, comment: { $set: comment }
				} } } });
			} else if (remove) {
				return update(st, {
					ui: {
						knowledgeBase: {
							userVote: {
								vote: { $set: 0 }
								, comment: { $set: "" }
								, editable: { $set: true }
							}
						}
					}
				});
			}
		}
		return st;
	} else if (act.type === KB_UPDATE_USERVOTE_COMMENT) {
		if (act.payload !== null) {
			return update(st, { ui: { knowledgeBase: { userVote: { comment: { $set: act.payload } } } } });
		}
		return st
	} else if (act.type === KB_UPDATE_USERVOTE_VOTE) {
		if (act.payload !== null) {
			return update(st, { ui: { knowledgeBase: { userVote: { vote: { $set: act.payload } } } } });
		}
		return st
	} else if (act.type === KB_UPDATE_USERVOTE_EDIT) {
		if (act.payload !== null) {
			return update(st, { ui: { knowledgeBase: { userVote: { editable: { $set: act.payload } } } } });
		}
		return st
	} else if (act.type === SET_ERRAND_COUNTDOWN_TIMER) {
		if (st.currentErrand.id == act.payload.eid) {
			return update(st, {
				basic: {
					data: {
						data: {
							endTimer: {
								$set:
									act.payload.ts
							}
						}
					}
				}
			});
		}
	} else if (act.type === KB_UPDATE_USERVOTE_WARNING) {
		return update(st, { ui: { knowledgeBase: { userVote: { warning: { $set: act.payload } } } } });
	} else if (act.type === done(keyErrandChangeInternalState)) {
		return update(st, {
			basic: {
				data: {
					data: {
						state: {
							$set:
								act.payload.data.state
						}
					}
				}
			}
		});
	} else if (act.type === SHOW_REPLY_SEARCH_SHORTCUT_DROPDOWN) {
		return update(st, { ui: { searchShortcut: { showDropdown: { $set: act.payload } } } });
	} else if (act.type === SHOW_REPLY_SEARCH_INPUT) {
		return update(st, { ui: { searchShortcut: { showSearch: { $set: act.payload } } } });
	} else if (act.type === HIDE_REPLY_SEARCH) {
		return update(st, {
			ui: {
				searchShortcut: {
					showDropdown: { $set: act.payload }
					, showSearch: { $set: act.payload }
				}
			}
		});
	} else if (act.type === WHATSAPP_TEMPLATE_POPUP) {
		return update(st, { ui: { reply: { showWATemplatePopup: { $set: !act.payload } } } });
	} else if (act.type === SHOW_HIDE_ACQUIRE_POPUP) {
		return update(st, { ui: { acquireErrand: { show: { $set: act.payload } } } });
	} else if (act.type === SET_CRM_EMAILTRIGGER_CONTENT) {
		return update(st, {
			currentErrand: {
				crmData: { $set: act.payload }
			}
		});
	}
	return st;
};

const updateOpenErrandReadyReducer = (state, action) => {
	const { type } = action;
	if (type === XFER_DOMAIN_AREA_DATA
		|| type === SYNC_ACQUIRE_DATA
		|| type === done(keyAcquireErrand)
		|| type === done(keyLoadBasicErrand)
		|| type === done(keyFetchExtendedData)
		|| type === done(keyOpenErrandData)
		|| type === done(keySavedEE)) {
		const { currentErrand } = state;
		if (!currentErrand.acquire ||
			!currentErrand.area ||
			!currentErrand.basic ||
			!currentErrand.extend) {
			return state;
		}
		// NOTE: any change to the errand state during open errand ready
		// compose it as another reducer and update to openErrandReady reducer.
		return errandReadyReducer(state, action);
	}
	return state;
};

const expandHideReducer = (st, act) => {
	const p = act.payload;
	if (p.part === 'collaboration') {
		return update(st, {
			collaboration: {
				$merge: {
					//show: !st.collaboration.show
					show: p.value
				}
			},
			showComment: { $set: (p.value ? !p.value : st.showComment) },
			showCRM: { $set: (p.value ? !p.value : st.showCRM) },
			showAIAnswerPanel: { $set: (p.value ? !p.value : st.showAIAnswerPanel) },
			rightSidePanelOpen: { $set: p.value },
			showKnowledgeBasePanel: { $set: false },
			expandKnowledgeBasePanel: { $set: false },
			chatSidePanelOpen: { $set: false }
		});
	} else if (p.part === 'note') {
		return update(st, {
			showComment: { $set: p.value },
			rightSidePanelOpen: { $set: p.value },
			collaboration: {
				show: { $set: (p.value ? !p.value : st.collaboration.show) }
			},
			showCRM: { $set: (p.value ? !p.value : st.showCRM) },
			showAIAnswerPanel: { $set: (p.value ? !p.value : st.showAIAnswerPanel) },
			showKnowledgeBasePanel: { $set: false },
			expandKnowledgeBasePanel: { $set: false },
			chatSidePanelOpen: { $set: false }
		});
	} else if (p.part === 'crmEmailTrigger') {
		return update(st, {
			showCRM: { $set: p.value },
			rightSidePanelOpen: { $set: p.value },
			showComment: { $set: (p.value ? !p.value : st.showComment) },
			collaboration: {
				show: { $set: (p.value ? !p.value : st.collaboration.show) }
			},
			showAIAnswerPanel: { $set: (p.value ? !p.value : st.showAIAnswerPanel) },
			showKnowledgeBasePanel: { $set: false },
			expandKnowledgeBasePanel: { $set: false },
			chatSidePanelOpen: { $set: false }
		});
	} else if (p.part === 'crmEmailTriggerPanel') {
		return update(st, { hasCRM: { $set: p.value } });
	} else if (p.part === 'AIAnswerPanel') {
		return update(st, {
			showAIAnswerPanel: { $set: p.value },
			rightSidePanelOpen: { $set: p.value },
			showComment: { $set: (p.value ? !p.value : st.showComment) },
			collaboration: {
				show: { $set: (p.value ? !p.value : st.collaboration.show) }
			},
			showCRM: { $set: (p.value ? !p.value : st.showCRM) },
			showKnowledgeBasePanel: { $set: false },
			expandKnowledgeBasePanel: { $set: false },
			chatSidePanelOpen: { $set: false }
		});
	} else if (p.part == 'KnowledgeBasePanel') {
		return update(st, {
			showKnowledgeBasePanel: { $set: p.value },
			rightSidePanelOpen: { $set: st.showAIAnswerPanel ? true : p.value },
			showAIAnswerPanel: { $set: p.value ? false : st.showAIAnswerPanel },
			showComment: { $set: (p.value ? !p.value : st.showComment) },
			collaboration: {
				show: { $set: (p.value ? !p.value : st.collaboration.show) }
			},
			showCRM: { $set: (p.value ? !p.value : st.showCRM) },
			chatSidePanelOpen: { $set: false }
		});
	}
	return st;
};

const expandKBPanelReducer = (st, act) => {
	const p = act.payload;
	return update(st, {
		expandKnowledgeBasePanel: { $set: p } //maximize the KB panel to be a popup modal
	});
}

const toggleReplyBoxReducer = (st, act) => {
	const p = act.payload;
	let s;
	if (typeof p.show !== 'undefined') {
		// if show is not undefined then current is not undefined too.
		s = { current: p.current, show: p.show };
	} else {
		if (p.current) {
			if (p.current === st.reply.current) {
				s = { show: !st.reply.show };
			} else {
				if (!st.reply.show) {
					s = { show: true, current: p.current };
				} else {
					s = { current: p.current };
				}
			}
		} else {
			s = { show: !st.reply.show };
		}
	}
	return update(st, { reply: { $merge: s } });
};

const selectToggleChannelReducer = (st, act) => {
	const p = act.payload;
	// if(typeof p === 'string') {
	// 	return update(st, {reply: {$merge: {currentChannel: p}}});
	// }
	return update(st, { reply: { $merge: { showChannel: p } } });
};

const selectToggleReplyActionReducer = (st, act) => {
	const p = act.payload;
	if (typeof p === 'boolean') {
		return update(st, { reply: { $merge: { showAction: p } } });
	}
	return st;
};

const selectShowRecipients = (st, act) => {
	const p = act.payload;
	if (typeof p === 'boolean') {
		return update(st, { reply: { showRecipients: { $set: p } } });
	}
	return st;
};

const selectShowReplyToolbar = (st, act) => {
	const p = act.payload;
	if (typeof p === 'boolean') {
		return update(st, { reply: { showReplyToolbar: { $set: p } } });
	}
	return st;
};

const selectShowReplyAssist = (st, act) => {
	const p = act.payload;
	if (typeof p === 'boolean') {
		return update(st, { reply: { showReplyAssist: { $set: p } } });
	}
	return st;
};

const selectShowSubject = (st, act) => {
	const p = act.payload;
	if (typeof p === 'boolean') {
		return update(st, { reply: { showSubject: { $set: p } } });
	}
	return st;
}

const selectReplyOptionTab = (st, act) => {
	const p = act.payload;
	return update(st, { reply: { selectedReplyTab: { $set: p } } });
}

const acquireOperations = (state, action) => {
	const { opr, value } = action.payload;
	if (opr === "show") {
		return update(state, {
			reply: {
				showAcquire: { $set: value }
			},
			//NOTES: disable autoOpenExtSystem by default when acquire another errand
			autoOpenExtSystem: { $set: false }
		});
	} else if (opr === "switch") {
		return update(state, { reply: { acquireTab: { $set: value } } });
	} else if (opr === "switchOpenType") {
		return update(state, { reply: { openType: { $set: value } } });
	}
	return state;
};

const showErrandHeaderMobile = (st, act) => {
	const p = act.payload;
	if (typeof p === 'boolean') {
		return update(st, { showErrandHeaderMobile: { $set: p } });
	}
	return st;
};

const showErrandOptionMobile = (st, act) => {
	const p = act.payload;
	if (typeof p === 'boolean') {
		return update(st, { showErrandOptionMobile: { $set: p } });
	}
	return st;
};

const showReplyboxErrandsMenu = (st, act) => {
	const p = act.payload;
	if (typeof p === 'boolean') {
		return update(st, { showReplyboxErrandsMenu: { $set: p } });
	}
	return st;
};

const setNavButtonBusy = (st, act) => {
	const p = act.payload;
	if (typeof p.value === 'boolean') {
		return update(st, { navBtnBusy: { $set: p.value } });
	}
	return st;
};

const setTextFilePreview = (st, act) => {
	const p = act.payload;
	return update(st, { textFilePreview: { $set: p } });
};

const setCSVFilePreview = (st, act) => {
	const p = act.payload;
	return update(st, { csvFilePreview: { $set: p } });
}

const handleUIFromWfSettings = (st, act) => {
	const p = act.payload;
	if (p.data) {
		if (typeof p.data.showAgentAssistPanel != "undefined") {
			return update(st, {
				showAIAnswerPanel: { $set: p.data.showAgentAssistPanel }
				, rightSidePanelOpen: { $set: p.data.showAgentAssistPanel }
			});
		}
	}
	return st;
}

const setAnswerTranslation = (st, act) => {
	if (act.type === SET_ANSWER_TRANSLATION) {
		const p = act.payload
		if (p.field === 'to') {
			return update(st, { translationTo: { $set: p.value } });
		}
	}
	return st;
}

function checkExistingAcquireStateBeforeUpdate(state, key, id, newUpdate) {
	if (!state[key].data.opr[id]) {
		return state;
	}
	return update(state, { [key]: newUpdate });
}

function checkExistingAcquireStateBeforeUpdatelinkedOpr(state, key, id, newUpdate) {
	if (!state[key].data.linkedOpr[id]) {
		return state;
	}
	return update(state, { [key]: newUpdate });
}

const selectAcquireReducer = (state, action) => {
	if (action.type !== ACQUIRE_ERRAND_OPERATION) {
		return state;
	}
	const { opr, value } = action.payload
		, { id, select } = value
		, newData = { data: { opr: { [id]: { selected: { $set: select } } } } }
		, newDataLinkedOpr = { data: { linkedOpr: { [id]: { selected: { $set: select } } } } }
		;
	if (opr === SEL_ACT_ACQ_ERD_OTHER) {
		return update(state, { contacts: newData });
	} else if (opr === SEL_ACT_ACQ_SELECT) {
		return checkExistingAcquireStateBeforeUpdate(
			state
			, "acquireErrand"
			, id
			, newData
		);
	} else if (opr === SEL_ACT_OTHER_CONTACT) {
		return checkExistingAcquireStateBeforeUpdate(
			state
			, "contacts"
			, id
			, newData
		);
	} else if (opr === SEL_ACT_MY_ERD) {
		return checkExistingAcquireStateBeforeUpdate(
			state
			, "myErrands"
			, id
			, newData
		);
	} else if (opr === SEL_ACT_LINKED_ERD) {
		return checkExistingAcquireStateBeforeUpdatelinkedOpr(
			state
			, "linkedErrands"
			, id
			, newDataLinkedOpr
		);

	}
	return state;
};

const selectAllAcquireReducer = (state, action) => {
	let allSelected = state.contacts.data.allSelected;
	if (action.type === SELECT_ALL_ACQUIRE_ERRAND) {
		return updateAllAcquireErrand(state, action.payload);
	} else if (action.type === SELECT_MORE_ACQUIRE_ERRAND) {
		if (allSelected) {
			return updateAllAcquireErrand(state, data.allSelected);
		}
	} else if (action.type === DESELECT_ALL_ACQUIRE_ERRAND) {
		if (allSelected) {
			return updateAllSelectedAcqErrand(state, action.payload);
		}
	}
	return state;
}

const selectAllAcquireLinkErrandReducer = (state, action) => {
	if(action.type === SELECT_ALL_LINK_ERRAND_MY_ERRAND) {
		let select = action.payload
		let data = state.myErrands.data;
		if(!data) {
			return state;
		}
		const prevOpr = data.opr;
		let opr = {};
		$.each(prevOpr, function(k,v) {
			if(select !== v.selected) {
				opr[k] = update(v, {$merge: {selected: select}});
			} else {
				opr[k] = v;
			}
		});
		data = update(data, {$merge: {opr}});
		return update(state, { myErrands: { $merge: { data } } });
	}

	return state;
}

const selectAllAcquireLinkedReducer = (state, action) => {
	if(action.type === SELECT_ALL_LINKED_ERRAND) {
		let select = action.payload
		let data = state.linkedErrands.data;
		if(!data) {
			return state;
		}
		const prevLinkedOpr = data.linkedOpr;
		let linkedOpr = {};
		$.each(prevLinkedOpr, function(k,v) {
			if(select !== v.selected) {
				linkedOpr[k] = update(v, {$merge: {selected: select}});
			} else {
				linkedOpr[k] = v;
			}
		});
		data = update(data, {$merge: {linkedOpr}});
		return update(state, { linkedErrands: { $merge: { data } } });
	}

	return state;
}

const updateAllAcquireErrand = (state, select) => {
	let data = state.contacts.data;
	if (!data) {
		return state;
	}
	const prevOpr = data.opr;
	let opr = {};
	$.each(prevOpr, function (k, v) {
		if (select !== v.selected) {
			opr[k] = update(v, { $merge: { selected: select } });
		} else {
			opr[k] = v;
		}
	});
	data = update(data, { $merge: { allSelected: select, opr } });
	return update(state, { contacts: { $merge: { data } } });
}

const updateAllSelectedAcqErrand = (state, select) => {
	return update(state, { contacts: { data: { allSelected: { $set: select } } } });
}

const manualErrandBusyReducer = (state, action) => {
	const { state: manualErrandState, id, eid, cipherKey } = action.payload;
	if (manualErrandState === ME_ST_CREATED) {
		return update(state, {
			manual: {
				state: { $set: ME_ST_CREATED },
				cipherKey: { $set: cipherKey },
				newErrandId: { $set: eid },
				createdId: { $set: id }
			}
		});
	} else if (manualErrandState === ME_ST_IDLE) {
		return update(state, {
			manual: {
				state: { $set: ME_ST_IDLE },
				newErrandId: { $set: NEW_MANUAL_ERRAND_ID },
				createdId: { $set: NEW_MANUAL_ERRAND_ID }
			}
		});
	}
	return update(state, { manual: { state: { $set: manualErrandState } } });
};

const manualCallBusyReducer = (state, action) => {
	const { state: manualCallState, id, eid, cipherKey, extRefId } = action.payload;
	if (manualCallState === ME_ST_CREATED) {
		return update(state, {
			manualCall: {
				state: { $set: ME_ST_CREATED },
				cipherKey: { $set: cipherKey },
				newErrandId: { $set: eid },
				extRefId: { $set: extRefId },
				createdId: { $set: id }
			}
		});
	} else if (manualCallState === ME_ST_IDLE) {
		return update(state, {
			manualCall: {
				state: { $set: ME_ST_IDLE },
				newErrandId: { $set: NEW_MANUAL_ERRAND_ID },
				extRefId: { $set: "" },
				createdId: { $set: NEW_MANUAL_ERRAND_ID }
			}
		});
	}
	return update(state, { manualCall: { state: { $set: manualCallState } } });
};

const manualErrandCreationReducer = (state, action) => update(state,
	{ manual: { createAs: { $set: action.payload } } });

const manualCallCreationReducer = (state, action) => update(state,
	{ manualCall: { createAs: { $set: action.payload } } });

const manualErrandTabReducer = (state, action) => update(state,
	{ manual: { tab: { $set: action.payload } } });

const actionHandleForward = (state, action) => {
	let ap = action.payload;
	if (ap.actionFor === 'moveFolder') {
		return update(state, { showMoveToFolder: { $set: ap.applyWith } });
	} else if (ap.actionFor == 'forwardArea') {
		return update(state, { showForwardToArea: { $set: ap.applyWith } });
	} else if (ap.actionFor == 'forwardAgent') {
		return update(state, { showForwardToAgent: { $set: ap.applyWith } });
	}
	return state;
};

const replyBool = (state, action, field) => update(state, { reply: { [field]: { $set: !!action.payload } } });

const rootBool = (state, action, field) => update(state, { [field]: { $set: !!action.payload } });

const uiLocalReducers = {
	[ACTIVATE_ANSWERED_VIEW]: (state, action) => replyBool(state, action, 'activateClosedErrandView'),
	[CHANGE_REPLY_OPTION]: (state, { payload }) => update(state, { reply: { current: { $set: payload } } }),
	[ERRAND_ATTACHMENT_LIST_DISPLAY]: (state, action) => rootBool(state, action, 'showAttachment'),
	[EXPAND_HIDE_OPTION]: expandHideReducer,
	[EXPAND_KB_PANEL]: expandKBPanelReducer,
	[MANUAL_ERRAND_BUSY]: manualErrandBusyReducer,
	[MANUAL_CALL_BUSY]: manualCallBusyReducer,
	[NO_SCROLL_TO_QUESTION]: (state, action) => update(state, { reply: { stopScrollToQuestion: { $set: true } } }),
	[SELECT_MANUAL_ERRAND]: manualErrandTabReducer,
	[SELECT_MANUAL_ERRAND_CREATION]: manualErrandCreationReducer,
	[SELECT_MANUAL_CALL_CREATION]: manualCallCreationReducer,
	[SELECT_REPLY_TOGGLE_SHOW]: toggleReplyBoxReducer,
	[SELECT_TOGGLE_REPLY_CHANNEL]: selectToggleChannelReducer,
	[SELECT_TOGGLE_REPLY_ACTION]: selectToggleReplyActionReducer,
	[SHOW_UPLOADED_ATTACHMENT_BOX]: (state, action) => replyBool(state, action, 'showUploadAttachmentBox'),
	[SYNC_REVIEW_DATA]: (state, { payload }) => {
		if (!checkIfReviewExternalForward(payload)) {
			return state;
		}
		return update(state, { reply: { current: { $set: RPLY_EXT_FWD } } });
	},
	[TOGGLE_SHOW_RECIPIENTS]: selectShowRecipients,
	[TOGGLE_SHOW_REPLY_TOOLBAR]: selectShowReplyToolbar,
	[TOGGLE_SHOW_REPLY_ASSIST]: selectShowReplyAssist,
	[TOGGLE_SHOW_SUBJECT]: selectShowSubject,
	[TOGGLE_REPLY_OPTION_TAB]: selectReplyOptionTab,
	[ACQUIRE_ERRAND_OPERATION]: acquireOperations,
	[SET_ERRAND_HEADER_MOBILE_VIEW]: showErrandHeaderMobile,
	[SET_ERRAND_OPTION_MOBILE_VIEW]: showErrandOptionMobile,
	[TOGGLE_REPLYBOX_ERRANDS_MENU]: showReplyboxErrandsMenu,
	[SET_NAV_BUTTON_BUSY]: setNavButtonBusy,
	[CONTROL_FORWARD_TO_ACTION]: actionHandleForward,
	[SET_TEXT_FILE_PREVIEW_CONTENT]: setTextFilePreview,
	[SET_CSV_FILE_PREVIEW_CONTENT]: setCSVFilePreview,
	[SELECT_MANUAL_REPLY]: (state, action) => update(state, { manual: { currentReply: { $set: action.payload } } }),
	[SELECT_MANUAL_CALL_REPLY]: (state, action) => update(state, { manualCall: { currentReply: { $set: action.payload } } }),
	[SELECT_ALL_QUESTION_ATTACHMENT]: (state, action) => update(state, {
		showAttachment: { $set: true }
	}),
	[ERRAND_TOGGLE_RIGHT_PANEL]: (state, action) => update(state, { rightPanelOpen: { $set: action.payload } }),
	[ERRAND_TOGGLE_CHAT_SIDEPANEL]: (state, action) => update(state, {
		chatSidePanelOpen: { $set: action.payload }
		, rightSidePanelOpen: { $set: !action.payload ? !action.payload : state.rightSidePanelOpen }
		, showComment: { $set: false }
		, showCRM: { $set: false }
		, showAIAnswerPanel: { $set: false }
		, showKnowledgeBasePanel: { $set: false }
		, expandKnowledgeBasePanel: { $set: false }
		, collaboration: {
			show: { $set: false }
		},
	}),
	[ERRAND_SET_FOLDER_INDEX]: (state, action) => update(state, { gridFolderIndex: { $set: action.payload } }),
	[ERRAND_TOGGLE_RIGHT_SIDEPANEL]: (state, action) => update(state, { rightSidePanelOpen: { $set: action.payload } }), // TODO: this state is useless
	[TOGGLE_CHAT_REPLY_NAV_DD]: (state, action) => update(state, { reply: { showChatReplyNavDD: { $set: action.payload } } }),
	[SELECT_CHAT_REPLY_TABS]: (state, action) => update(state, { reply: { selectedChatReplyTab: { $set: action.payload } } }),
	[SET_KB_TREE_PARENT]: (state, action) => update(state, { knowledgeBase: { selectedCategory: { $set: action.payload } } }),
	[SET_SUGGESTED_ANSWER_USED]: (state, action) => update(state, { suggestedAnswerUsed: { $set: action.payload } }),
	[done(keyErrandWorkflowSettings)]: handleUIFromWfSettings,
};

const snapInputsToLastSaveReducer = (state, action) => {
	const { time } = action.payload;
	return update(state, {
		ui: {
			lastSave: {
				dirty: { $set: false }
				, previous: { $set: state.inputs }
				, time: { $set: time }
			}
		}
	});
};

const snapCollabInputsToLastSaveReducer = (state, action) => {
	const { time } = action.payload;
	return update(state, {
		ui: {
			lastSaveEE: {
				dirty: { $set: false }
				, previous: { $set: state.collaborationInputs }
				, time: { $set: time }
			}
		}
	});
};

const uiReducers = {
	[req(keyUpdateAnswer)]: snapInputsToLastSaveReducer
	, [req(keyUpdateAnswerEE)]: snapCollabInputsToLastSaveReducer
	, [ERRAND_OPENED_AND_READY]: (state, action) => {
		const { chat, replyOptions, hasAutoExtSystem } = action.payload
			, currentReply = state.ui.reply.current
			;
		if (replyOptions && replyOptions.length) {
			let found;
			$.each(replyOptions, (i, { key }) => {
				if (key === currentReply) {
					found = true;
					return false;
				}
			});
			if (!found) {
				state = update(state, {
					ui: {
						reply: {
							current: { $set: replyOptions[0].key }
						}
					}
				});
			}
		}
		if (!chat) {
			if (hasAutoExtSystem) {
				state = update(state, {
					ui: {
						autoOpenExtSystem: {
							$set: true
						}
					}
				});
			}
			return snapCollabInputsToLastSaveReducer(
				snapInputsToLastSaveReducer(state, action), action);
		}
		return state;
	}
};

const updateDirtyReducer = (state, action) => {
	if (state.lastSave.dirty) {
		return state;
	}
	return update(state, { lastSave: { dirty: { $set: true } } });
};

const updateDirtyCollabReducer = (state, action) => {
	if (state.lastSaveEE.dirty) {
		return state;
	}
	return update(state, { lastSaveEE: { dirty: { $set: true } } });
};

const updateDirtyCkeditorReducer = (state, action) => {
	if (!state.lastSave.ckeditor) {
		return state;
	}
	return update(state, { lastSave: { ckeditor: { $set: false } } });
};

const updateDirtyCkeditorCollabReducer = (state, action) => {
	if (!state.lastSaveEE.ckeditor) {
		return state;
	}
	return update(state, { lastSaveEE: { ckeditor: { $set: false } } });
};

const filterManualDirtyReducer = (state, action) => {
	const { manual } = action.payload;
	if (!manual) {
		return updateDirtyReducer(state, action);
	}
	return state;
};

const filterReplyDirtySubReducer = (state, action, ckeditor) => {
	const { reply, which } = action.payload;
	if (typeof reply === 'undefined' || (reply === RPLY_ERRAND ||
		reply === RPLY_EXT_FWD || which === "update_question")) {
		state = updateDirtyReducer(state, action);
		if (ckeditor) {
			state = updateDirtyCkeditorReducer(state, action);
		}
		return state;
	} else if (reply === RPLY_COLLABORATE) {
		// if (!state.lastSaveEE.firsttime) { // if its firsttime, do nothing
			state = updateDirtyCollabReducer(state, action);
			if (ckeditor) {
				state = updateDirtyCkeditorCollabReducer(state, action);
			}
		// } else {
		// 	state = update(state, { lastSaveEE: { firsttime: { $set: false } } });
		// }
	}
	return state;
};

const firstTimeCleanReducer = (state, action) => {
	state = update(state, {
		lastSaveEE: {
			ckeditor: { $set: true }
			, start: { $set: LSSS_VALID }
			, triggered: { $set: false }
		}
	});
	state = update(state, {
		lastSaveEE: {
			firsttime: { $set : true} // add the state to indicate its firsttime
		}
	});
	return update(state, {
		lastSave: {
			ckeditor: { $set: true }
			, start: { $set: LSSS_VALID }
			, triggered: { $set: false }
		}
	});
}

const _actionsDirtyInputsReducers = {
	[ADD_FILE_ARCHIVE_TO_ANSWER]: updateDirtyReducer
	, [ADD_LIBRARY_FILE_TO_ANSWER]: updateDirtyReducer
	, [CLEAR_COLLABORATE_ANS_ATTACHMENT]: updateDirtyReducer
	, [CLEAR_SELECTED_ARCHIVE]: updateDirtyReducer
	, [CLEAR_SELECTED_LIBRARY]: updateDirtyReducer
	, [DELETE_SAVED_ATTACHMENT]: updateDirtyReducer
	, [DELETE_UPLOADED_ATTACHMENT]: updateDirtyReducer
	// NOTE: update area change backend database so no need tracked it.
	// [ERRAND_AREA_OPERATION]: filterManualDirtyReducer,
	, [ERRAND_DUEDATE_OPERATION]: updateDirtyReducer
	, [ERRAND_OPENED_AND_READY]: firstTimeCleanReducer
	, [ERRAND_TAGS_OPERATION]: filterManualDirtyReducer
	, [INPUT_TEXT_CHANGES]: (state, action) => {
		if (action.payload.which === 'internal_comment') {
			return state;
		}
		return filterReplyDirtySubReducer(state, action,
			(action.payload.which === 'update_answer' ||
				action.payload.which === "update_question"));
	}
	, [INSERT_COLLABORATE_ANS_ATTACHMENT]: updateDirtyReducer
	, [RECIPIENTS_CHANGE]: (state, action) => filterReplyDirtySubReducer(state, action, false)
	, [SELECT_QUESTION_ATTACHMENT]: updateDirtyReducer
	, [SELECT_ALL_QUESTION_ATTACHMENT]: updateDirtyReducer
	, [SELECT_REPLY_CHANNEL]: filterManualDirtyReducer
	, [SELECT_COLLAB_REPLY_CHANNEL]: filterManualDirtyReducer
	, [SELECTED_PERSONALIZATION]: filterManualDirtyReducer
	, [SELECTED_PERSONALIZATION_DEFAULT]: filterManualDirtyReducer
	// NOTE: update priority change backend database so no need tracked it.
	// [TOGGLE_ERRAND_PRIORITY]: updateDirtyReducer,
	, [TOGGLE_LOCK_TO_ME]: updateDirtyReducer
	, [UPDATE_INPUT_TAGS]: updateDirtyReducer
	, [UPDATE_REPLY_CHECKBOXES]: (state, action) => {
		const { type } = action.payload;
		if (type === ECB_INC_QUESTION || type === ECB_PARTIAL_ANSWER) {
			return updateDirtyReducer(state, action);
		}
		return state;
	}
	, [UPLOAD_ERRAND_ANSWER_ATTACHMENT]: updateDirtyReducer
	, [APPEND_REPLY_ADDRESS]: (state, action) => {
		const cb = state.contactBook;
		if (cb.context === RPLY_ERRAND
			|| cb.context === RPLY_EXT_FWD) {
			return updateDirtyReducer(state, action);
		}
		return state;
	}
};

function lastSaveCanSaveSubReducer(state, action, canSave) {
	if (canSave != !state.lastSave.canSave) {
		return state;
	}
	return update(state, { lastSave: { canSave: { $set: canSave } } });
}

const _lastSaveReducers = {
	[AGENT_BUSY_TYPING]: (state, action) => lastSaveCanSaveSubReducer(state, action, false)
	, [AGENT_NOT_TYPING]: (state, action) => lastSaveCanSaveSubReducer(state, action, true)
};

const updateLastSaveReducer = reduceReducers(
	createReducer(null, _actionsDirtyInputsReducers)
	, createReducer(null, _lastSaveReducers)
	, (state, action) => {
		if (action.type !== INVALIDATE_LAST_SAVE_START) {
			return state;
		}
		return update(state, { lastSave: { start: { $set: LSSS_INVALID } } });
	}
	, (state, action) => {
		if (action.type !== MARK_LAST_SAVE_TRIGGERED) {
			return state;
		}
		return update(state, { lastSave: { triggered: { $set: action.payload } } });
	}
);

const _actionsDirtyInputsCollabReducers = {
	[ADD_FILE_ARCHIVE_TO_COLLABORATION]: updateDirtyCollabReducer
	, [CHANGE_COLLABORATION_ROLE]: updateDirtyCollabReducer
	, [CLEAR_COLLABORATE_ANS_ATTACHMENT]: updateDirtyCollabReducer
	, [COL_CLEAR_SELECTED_ARCHIVE]: updateDirtyCollabReducer
	, [DELETE_UPLOADED_COL_ATTACHMENT]: updateDirtyCollabReducer
	, [SELECT_EXIST_ATTACHMENT]: updateDirtyCollabReducer
	, [CLEAR_INTERNAL_COLLABORATORS]: updateDirtyCollabReducer
	, [SET_COLLABORATION_TRANSLATE_TO]: updateDirtyCollabReducer
	, [UPDATE_COLLABORATE_CB]: updateDirtyCollabReducer
	, [ADD_LIBRARY_FILE_TO_COLLABORATE]: updateDirtyCollabReducer
	, [COL_CLEAR_SELECTED_LIBRARY]: updateDirtyCollabReducer
	, [SELECT_INCLUDE_COLLAB_HISTORY]: updateDirtyCollabReducer
	, [SELECT_INCLUDE_ERRAND_HISTORY]: updateDirtyCollabReducer
	, [UPLOAD_COLLABORATION_ATTACHMENT]: updateDirtyCollabReducer
	, [SELECT_ALL_QUESTION_ATTACHMENT]: updateDirtyCollabReducer
	, [DELETE_SAVED_COL_ATTACHMENT] : updateDirtyCollabReducer
};

function lastSaveCollabCanSaveSubReducer(state, action, canSave) {
	return update(state, { lastSaveEE: { canSave: { $set: canSave } } });
}

const _lastSaveCollabReducers = {
	[AGENTEE_BUSY_TYPING]: (state, action) => lastSaveCollabCanSaveSubReducer(state, action, false)
	, [AGENTEE_NOT_TYPING]: (state, action) => lastSaveCollabCanSaveSubReducer(state, action, true)
};

const updateLastSaveCollabReducer = reduceReducers(
	createReducer(null, _actionsDirtyInputsCollabReducers)
	, createReducer(null, _lastSaveCollabReducers)
	, (state, action) => {
		if (action.type !== INVALIDATE_LAST_SAVE_START) {
			return state;
		}
		return update(state, { lastSaveEE: { start: { $set: LSSS_INVALID } } });
	}
	, (state, action) => {
		if (action.type !== MARK_EE_LAST_SAVE_TRIGGERED) {
			return state;
		}
		return update(state, { lastSaveEE: { triggered: { $set: action.payload } } });
	}
);

const uiReplyInit = {
	activateClosedErrandView: false,
	hideReply: false,
	show: false,
	showChatReplyNavDD: false,
	current: RPLY_ERRAND,
	showChannel: false,
	showAction: false,
	showCollaboratorList: false, // TODO: useless. control by internal state
	showRecipients: true,
	showReplyToolbar: true,
	showReplyAssist: true,
	showSubject: true,
	selectedReplyTab: false,
	selectedChatReplyTab: false,
	showTags: false,
	showArea: false,
	showAcquire: false,
	acquireTab: AET_OPEN,
	openType: AET_OPENTYPE_OWN,
	isOpenErrand: true,
	channels: [
		'email',
		'chat',
		'facebook',
		'line',
		'linkedin',
		'messenger',
		'twitter',
		'instagram'
	],
	showReactionPopup: false,
	showRatingPopup: false,
	showSendPMPopup: false,
	showUpdateAnswerPopup: false,
	showUploadAttachmentBox: false,
	showArchiveDD: false,
	stopScrollToQuestion: false,
	autoOpenExtSystem: false,
	shouldShowTweetWarning: false,
	numberOfTweet: 1,
	showWATemplatePopup: false
};

const manualErrandUIInit = {
	tab: ME_CREATE,
	currentReply: ME_CREATE,
	createAs: "",
	cipherKey: "",
	state: ME_ST_IDLE,
	createdId: NEW_MANUAL_ERRAND_ID,
	newErrandId: NEW_MANUAL_ERRAND_ID
};

const manualCallUIInit = {
	tab: ME_CREATE,
	currentReply: ME_CREATE,
	createAs: "",
	cipherKey: "",
	extRefId: "",
	state: ME_ST_IDLE,
	createdId: NEW_MANUAL_ERRAND_ID,
	newErrandId: NEW_MANUAL_ERRAND_ID
};

const knowledgeBaseUIInit = {
	showPopup: false,
	doSearch: false,
	show: false,
	selectedChannel: "",
	searchText: "",
	shownAnswer: "",
	shownQuestion: "",
	shownAttachments: "",
	shownId: "",
	shownShortcut: "",
	searchTimeoutId: 0,
	searchTimeoutDelay: 500,
	selectedLibrary: "",
	selectedCategory: "",
	reply: "",
	featuredQuestion: false,
	userVote: {
		comment: ''
		, editable: true
		, vote: 0
		, warning: false
	},
	questionNode: null
}

const rewriteAnswerBaseUIInit = {
	showPopup: false,
	generatingAns: false,
	currentQues: "",
	dataSrc: {
		questions: []
		, answers: []
	},
	shownId: "",
	shownShortcut: "",
	newContext: true, //this is no longer applicable , since we using `llmSessionId` for consistency, but keeping the code for reference
	llmSessionId: {},
	agentAssistContext: "",
	agentAssistLoading: false,
	agentAssisterror: "",
	currentStream: "",
	updateCount: 0,
	currentEid: 0,
}

const contactBookUIInit = {
	show: false,
	searchText: "",
	currentTab: 0,
	selectedPerson: 0,
	selectedContact: 0,
	replyType: "",
	context: "",
	error: "",
	/* more contactBook UI states */
}

const contactCardUIInit = {
	show: false,
	showContactHistory: false,
	showChannelOptions: false,
	selectedChannel: -1,
	channelType: -1,
	contactInput: "",
	customLabel: "",
	checkAnsweredHistory: false,
	checkUnansweredHistory: false,
	showMore: false,
	notesButton: true,
	noteText: "",
	noteAttachment: [],
	avatarPreview: "",
	avatarFilename: "",
	avatarFiletype: "",
	error: "",
	name: "",
	showCompanyInfo: false
	/* more contactCard UI states */
}

const searchShortcutUIInit = {
	showDropdown: false,
	showSearch: false
	/* more searchShortcut UI states */
}

const acquireErrandUIInit = {
	show: false
}

// Errand open reducer code - any reducer under this branch should be added
// top of this line as not break the init state and array of reducers.
const errandInputsInitState = {
	area: 0,
	tags: [],
	current_edit_note: {
		note: 0,
		errand: 0
	},
	classification: false,
	internal_comment: '',
	plain_internal_comment: '',
	internal_comment_uploaded_attachments: [],
	internal_comment_saved_attachments: [],
	answer_state: E_A_UNKNOWN,
	answer_id: 0,
	associated_list: [], // update from array
	reply_channel: RC_EMAIL,
	collab_channel: RC_EMAIL,
	checkboxes: DEF_REPLY_CHECKBOXES_STATE,
	update_area_id: 0,
	update_id: 0,
	update_channel: 0,
	done_date: '',
	update_from: '', // string or integer
	update_tags: '', // update from array TODO: seem useless
	selected_group_tags: '',
	selected_social_media: '',
	// question_histories: {
	// 	include_question: false,
	// 	question: false,
	// 	all: false
	// },
	translationTo: "",
	update_subject: '',
	update_attachment_total: 0,
	update_salutation: 0,
	update_signature: 0,
	update_to: [],
	update_cc: [],
	update_bcc: [],
	update_forward: [],
	postpone_errand_popup: false,
	update_lock: false,
	// update_close: false,
	update_priority: false,
	// update_library: '',
	// update_include_question: false,
	update_history: '',
	update_question: '',
	original_question: '',
	plain_answer: '',
	update_answer: '',
	update_answer_forward: '',
	update_question_subject: '',
	selected_externalforward_attachment: null, // forward's question attachments
	uploaded_attachments: [],
	saved_attachments: [],
	archive_attachments: [],
	question_attachments: null,
	library_attachments: [],
	external_expert_copy_text_status: '',
	update_external_expert_Answer_attachments: [],
	update_pintop: false,
	pms_body: "",
	posts_body: "",
	selected_history_attachments: null,
	selected_library_question: 0,
	templContent: "",
	templCode: "",
	templId: 0,
	quickReplyCont: "",
	quickReplyId: 0,
};

// Collaboration inputs
const colInputsInitState = {
	errandID: 0, // only use for indication of new opened errand
	queryID: 0,
	savedQueryID: 0,
	answerID: 0,
	threadID: 0,
	update_signature: 0, // string of the signature ID update_signature
	replyQuery: false, // flag indicate an expert replying an query
	answer_state: EXPERT_ANS_IDLE,
	update_subject: '', // subject
	update_to: [], // externalExpertAddresses, similar to Reply-To
	update_cc: [], // ccAddresses
	update_bcc: [], // bccAddresses
	saveRecipient: false,
	includeCollabHistory: false,
	selectedAgents: [],
	selectedAreas: [],
	includeErrandHistory: false,
	update_answer: '', // htmlBody
	translateTo: '',
	selectedAttachment: {},
	uploaded_attachments: [], // attachments
	saved_attachments: [],
	archive_attachments: [], // archiveAttachments
	library_attachments: [], // libraryAttachments
	canSendCollabToMedia: true,
	expandCollabThread: {} // expandCollabThread is a map of threadID to boolean for example, {1234: true}
};

const KB_UI_STATE = 'knowledgeBase';

const uiInit = {
	previewOrSaveEmlBusy: false,
	showErrandHeaderMobile: false,
	showErrandOptionMobile: false,
	showReplyboxErrandsMenu: false,
	showMoveToFolder: false,
	showForwardToArea: false,
	showForwardToAgent: false,
	shouldPopPrint: false,
	collaboration: {
		show: false
	},
	showCRM: false,
	hasCRM: false,
	lastSave: {
		previous: errandInputsInitState
		, time: 0
		// true mean ckeditor is fresh and no agent action yet.
		, ckeditor: false
		, dirty: false
		, start: LSSS_INVALID
		, triggered: false
		, canSave: false
	},
	lastSaveEE: {
		previous: colInputsInitState
		, time: 0
		// true mean ckeditor is fresh and no agent action yet.
		, ckeditor: false
		, dirty: false
		, start: LSSS_INVALID
		, triggered: false
		, canSave: false
	},
	navBtnBusy: false,
	showComment: false,
	reply: uiReplyInit,
	manual: manualErrandUIInit,
	manualCall: manualCallUIInit,
	showErrorNotifPopup: false,
	showAttachment: false,
	showVisitorPath: false,
	isAgentAttached: false,
	toggleAttachBox: false,
	textFilePreview: "",
	csvFilePreview: "",
	[KB_UI_STATE]: knowledgeBaseUIInit,
	rewriteAnswerBase: rewriteAnswerBaseUIInit,
	contactBook: contactBookUIInit,
	contactCard: contactCardUIInit,
	chatTranslation: {},
	gdpr: {
		exportLogs: [],
		exportPath: "",
		exportSecret: "",
		exportError: "",
		exportTimestampEnabled: "",
		exportExpirySecs: "",
		exportTimeZones: [],
		selectedLang: "",
		selectedTZ: ""
	},
	filterPopup: filterPopupInit,
	showExternalData: true,
	chatSatisfaction: {
		isToggleOn: false,
		isLikeIconActive: false,
		isUnlikeIconActive: false,
		chatRatingText: ""
	},
	clientChatSatisfaction: {
		isToggleOn: false,
		isLikeIconSelected: false,
		isUnlikeIconSelected: false,
		clientCommentText: ""
	},
	previousErrandId: 0,
	currentErrandOpening: false,
	rightPanelOpen: false, //Todo: probably rename this relating to chat
	chatSidePanelOpen: false,
	gridFolderIndex: 0,
	rightSidePanelOpen: false,
	searchShortcut: searchShortcutUIInit,
	acquireErrand: acquireErrandUIInit,
	showKnowledgeBasePanel: false,
	expandKnowledgeBasePanel: false,
	suggestedAnswerUsed: false,
	showAIAnswerPanel: false,
};

const errandUILocalReducer = createReducer(uiInit, uiLocalReducers);

// const sourceCloneToInputs = src => {
// 	let tags = [];
// 	if(src.tags && src.tags.length) {
// 		tags = src.tags.map(e => e);
// 	}
// 	return {
// 		area: src.area,
// 		tags,
// 		update_subject: src.answer.subject,
// 		update_answer: src.answer.body,
// 		update_channel: src.channel,
// 		update_question_subject: src.subject,
// 		update_question: src.body
// 	};
// };

const errandInputsReducer = (st, act) => {
	if (act.type === SET_CURRENT_ERRAND) {
		if (act.payload.chat) {
			return update(st, {
				inputs: {
					$merge: {
						area: act.payload.chat.errand.data.area,
						// TODO set these for chat as appropriate for the current chat.
						tags: [],
						saved_attachments: [],
						uploaded_attachments: [],
						archive_attachments: [],
						library_attachments: [],
						update_external_expert_Answer_attachments: []
					},
				}
			});
		}
		// const errand = st.errand.erd[14376]; // TODO: switch it back to use st.currentErrand.id
		// return update(st, {inputs: {$merge: sourceCloneToInputs(errand)}});
	} else if (act.type === INPUT_TEXT_CHANGES) {
		const { which, reply, isCall, ...p } = act.payload;
		let inpt;
		if (reply === RPLY_MANUAL) {
			if (isCall) {
				inpt = INPUTS_MANUAL_CALL;
			} else {
				inpt = INPUTS_MANUAL_ERRAND;
			}
		} else if (reply === RPLY_COLLABORATE) {
			inpt = INPUTS_COLLABORATION;
			if (which === 'update_question' || which === 'internal_comment') {
				// collaboration do not has question or comment to update.
				return st;
			}
		} else {
			inpt = INPUTS_OPEN_ERRAND;
		}
		if (which === 'update_subject' ||
			which === 'update_question') {
			return update(st, { [inpt]: { [which]: { $set: p.value } } });
		} else if (which === 'internal_comment') {
			return update(st, {
				[inpt]: {
					internal_comment: { $set: p.value },
					plain_internal_comment: { $set: p.plain }
				}
			});
		} else if (which === 'update_answer') {
			let isSuggestedAnswerUsed = st.ui.suggestedAnswerUsed;
			let suggestedAns = "", trimmedSuggestions = "";
			if(st.suggestedAnswer && st.suggestedAnswer.data && st.suggestedAnswer.data.suggested_answer) {
				if(st.suggestedAnswer.data.suggested_answer.length > 0 && st.suggestedAnswer.data.suggested_answer[0].answer) {
					suggestedAns = st.suggestedAnswer.data.suggested_answer[0].answer;
					trimmedSuggestions = suggestedAns.replace(/<[^>]*>?/gm, '');
				}
			}
			let innerReplyTxt = "";
			if(p.value != "") {
				const tempElement = document.createElement("div");
				tempElement.innerHTML = p.value;
				innerReplyTxt = tempElement.textContent || tempElement.innerText || "";
				innerReplyTxt = innerReplyTxt.replace(/\s+/g, ' ').trim();
			}

			// Can just change the value of EDITED_OFFSET to change the percentage of difference, change to 0.0 to completely treated edited answer as the new answer
			const EDITED_OFFSET = 3; //change to 0 to treat edited answer as new answer
			const diffPercentage = compareText(trimmedSuggestions, innerReplyTxt);
			//if 0.0, no changes in the answer
			//adding just spaces will still be treated as 0 changes
			//if 100.0, the answer is completely different
			if(isSuggestedAnswerUsed && diffPercentage > EDITED_OFFSET) {
				isSuggestedAnswerUsed = false;
			}

			return update(st, {
				[inpt]: {
					update_answer: { $set: p.value },
					plain_answer: { $set: p.plain }
				},
				ui: {
					suggestedAnswerUsed: { $set: innerReplyTxt.trim() == "" ? false : isSuggestedAnswerUsed }
				}
			});
		} else if (which === RPLY_QUESTION) {
			return update(st, {
				[inpt]: {
					update_question: { $set: p.value }
				}
			});
		}
	} else if (act.type === EDIT_ERRAND_NOTES) {
		const { note, errand, value, attachments } = act.payload;
		return update(st, {
			inputs: {
				current_edit_note: { $set: { note, errand } },
				internal_comment: { $set: value },
				plain_internal_comment: { $set: value },
				internal_comment_saved_attachments: { $set: attachments }
				// purposely do not clear uploaded attachments
			}
		});
	} else if (act.type === ERRAND_TAGS_OPERATION) {
		const { opr, manual, isCall, ...p } = act.payload;
		let inpt = (manual) ? (isCall ? INPUTS_MANUAL_CALL : INPUTS_MANUAL_ERRAND) : INPUTS_OPEN_ERRAND;
		if (opr === 'show') {
			return update(st, { ui: { reply: { showTags: { $set: p.value } } } });
		} else if (opr === TAG_ADD) {
			let exist, index;
			$.each(st[inpt].tags, (i, v) => {
				const input = p.value
				if (v.length == input.length) {
					let notMatch;
					$.each(v, (j, w) => {
						if (w != input[j]) {
							notMatch = true;
							return false;
						}
					});
					if (!notMatch) {
						exist = true;
						index = i;
						return false;
					}
				}
			});
			if (!exist) {
				return update(st, { [inpt]: { tags: { $push: [p.value] } } });
			}
		} else if (opr === TAG_DELETE) {
			return update(st, { [inpt]: { tags: { $splice: [[p.value, 1]] } } });
		} else if (opr === TAG_CLEAR) {
			return update(st, { [inpt]: { tags: { $set: emptyArray } } });
		}
	} else if (act.type === ERRAND_AREA_OPERATION) {
		const p = act.payload, v = p.value;
		if (p.opr === 'show') {
			return update(st, { ui: { reply: { showArea: { $set: v } } } });
		} else if (p.opr === 'change') {
			const t = typeof v;
			let inpt = (p.manual) ? (p.isCall ? INPUTS_MANUAL_CALL : INPUTS_MANUAL_ERRAND) : INPUTS_OPEN_ERRAND;
			if (t === 'string') {
				let s = v.split(',');
				return update(st, { [inpt]: { area: { $set: parseInt(s[0], 10) } } });
			} else if (t === 'number') {
				return update(st, { [inpt]: { area: { $set: v } } });
			}
		}
	} else if (act.type === SELECT_REPLY_CHANNEL) {
		const { channel, manual, isCall } = act.payload;
		let inpt = (manual) ? (isCall ? INPUTS_MANUAL_CALL : INPUTS_MANUAL_ERRAND) : INPUTS_OPEN_ERRAND;
		return update(st, { [inpt]: { reply_channel: { $set: channel } } });
	} else if (act.type === SELECT_COLLAB_REPLY_CHANNEL) {
		const { channel, manual } = act.payload;
		let inpt = (manual) ? (isCall ? INPUTS_MANUAL_CALL : INPUTS_MANUAL_ERRAND) : INPUTS_OPEN_ERRAND;
		return update(st, { [inpt]: { collab_channel: { $set: channel } } });
	} else if (act.type === UPDATE_REPLY_TO_AND_CHANNEL) {
		const { service, toAddress } = act.payload;
		return update(st, {
			[INPUTS_OPEN_ERRAND]: {
				update_to: { $set: [toAddress] },
				update_channel: { $set: service }
			}
		});
	} else if (act.type === TOGGLE_POSTPONE_ERRAND) {
		return update(st, { inputs: { postpone_errand_popup: { $set: act.payload } } });
	} else if (act.type === TOGGLE_LOCK_TO_ME) {
		const { payload } = act;
		return update(st, { inputs: { update_lock: { $set: payload.lock } } });
	} else if (act.type === TOGGLE_ERRAND_PRIORITY) {
		const { payload } = act;
		return update(st, { inputs: { update_priority: { $set: payload } } });
	} else if (act.type === UPDATE_INPUT_TAGS) {
		const { areaTags, manual, isCall } = act.payload;
		let newTags = [], tags, inpt = (manual) ? (isCall ? INPUTS_MANUAL_CALL : INPUTS_MANUAL_ERRAND) : INPUTS_OPEN_ERRAND;
		tags = st[inpt].tags;
		$.each(tags, (i, v) => {
			if (isInsideAreaTags(areaTags, v)) {
				newTags.push(v);
			}
		});
		return update(st, { [inpt]: { tags: { $set: newTags } } });
	} else if (act.type === CLASSIFICATION_TAGS_OPERATION) {
		const { opr, value, id, isDelErrand } = act.payload;
		if (opr === 'add') {
			let exist, index;
			$.each(st.inputs.classification.tags[id], (i, v) => {
				if (v.length == value.length) {
					let notMatch;
					$.each(v, (j, w) => {
						if (w != value[j]) {
							notMatch = true;
							return false;
						}
					});
					if (!notMatch) {
						exist = true;
						index = i;
						return false;
					}
				}
			});
			if (!exist) {
				return update(st, {
					inputs: {
						classification: {
							tags: {
								[id]: {
									$push: [value]
								}
							}
						}, isDeleting: { $set: isDelErrand }
					}
				});
			}
		} else if (opr === 'del') {
			return update(st, {
				inputs: {
					classification: {
						tags: {
							[id]: {
								$splice: [[value, 1]]
							}
						}
					}
				}
			});
		}
	} else if (act.type === RECIPIENTS_CHANGE) {
		const { which, value, reply, isCall } = act.payload,
			updateRecipient = 'update_' + which;
		let inpt;
		if (reply === RPLY_MANUAL) {
			if (isCall) {
				inpt = INPUTS_MANUAL_CALL;
			} else {
				inpt = INPUTS_MANUAL_ERRAND;
			}
		} else if (reply === RPLY_COLLABORATE) {
			inpt = INPUTS_COLLABORATION;
		} else {
			inpt = INPUTS_OPEN_ERRAND;
		}
		return update(st, { [inpt]: { [updateRecipient]: { $set: value } } });
	} else if (act.type === TOGGLE_PINTOP_ERRAND) {
		const { payload } = act;
		return update(st, { inputs: { update_pintop: { $set: payload } } });
	} else if (act.type === ERRAND_DUEDATE_OPERATION) {
		const p = act.payload;
		return update(st, { inputs: { done_date: { $set: p } } });
	} else if (act.type === SELECTED_PERSONALIZATION) {
		const { field, value, reply, isCall } = act.payload;
		let inpt;
		if (reply === RPLY_MANUAL) {
			if (isCall) {
				inpt = INPUTS_MANUAL_CALL;
			} else {
				inpt = INPUTS_MANUAL_ERRAND;
			}
		} else if (reply === RPLY_COLLABORATE) {
			inpt = INPUTS_COLLABORATION;
		} else {
			inpt = INPUTS_OPEN_ERRAND;
		}
		return update(st, { [inpt]: { [field]: { $set: value } } });
	} else if (act.type === SELECTED_PERSONALIZATION_DEFAULT) {
		const { field, value, reply, isCall } = act.payload;
		let inpt;
		if (isCall) {
			inpt = INPUTS_MANUAL_CALL;
		} else {
			inpt = INPUTS_MANUAL_ERRAND;
		}
		return update(st, { [inpt]: { [field]: { $set: value } } });
	} else if (act.type === APPEND_REPLY_ADDRESS) {
		const cb = st.ui.contactBook;
		let address = act.payload.email, isCall = act.payload.isCall, inpt,
			id = act.payload.id;
		if (cb.context === RPLY_MANUAL) {
			if (isCall) {
				inpt = INPUTS_MANUAL_CALL;
			} else {
				inpt = INPUTS_MANUAL_ERRAND;
			}
		} else if (cb.context === RPLY_COLLABORATE) {
			inpt = INPUTS_COLLABORATION;
		} else {
			inpt = INPUTS_OPEN_ERRAND;
		}
		if (address != "") {
			let items = [];
			$.each(address.split(','), (k, v) => {
				items.push({ id: v, value: v });
			});
			if (cb.replyType === RECIPIENT_TO) {
				if (inpt != INPUTS_COLLABORATION &&
					typeof st[inpt] === "undefined") {
					return st;
				}
				return update(st, { [inpt]: { update_to: { $push: items } } });
			} else if (cb.replyType === RECIPIENT_CC) {
				return update(st, { [inpt]: { update_cc: { $push: items } } });
			} else if (cb.replyType === RECIPIENT_BCC) {
				return update(st, { [inpt]: { update_bcc: { $push: items } } });
			} else if (cb.replyType === RECIPIENT_FORWARD) {
				return update(st, { [inpt]: { update_forward: { $push: items } } });
			}
		}
		if (cb.replyType === RECIPIENT_INTERNAL_COLLABORATE
			&& typeof id == 'number' && id > 0) {
			const agents = st.collaborationInputs.selectedAgents;
			if (agents.indexOf(id) === -1) {
				return update(st, {
					[inpt]: {
						selectedAgents: { $push: [id] }
					}
				});
			}
			return st;
		}
	} else if (act.type == SET_AREA_RECIPIENT) {
		let inpt = INPUTS_COLLABORATION;
		return update(st, {
			[inpt]: {
				selectedAreas: { $set: act.payload }
			}
		});
	} else if (act.type === SET_REPLY_ADDRESS) {
		const cb = st.ui.contactBook;
		let address = act.payload.email, isCall = act.payload.isCall, inpt,
			id = act.payload.id;
		if (cb.context === RPLY_MANUAL) {
			if (isCall) {
				inpt = INPUTS_MANUAL_CALL;
			} else {
				inpt = INPUTS_MANUAL_ERRAND;
			}
			if (address != "") {
				let items = [];
				$.each(address.split(','), (k, v) => {
					items.push({ id: v, value: v });
				});
				if (cb.replyType === RECIPIENT_TO) {
					return update(st, { [inpt]: { update_to: { $set: items } } });
				}
			}
		}
	} else if (act.type === INPUT_TEXT_RESET) {
		const { which } = act.payload;
		const origQuestion = st.inputs.original_question;
		if (which === RPLY_QUESTION) {
			return update(st, {
				inputs: {
					update_question: { $set: origQuestion }
				}
			});
		}
	} else if (act.type === UPDATE_TWEET_BOX_TOGGLE_NUMBER_OF_TWEET) {
		const { tgl, nt } = act.payload;
		return update(st, {
			ui: {
				reply: {
					shouldShowTweetWarning: { $set: tgl },
					numberOfTweet: { $set: nt }
				}
			}
		});
	} else if (act.type === SELECT_ALL_HISTORY_ATTACHMENT) {
		return historyAttachmentsReducer(st, act);
	} else if (act.type === INPUT_CK_RESET) {
		return update(st, {
			ui: {
				lastSave: {
					ckeditor: { $set: false }
					, start: { $set: LSSS_VALID }
					, triggered: { $set: false }
					, dirty: { $set: false }
					, canSave: { $set: false }
				},
				lastSaveEE: {
					ckeditor: { $set: false }
					, start: { $set: LSSS_VALID }
					, triggered: { $set: false }
					, dirty: { $set: false }
					, canSave: { $set: false }
				}
			}
		});
	}
	return st;
};

const classificationReducer = (state, action) => {
	const { opr, value, promise } = action.payload;
	if (opr === 'start') {
		const { param, errands, tags, option, actType } = value
			, { ...noTag } = tags
			;
		// classification fields:
		// param - created answer parameters and send is clicked.
		// errands - array of errand id to be tagged including the current
		//           errand.
		// same - flag to determine if next errand tagging should follow errand
		//        tagged tags.
		// index - current index position of errands field that is being
		//         tagging.
		// tags - object with keys of errand id and value of array of array of
		//        tag. Current errand tags is NOT include here.
		return update(state, {
			classification: {
				$set: {
					promise
					, param
					, errands
					, tags
					, option
					, same: true
					, firstTags: null
					, tagsMap: null
					, condition: null
					, index: 0
					, isDeleting: actType
				}
			}
		});
	} else if (opr === 'next') {
		const { param, index, tag, firstTags, tagsMap } = value
			, c = state.classification
			;
		let s = { param: { $set: param }, index: { $set: index } };
		if (tag) {
			const id = c.errands[index].id;
			s.tags = { [id]: { $set: tag } };
		}
		if (!c.firstTags || !c.firstTags.length) {
			s.firstTags = { $set: firstTags };
			s.tagsMap = { $set: tagsMap }
		}
		return update(state, { classification: s });
	} else if (opr === 'update') {
		const { tag, noTag } = value;
		if (!tag) {
			return state;
		}
		const c = state.classification
			, { id } = c.errands[c.index]
			;
		return update(state, {
			classification: {
				tags: { [id]: { $set: tag } }
				, condition: { $set: noTag }
			}
		});
	} else if (opr === 'cancel' || opr === 'done') {
		return update(state, { classification: { $set: false } });
	}
	return state;
};

const classificationPreviousTagsReducer = (state, action) => {
	if (!state.classification) {
		return state;
	}
	const tags = action.payload.domain.data.errand_tags, id = action.payload.id;
	return update(state, { classification: { tags: { $merge: { [id]: tags } } } });
};

const answerStateReducer = (state, action) => {
	const { state: st, id } = action.payload;
	return update(state, { answer_state: { $set: st }, answer_id: { $set: id } });
};

const initOpenErrand = {
	id: 0,
	type: JUST_LOADED_STR,
	ready: false,
	basic: false,
	area: false,
	acquire: false,
	extend: false,
	otherAddresses: false,
	history: false,
	contacts: false,
	internalComment: ICST_UNKNOWN,
	smData: {
		service: 0,
		serviceName: "",
		isFBVKpm: false,
		isTwtPm: false,
		isINSTpm: false,
		sendPm: false,
		retweet: 0,
		action: "",
		like: "",
		closed: false,
		answerDeleted: false,
		hasAnswer: false
	},
	contactCard: {
		channel: emptyArray,
		customer: {
			id: 0,
			name: "",
			date: "",
			externalId: "",
			city: "",
			postcode: "",
			companyId: UNSELECT,
			companyName: ""
		},
		contactList: []
	},
	contactBook: [],
	customerNotes: []
};

const openErrandReducer = (st = initOpenErrand, act) => {
	if (act.type === RELOAD_CURRENT_EXPERT) {
		let colData;
		if (st.collaboration) {
			colData = update(st.collaboration, {
				$merge: {
					ee: { $set: false },
					eeList: { $set: false }
				}
			});
		} else {
			colData = {
				ee: false,
				eeList: false,
				num: 0,
				denom: 0
			};
		}
		return update(st, { collaboration: { $set: colData } });
	} else if (act.type === SET_CURRENT_ERRAND) {
		const { ee, isSwitching } = act.payload;
		let collaboration;
		if (ee) {
			const { num, denom } = ee;
			collaboration = { ee: false, eeList: false, num, denom };
		}
		return update(st, {
			$merge: {
				ready: isSwitching ? st.ready : false,
				area: false,
				basic: false,
				acquire: false,
				extend: false,
				otherAddresses: false,
				history: false,
				contacts: false,
				internalComment: ICST_UNKNOWN,
				collaboration
			}
		});
	} else if (act.type === SOCIAL_MEDIA_ERRANDS_ACTIONS) {
		let lkAction = st.smData.like, hdAction = st.smData.action, retwt = st.smData.retweet;
		switch (act.payload.which) {
			case "unlike": lkAction = "unliked"; break;
			case "like": lkAction = "liked"; break;
			case "hide": hdAction = "hidden"; break;
			case "unhide": hdAction = ""; break;
			case "delete": hdAction = "deleted"; break;
			case "retweet": retwt = true; break;
			case "unretweet": retwt = false; break;
			default:
				console.log("dbg: unhandled action: %s", act.payload.which);
		}
		return update(st, {
			smData: {
				["like"]: { $set: lkAction },
				["action"]: { $set: hdAction },
				["retweet"]: { $set: retwt },
			}
		}
		);
	} else if (act.type == UPDATE_CURRENT_ERRAND_HAS_EE) {
		const { ee } = act.payload;
		if (ee) {
			const { num, denom } = ee;
			let collaboration = { ee: false, eeList: false, num, denom };
			if (st.collaboration) {
				st = update(st, { collaboration: { $merge: collaboration } });
			} else {
				st = update(st, { collaboration: { $set: collaboration } });
			}
		}
	}
	return st;
};

const setCurrentChatErrandReducer = (state, action) => {
	if (action.type !== SET_CURRENT_ERRAND) {
		return state
	}
	const { chat } = action.payload
	if (!chat) {
		return state
	}
	const { errand } = chat
	const { data } = errand
	// TODO: can not use initialChatData because this global var is not a constant
	// and it can change base on websocket data. This break the reducer rule.
	const areaData = initialChatData.chatAreaById[data.area]
	let afa
	if (areaData) {
		afa = areaData.FileArchive
	}
	return update(state, {
		acquireErrand: {
			data: {
				$set: {
					acquire: update(
						errand, {
						owner: {
							$set: {
								id: data.agentId,
								photo: data.agentAvatar,
								name: data.agent
							}
						}
					}
					)
				}
			}
		},
		basic: { data: { $set: errand } },
		currentErrand: {
			acquire: { $set: true },
			basic: { $set: true },
			errand: { $set: { ...errand, isChat: true } },
			extend: { $set: true },
			history: { $set: true },
			internalComment: { $set: ICST_NO_DATA },
			smData: { $set: emptyObject }
		},
		fetchExtendedData: {
			data: {
				$set: {
					data: {
						area_file_archive: afa,
						errand_tags: chat.tags
					}
				}
			}
		},
		fetchHistory: {
			data: {
				opr: {
					$set: {
						[errand.id]: { selected: true },
						all: false
					}
				}
			}
		}
	})
}

const updateOpenErrandState = (st = initOpenErrand, act) => {
	if (act.type === done(keyLoadBasicErrand)
		|| act.type === done(keyOpenErrandData)) {
		let p = act.payload.data.data;
		if (act.type === done(keyOpenErrandData)) {
			let mcam = mcamByID('fetchBasicData', act.payload.data)
			p = mcam.data.data;
		}
		return update(st, {
			basic: { $set: true },
			smData: {
				$set: {
					service: p.service,
					serviceName: p.serviceName,
					isFBVKpm: p.fbPms,
					isTwtPm: p.twitterPm,
					sendPm: p.sendPm,
					retweet: p.retweet,
					action: p.fbAction,
					like: p.fbLikes,
					closed: p.closed,
					answerDeleted: p.answerDeleted,
					hasAnswer: p.hasAnswer,
				}
			}
		});
	} else if (act.type === XFER_DOMAIN_AREA_DATA) {
		return update(st, { area: { $set: true } });
	} else if (act.type === done(keyAcquireErrand)) {
		return update(st, { acquire: { $set: true } });
	} else if (act.type === done(keyFetchExtendedData)) {
		return update(st, { extend: { $set: true } });
	} else if (act.type === done(keyFetchExternalQueries) ||
		act.type === XFER_DOMAIN_EE_QUERY) {
		if (st.collaboration) {
			return update(st, { collaboration: { ee: { $set: true } } });
		}
	} else if (act.type === done(keyFetchExternalExpertList) ||
		act.type === XFER_DOMAIN_EE_LIST) {
		if (st.collaboration) {
			return update(st, { collaboration: { eeList: { $set: true } } });
		}
	} else if (act.type === NOTE_SIZE) {
		const { size, type } = act.payload;
		if (size === 0) {
			return update(st, { internalComment: { $set: ICST_NO_DATA } });
		} else if (size > 0) {
			return update(st, { internalComment: { $set: ICST_DATA_FETCHED } });
		}
	} else if (act.type === done(keyFetchErrandNotes)) {
		return update(st, { internalComment: { $set: ICST_DATA_FETCHED } });
	}
	return st;
};

const updateLightDomainBasicSubReducer = (state, action, errandID, light) => {
	const e = state[D_BASIC_ERRANDS].byId[errandID];
	if (!e || !e.data.collaboration) {
		return state;
	}
	return update(state, {
		[D_BASIC_ERRANDS]: {
			byId: {
				[errandID]: {
					data: {
						collaboration: { light: { $set: light } }
					}
				}
			}
		}
	});
};

const updateLightFromEEListReducer = (state, action) => {
	const { data, param } = action.payload;
	if (data && data.error) {
		return state;
	}
	const id = param.errand, light = data.extra.light;
	return updateLightDomainBasicSubReducer(state, action, id, light);
};

const domainChatErrand = (state, action) => {
	let packet = action.packet
		, args = packet.args
		;
	if (packet.event == evtCHAT_REGISTER || packet.event == evtCHAT_ERRAND_ADD) {
		if (args[0].chatErrands) {
			let time = Date.now();
			$.each(args[0].chatErrands, (i, v) => {
				state = addNormalizedDomain(state, D_BASIC_ERRANDS, v.errand.id, v.errand, time)
			})
		}
	}
	return state;
}

export const domain = {
	[WS_EVENT]: domainChatErrand,
	[done(keyErrandAreaData)]: reduceReducers(
		mcamCountersReducer
		, domainAreaData
		, (state, action) => {
			const { data, time } = action.payload
				, a = areaDataSelector(FETCH_AREA_DATA_STR, data)
				;
			if (!a) {
				return state;
			}
			const { agents } = a;
			let ids = [];
			$.each(agents, (i, { id }) => {
				ids.push(id);
			});
			if (!ids.length) {
				ids = emptyArray;
			}
			return addNormalizedDomain(state, D_FORWARD_AGENTS, a.id, ids, time);
		}
	),
	[done(keyErrandContacts)]: domainOtherContacts,
	[done(keyErrandContactsHistories)]: domainOtherContacts,
	[done(keyAcquireErrand)]: mcamCountersReducer,
	[done(keyFetchClientAddress)]: mcamCountersReducer,
	[done(keyFetchExternalQueries)]: domainWFExpertQueries,
	[done(keyFetchExtendedData)]: mcamCountersReducer,
	[done(keyOpenErrandData)]: reduceReducers(
		mcamCountersReducer,
		domainFetchHistoryMCAM,
		domainBasicErrandMCAM
	),
	[done(keyLoadBasicErrand)]: domainBasicErrand,
	[done(keyFetchHistory)]: domainFetchHistory,
	[done(keyGetOneRelatedErrand)]: getOneRelatedErrandAsyncDomainReducer,
	[done(keyFetchExternalExpertList)]: reduceReducers(
		domainEEList,
		updateLightFromEEListReducer
	),
	[done(keyFetchExternalExpertThread)]: domainEEThread,
	[done(keyFetchErrandNotes)]: domainErrandNotes,
	[done(keyDeleteOneErrandNotes)]: domainDeleteErrandNotes,
	[done(keyCreateOneErrandNotes)]: domainAddErrandNotes,
	[done(keyUpdateOneErrandNotes)]: domainUpdateErrandNotes,
	[done(keyExternalexpertEdit)]: domainEEEdit,
	[done(keyTurnExternalExpertLightOff)]: (state, action) => {
		// directly change the light state for collaboration without reloading
		// the light data. DOMAIN branch.
		const { data, param } = action.payload;
		if (data && data.error) {
			return state;
		}
		const id = param.errand, light = data.light;
		return updateLightDomainBasicSubReducer(state, action, id, light);
	}
};

const notesOperationReducer = (state, action) => {
	const { opr } = action.payload;
	if (opr === NOTEOPR_DEFAULT) {
		return update(state, {
			current_edit_note: { $set: { note: 0, errand: 0 } },
			internal_comment: { $set: '' },
			plain_internal_comment: { $set: '' },
			internal_comment_uploaded_attachments: { $set: [] },
			internal_comment_saved_attachments: { $set: [] }
		});
	} else if (opr === NOTEOPR_CREATE_NEW) {
		return update(state, {
			current_edit_note: { $set: { note: 0, errand: 0 } },
			internal_comment: { $set: '' },
			plain_internal_comment: { $set: '' },
			internal_comment_saved_attachments: { $set: [] }
		});
	}
	return state;
};

const updateCollaborationInputsReducer = (state, action) => {
	const eee = state.externalExpertEdit.domain.list;
	let selectedAttachment = {};
	$.each(eee.oldAttachments, (k, v) => {
		selectedAttachment[v.attachmentId] = false;
	});
	$.each(eee.errandAttachments, (i, e) => {
		$.each(e.attachments, (k, v) => {
			selectedAttachment[v.attachmentId] = false;
		});
	});
	let selectedAgents = [];
	if (eee.selectedToInternals) {
		let IDs = Object.keys(eee.selectedToInternals);
		$.each(IDs, (i, id) => {
			selectedAgents.push(parseInt(id, 10));
		})
		//selectedAgents = list.toString();
	}
	let selectedAreas = [];
	if (eee.selectedToAreas) {
		let IDs = Object.keys(eee.selectedToAreas);
		$.each(IDs, (i, id) => {
			selectedAreas.push(parseInt(id, 10));
		})
	}
	return update(state, {
		collaborationInputs: {
			selectedAgents: { $set: selectedAgents },
			selectedAreas: { $set: selectedAreas },
			update_subject: { $set: eee.txtSubject },
			update_to: { $set: eee.selectedToAddress },
			update_cc: { $set: eee.selectedCcAddress },
			update_bcc: { $set: eee.selectedBccAddress },
			saveRecipient: { $set: false },
			includeCollabHistory: { $set: eee.includeCollabHistory },
			includeErrandHistory: { $set: eee.includeErrandHistory },
			update_answer: { $set: eee.message },
			selectedAttachment: { $set: selectedAttachment },
			update_signature: { $set: eee.selectedSignature },
			uploaded_attachments: { $set: [] },
			saved_attachments: { $set: eee.savedAttachments},
			archive_attachments: { $set: [] },
			library_attachments: { $set: [] },
			savedQueryID: { $set: eee.currentSavedQueryId }
		}
	});
};

const updateInputsCollaborationChannelReducer = (state, action) => {
	const eee = state.externalExpertEdit.domain.list;
	let collabChannel = RC_EMAIL
	if (eee.selectedToAddress.length >= 1) {
		if (eee.selectedToAddress[0].service ==
			REPLY_CHANNEL_TO_SERVICE_TYPE[RC_SLACK]) {
			collabChannel = RC_SLACK;
		} else if (eee.selectedToAddress[0].service == REPLY_CHANNEL_TO_SERVICE_TYPE[RC_MSTEAMS]) {
			collabChannel = RC_MSTEAMS;
		} else if (eee.selectedToAddress[0].service == REPLY_CHANNEL_TO_SERVICE_TYPE[RC_JIRA]) {
			collabChannel = RC_JIRA;
		} else if (eee.selectedToAddress[0].service == REPLY_CHANNEL_TO_SERVICE_TYPE[RC_GOOGLECHAT]) {
			collabChannel = RC_GOOGLECHAT;
		}
	}
	return update(state, { inputs: { collab_channel: { $set: collabChannel } } });
};

const setDefaultSettings = (state, action) => {
	let lock = false, priority = state.update_priority;
	if (!action.payload.chat) {
		let wfd = action.payload.wfSettings.data;
		let extErrandData = action.payload.extendedErrand.data;
		if (wfd.lockToMe) {
			if (wfd.lockToMeCheckBoxDefaultState) {
				lock = true;
				// if(extErrandData.errandAnswer == true){
				// 	lock = extErrandData.errand_lock
				// }
			} else {
				lock = (extErrandData.errand_lock ? true : false);
			}
		}
		if (wfd.highPriority) {
			if (extErrandData.errandAnswer) {
				priority = (extErrandData.errand_priority ? true : false);
			}
		}
	}
	return update(state, {
		update_lock: { $set: lock },
		update_priority: { $set: priority }
	});
};

const socialMediaInputReducersData = (state, action) => {
	if (action.payload.acType === "sendpm" || action.payload.acType === "updateAnswer") {
		return update(state, { pms_body: { $set: action.payload.msg } });
	}
	return state;
};

const uploadedAttachmentSubReducer = (state, action, field) => update(state, { [field]: { $push: [action.payload] } });

const uploadedManualErrandSavedAttSubReducer = (state, action) => {
	if (typeof action.payload.files !== 'undefined') {
		return update(state, { saved_attachments: { $set: action.payload.files } });
	}
	return state;
}

const deleteAllUploadedAttachments = (state, action) => {
	return update(state, { uploaded_attachments: { $set: emptyArray } });
}

const uploadAttachmentsReducer = (state, action) => uploadedAttachmentSubReducer(state, action, 'uploaded_attachments');

function simpleDeleteAttachmentSubReducer(state, action, field) {
	const id = action.payload.id;
	let found, index;
	$.each(state[field], (i, v) => {
		if (v.id === id) {
			found = true;
			index = i;
			return false;
		}
	});
	if (found) {
		return update(state, { [field]: { $splice: [[index, 1]] } });
	} else {
		console.log('dbg: delete ' + field + ' not found:', id);
	}
	return state;
}

const deleteAttachmentSubReducer = (state, action, attachmentType) => {
	if (action.payload.which === DEL_ANSWER_ATTACHMENT) {
		return simpleDeleteAttachmentSubReducer(state, action, attachmentType);
	}
	return state;
};

const deleteUploadedAttachmentReducer = (state, action) => deleteAttachmentSubReducer(state, action, 'uploaded_attachments');

const checkAndInsertSubReducer = (state, action, field, current) => {
	const id = current.id;
	let found, index;
	$.each(state[field], (i, v) => {
		if (v.id === id) {
			found = true;
			index = i;
			return false;
		}
	});
	if (!found) {
		return update(state, { [field]: { $push: [current] } });
	}
	return state;
};

const addArchiveReducer = (state, action) => {
	const { selectedId, archiveList } = action.payload;
	let found, index;
	$.each(archiveList, (i, v) => {
		if (v.id === selectedId) {
			found = true;
			index = i;
			return false;
		}
	});
	if (found) {
		const archive = archiveList[index];
		return checkAndInsertSubReducer(state, action, 'archive_attachments',
			archive);
	}
	return state;
};

const clearArchiveReducer = (state, action) => deleteAttachmentSubReducer(state, action, 'archive_attachments');

const isInLibraryList = (list, item) => {
	for (let i = 0; i < list.length; i++) {
		if (list[i].id == item.id) {
			return true;
		}
	}
	return false;
}
const addLibraryReducer = (state, action) => {
	const { libraryList } = action.payload;
	let attachment_list = state.library_attachments;
	let newList = [];
	if (libraryList) {
		$.each(libraryList, (i, v) => {
			if (isInLibraryList(attachment_list, v) == false) {
				newList.push(v);
			}
		});
		if (newList.length > 0) {
			return update(state, { library_attachments: { $push: newList } });
		}
	}
	return state;
};

const clearLibraryReducer = (state, action) => deleteAttachmentSubReducer(state, action, 'library_attachments');

const incCollabHistoryReducer = (state, action) => {
	return update(state, { includeCollabHistory: { $set: action.payload.value } });
}

const incErrandHistoryReducer = (state, action) => {
	return update(state, { includeErrandHistory: { $set: action.payload.value } });
}

// const clearArchiveReducer = (state, action) => {
// 	let p = action.payload;
// 	if(p.which === DEL_ANSWER_ATTACHMENT) {
// 		let found, index;
// 		$.each(state.archive_attachments, (i,v) => {
// 			if(v.id === p.afId) {
// 				found = true;
// 				index = i;
// 				return false;
// 			}
// 		});
// 		if(found) {
// 			return update(state, {archive_attachments: {
// 				$splice: [[index, 1]]}});
// 		} else {
// 			console.log('dbg: delete archive not found:', p.afId);
// 		}
// 	}
// 	return state;
// };

const updateCheckboxes = (state, type, value) => update(state, { checkboxes: { [type]: { $set: value } } });

const updateCheckboxesReducer = (state, { payload }) => {
	const { type, value } = payload;
	return updateCheckboxes(state, type, value);
};

const createReducerSetValueById = field => (state, action) => {
	const { id, value } = action.payload;
	return update(state, { [field]: { [id]: { $set: value } } });
};

const updateAllValueByFieldReducer = () => (state, action) => {
	const { value, reply } = action.payload;
	if (reply === RPLY_ERRAND) {
		const field = "question_attachments";
		const currentVals = state[field];
		if (!currentVals) {
			return state;
		} else {
			let newData = {};
			$.each(currentVals, function (id, v) {
				newData[id] = value;
			});
			return update(state, { [field]: { $merge: newData } });
		}
	}
	return state;
};

const makeCheckboxUpdater = field => (state, data) => updateCheckboxes(state, field, boolStringToBool(data));

const makeUpdaterByFunction = (field, func) => (state, data) => update(state, { [field]: { $set: func(data) } });

const syncReviewDataSubReducers = {
	"include_same_thread_errands": makeCheckboxUpdater(ECB_INC_HISTORIES)
	, "update_channel": makeUpdaterByFunction("update_channel", parseInt)
	, "update_close": makeCheckboxUpdater(ECB_PARTIAL_ANSWER)
	, "update_forward": makeUpdaterByFunction("update_forward", convertEmailsToArr)
	, "update_library": makeCheckboxUpdater(ECB_SUGGEST_TO_LIBRARY)
	, "update_include_question": makeCheckboxUpdater(ECB_INC_QUESTION)
	, "selected_history_attachments": makeUpdaterByFunction("selected_history_attachments", csvStringToIntArray)
};

const inputsReducers = {
	[CKEDITOR_REFORMAT_INPUT]: (state, action) => {
		const { plain, value } = action.payload;
		return update(state, {
			update_answer: { $set: value },
			plain_answer: { $set: plain }
		});
	},
	[CLEAR_COLLABORATE_ANS_ATTACHMENT]: (state, action) => simpleDeleteAttachmentSubReducer(state, action, 'update_external_expert_Answer_attachments'),
	[DELETE_SAVED_ATTACHMENT]: (state, action) => deleteAttachmentSubReducer(state, action, 'saved_attachments'),
	[DELETE_SAVED_INTERNAL_COMMENT_ATTACHMENT]: (state, action) => simpleDeleteAttachmentSubReducer(state, action, 'internal_comment_saved_attachments'),
	[ERRAND_NOTES_OPERATION]: notesOperationReducer,
	[INSERT_COLLABORATE_ANS_ATTACHMENT]: (state, action) => checkAndInsertSubReducer(state, action, 'update_external_expert_Answer_attachments', action.payload),
	[SET_ERRAND_WF_SETTINGS]: setDefaultSettings,
	[UPDATE_REPLY_CHECKBOXES]: updateCheckboxesReducer,
	[ERRAND_CLASSIFICATION]: classificationReducer,
	[ERRAND_ANSWER_STATE]: answerStateReducer,
	[XFER_DOMAIN_EXTEND_DATA]: classificationPreviousTagsReducer,
	[SELECT_QUESTION_ATTACHMENT]: createReducerSetValueById("question_attachments"),
	[SELECT_ALL_QUESTION_ATTACHMENT]: updateAllValueByFieldReducer("question_attachments"),
	[SELECT_EXTERNALFORWARD_ATTACHMENT]: createReducerSetValueById("selected_externalforward_attachment"),
	[SELECT_HISTORY_ATTACHMENT]: createReducerSetValueById("selected_history_attachments"),
	[SELECT_FORWARD_ALL_Q_ATTACHMENTS]: (state, action) => {
		const { value } = action.payload;
		let results = {};
		$.each(state.selected_externalforward_attachment, (k, v) => {
			if (v !== value) {
				v = value;
			}
			results[k] = v;
		});
		return update(state, { selected_externalforward_attachment: { $set: results } });
	},
	[SOCIAL_MEDIA_MSG_SAVE_ACTION]: socialMediaInputReducersData,
	[UPLOAD_ERRAND_ANSWER_ATTACHMENT]: uploadAttachmentsReducer,
	[UPLOAD_INTERNAL_COMMENT_ATTACHMENT]: (state, action) => uploadedAttachmentSubReducer(state, action, 'internal_comment_uploaded_attachments'),
	[DELETE_UPLOADED_ATTACHMENT]: deleteUploadedAttachmentReducer,
	[DELETE_UPLOADED_INTERNAL_COMMENT_ATTACHMENT]: (state, action) => simpleDeleteAttachmentSubReducer(state, action, 'internal_comment_uploaded_attachments'),
	[ADD_FILE_ARCHIVE_TO_ANSWER]: addArchiveReducer,
	[ADD_LIBRARY_FILE_TO_ANSWER]: addLibraryReducer,
	[SELECTED_LIBRARY_QUESTION_TO_ANSWER]: (state, action) => {
		const { selectedId } = action.payload;
		return update(state, {
			selected_library_question: { $set: selectedId },
		});
	},
	[CLEAR_SELECTED_LIBRARY]: clearLibraryReducer,
	[CLEAR_SELECTED_ARCHIVE]: clearArchiveReducer,
	[SET_ANSWER_TRANSLATION]: setAnswerTranslation,
	[SET_WHATSAPP_TEMPLATE_CONTENT]: (state, action) => {
		const { id, content } = action.payload;
		return update(state, {
			templContent: { $set: content },
			templId: { $set: id }
		});
	},
	[SET_QUICK_REPLY_CONTENT]: (state, action) => {
		const { id, content } = action.payload;
		return update(state, {
			quickReplyCont: { $set: content },
			quickReplyId: { $set: id }
		});
	},
	[SAVE_WHATSAPP_TEMPLATE_CODE]: (state, action) => {
		const { code } = action.payload;
		return update(state, {
			templCode: { $set: code }
		});
	},
	[SYNC_REVIEW_DATA]: (state, { payload }) => {
		$.each(syncReviewDataSubReducers, (k, f) => {
			const data = payload[k];
			if (typeof data !== "undefined") {
				state = f(state, data, payload);
			}
		});
		return state;
	}
};

const inputsReducer = createReducer(errandInputsInitState, inputsReducers);

function updateMailAttachmentsReducer(state, action, updated_attachments) {
	if (!updated_attachments || !updated_attachments.mail) {
		// reset question attachments
		return questionAttachmentsReducer(state, action);
	}
	const mail = updated_attachments.mail.slice();
	let changed
		, newData = {}
		, { question_attachments } = state.inputs
		;
	$.each(question_attachments, (k, v) => {
		const id = k + "";
		$.each(mail, (j, w) => {
			if (w.from === id) {
				mail.splice(j, 1);
				newData[k] = false;
				if (!changed) {
					changed = true;
				}
				return false;
			}
		});
	});
	if (!changed) {
		return state;
	}
	return update(state, { inputs: { question_attachments: { $merge: newData } } });
}

function checkAndUpdateAttachment(state, action, newInputs, field) {
	if (state.inputs[field].length) {
		if (!newInputs) {
			newInputs = { [field]: { $set: emptyArray } };
		} else {
			newInputs[field] = { $set: emptyArray };
		}
	}
	return newInputs;
}

function updatingAttachment(
	state
	, action
	, updated_attachments
	, newInputs
	, field
	, changed
) {
	if (!updated_attachments) {
		return checkAndUpdateAttachment(state, action, newInputs, field);
	}
	let input = state.inputs[field];
	if (input && input.length) {
		const updated = updated_attachments[changed];
		if (updated) {
			const noChange = [];
			let newData = emptyArray;
			$.each(input, (i, v) => {
				const id = v.id + "";
				let found;
				$.each(updated, (j, w) => {
					if (w.from === id) {
						found = true;
						return false;
					}
				});
				if (!found) {
					noChange.push(v);
				}
			});
			if (noChange.length) {
				newData = noChange;
			}
			if (!newInputs) {
				newInputs = { [field]: { $set: newData } };
			} else {
				newInputs[field] = { $set: newData };
			}
		}
	}
	return newInputs;
}

const saveErrandDoneReducer = (state, action) => {
	if (action.type !== done(keyUpdateAnswer)
		|| state.updateAnswer.data.error) {
		return state;
	}
	let newInputs
		, { updated_attachments } = state.updateAnswer.data
		;
	// avoid same attachments is created again.
	state = updateMailAttachmentsReducer(
		state
		, action
		, updated_attachments
	);
	newInputs = updatingAttachment(
		state
		, action
		, updated_attachments
		, newInputs
		, 'archive_attachments'
		, 'archive'
	);
	newInputs = updatingAttachment(
		state
		, action
		, updated_attachments
		, newInputs
		, 'uploaded_attachments'
		, 'upload'
	);
	newInputs = updatingAttachment(
		state
		, action
		, updated_attachments
		, newInputs
		, 'uploaded_attachments'
		, 'temp'
	);
	newInputs = updatingAttachment(
		state
		, action
		, updated_attachments
		, newInputs
		, 'library_attachments'
		, 'library'
	);
	newInputs = updatingAttachment(
		state
		, action
		, updated_attachments
		, newInputs
		, 'update_external_expert_Answer_attachments'
		, 'collaboration'
	);
	if (newInputs) {
		return update(state, {
			inputs: newInputs
			, ui: { lastSave: { previous: newInputs } }
		});
	}
	return state;
};

const updateSavedAttachmentsSubReducer = (state, action, attachments, updateAttachmentReducer) => {
	if (!attachments
		|| !attachments.length
		|| state.inputs.saved_attachments.length == attachments.length) {
		// if server reply saved attachments 0 length then the only possible
		// this happen is right before trigger update answer endpoint, saved
		// attachments length already 0. This is because saved attachment length
		// can only change to non-zero when archive or uploaded attachments more
		// than zero and/or some save attachments is/area deleted but not all.
		// If all saved attachments are deleted then the front-end saved
		// attachments already hold the zero length value. Saved attachments
		// list can only increase when there is any archive or uploaded
		// attachments and stay no-change when there isn't any. This still true
		// for special auto-save update-errand.
		return state;
	}
	// directly update last save saved attachments because last save snapshot
	// inputs object before update answer endpoint send to server. The value of
	// saved attachments will change and can only be known after server reply
	// the result. The only way last snapshot out of sync with backend server is
	// there is archive or uploaded attachments carry by last update answer
	// endpoint.
	state = updateAttachmentReducer(state, action);
	return update(state, {
		inputs: { saved_attachments: { $set: attachments } },
		ui: { lastSave: { previous: { saved_attachments: { $set: attachments } } } }
	});
};

const updateSavedAttachmentsReducer = (state, action) =>
	updateSavedAttachmentsSubReducer(state, action, state.updateAnswer.data.answer_mail_attachments, saveErrandDoneReducer);

const updateSavedTextReducer = (state, action) => {
	const { update_plain, update_answer } = state.updateAnswer.data;
	if (typeof update_answer === "undefined" &&
		typeof update_plain === "undefined") {
		return state
	}
	let newUpdate = {};
	if (typeof update_answer !== "undefined") {
		newUpdate.update_answer = { $set: update_answer };
	}
	if (typeof update_plain !== "undefined") {
		newUpdate.plain_answer = { $set: update_plain };
	}
	return update(state, { inputs: newUpdate });
};

const sendAnswerDoneReducer = (state, action) => {
	if (action.type !== done(keySendAnswer) || !state.sendAnswer.data ||
		!state.sendAnswer.data.updated_attachments) {
		return state;
	}
	let newInputs
		, { updated_attachments } = state.sendAnswer.data
		;
	// avoid same attachments is created again.
	// state = updateMailAttachmentsReducer(
	// 	state
	// 	, action
	// 	, updated_attachments
	// );
	newInputs = updatingAttachment(
		state
		, action
		, updated_attachments
		, newInputs
		, 'archive_attachments'
		, 'archive'
	);
	newInputs = updatingAttachment(
		state
		, action
		, updated_attachments
		, newInputs
		, 'uploaded_attachments'
		, 'upload'
	);
	newInputs = updatingAttachment(
		state
		, action
		, updated_attachments
		, newInputs
		, 'uploaded_attachments'
		, 'temp'
	);
	newInputs = updatingAttachment(
		state
		, action
		, updated_attachments
		, newInputs
		, 'library_attachments'
		, 'library'
	);
	newInputs = updatingAttachment(
		state
		, action
		, updated_attachments
		, newInputs
		, 'update_external_expert_Answer_attachments'
		, 'collaboration'
	);
	if (newInputs) {
		return update(state, {
			inputs: newInputs
			, ui: { lastSave: { previous: newInputs } }
		});
	}
	return state;
}

const updateSendAnswerSavedAttachmentsReducer = (state, action) => {
	// if sendAnswer success no process needed
	if (action.type !== done(keySendAnswer) || !state.sendAnswer.data ||
		!state.sendAnswer.data.updated_attachments) {
		return state;
	}
	// update saved attachment
	return updateSavedAttachmentsSubReducer(state, action, state.sendAnswer.data.answer_mail_attachments, sendAnswerDoneReducer);
}

const saveCollabDoneReducer = (state, action) => {
	if (action.type !== done(keyUpdateAnswerEE)
		|| state.updateAnswerEE.data.error) {
		return state;
	}
	let newInputs
		, { updated_attachments } = state.updateAnswerEE.data
		;
	// avoid same attachments is created again.
	state = updateMailAttachmentsReducer(
		state
		, action
		, updated_attachments
	);
	// newInputs = updatingAttachment( // mak: not sure why we need this
	// 	state
	// 	, action
	// 	, updated_attachments
	// 	, newInputs
	// 	, 'archive_attachments'
	// 	, 'archive'
	// );
	newInputs = updatingAttachment(
		state
		, action
		, updated_attachments
		, newInputs
		, 'uploaded_attachments'
		, 'upload'
	);
	// newInputs = updatingAttachment( // mak: not sure why we need this
	// 	state
	// 	, action
	// 	, updated_attachments
	// 	, newInputs
	// 	, 'uploaded_attachments'
	// 	, 'temp'
	// );
	// newInputs = updatingAttachment( // mak: not sure why we need this
	// 	state
	// 	, action
	// 	, updated_attachments
	// 	, newInputs
	// 	, 'library_attachments'
	// 	, 'library'
	// );
	// newInputs = updatingAttachment( // mak: not sure why we need this
	// 	state
	// 	, action
	// 	, updated_attachments
	// 	, newInputs
	// 	, 'savedQueryID'
	// 	, 'queryID'
	// );
	if (newInputs) {
		return update(state, {
			collaborationInputs: newInputs
			, ui: { lastSaveEE: { previous: newInputs } }
		});
	}
	return state;
};

const updateSavedAttachmentsCollabSubReducer = (state, action, attachments, updateAttachmentReducer) => {
	if (!attachments
		|| !attachments.length
		|| state.collaborationInputs.saved_attachments.length == attachments.length) {
		// if server reply saved attachments 0 length then the only possible
		// this happen is right before trigger update answer endpoint, saved
		// attachments length already 0. This is because saved attachment length
		// can only change to non-zero when archive or uploaded attachments more
		// than zero and/or some save attachments is/area deleted but not all.
		// If all saved attachments are deleted then the front-end saved
		// attachments already hold the zero length value. Saved attachments
		// list can only increase when there is any archive or uploaded
		// attachments and stay no-change when there isn't any. This still true
		// for special auto-save update-errand.
		return state;
	}
	// directly update last save saved attachments because last save snapshot
	// inputs object before update answer endpoint send to server. The value of
	// saved attachments will change and can only be known after server reply
	// the result. The only way last snapshot out of sync with backend server is
	// there is archive or uploaded attachments carry by last update answer
	// endpoint.
	state = updateAttachmentReducer(state, action);
	return update(state, {
		collaborationInputs: { saved_attachments: { $set: attachments } },
		ui: { lastSaveEE: { previous: { saved_attachments: { $set: attachments } } } }
	});
};

const updateSavedAttachmentsCollabReducer = (state, action) =>
	updateSavedAttachmentsCollabSubReducer(state, action, state.updateAnswerEE.data.answer_mail_attachments, saveCollabDoneReducer);

const updateSavedTextCollabReducer = (state, action) => {
	const { queryID } = state.updateAnswerEE.data;
	if (typeof queryID === "undefined") {
		return state
	}
	return update(state, { collaborationInputs: { savedQueryID: { $set: queryID } } });
};

// these are those reducers that depend on certain AJAX request and affect
// errand inputs. It need to sit after AJAX request processing.
const _postAJAXInputsReducers = {
	[done(keyUpdateAnswer)]: reduceReducers(
		updateSavedAttachmentsReducer
		, updateSavedTextReducer
	),
	[done(keySendAnswer)]: updateSendAnswerSavedAttachmentsReducer,
	[done(keyUpdateAnswerEE)]: reduceReducers(
		updateSavedAttachmentsCollabReducer
		, updateSavedTextCollabReducer
	)
};

const postAJAXInputsReducer = createReducer(null, _postAJAXInputsReducers);

const defManualAccountAddressReducer = (state, action) => {
	const newIndexes = update(state.selected_indexes, {
		account: {
			$set: UNSELECT
		}
	});
	return update(state, {
		selected_indexes: { $set: newIndexes },
		update_to: { $set: [] },
		selected_social_media: { $set: '' }
	});
};

const selectSocialMediaAccountReducer = (state, action) => update(state, {
	selected_indexes: { account: { $set: action.payload.dbID } },
	selected_social_media: { $set: action.payload.value }
});

const manualErrandCreateLimitedChannelReducer = (state, action) => {
	const v = action.payload;
	if (v === ME_CREATE) {
		if (!ME_CREATE_ALLOWED_CHANNELS[state.reply_channel]) {
			// reset channel to email as it is allowed for both create/start
			// manual errand.
			return update(state, { reply_channel: { $set: RC_EMAIL } });
		}
	}
	return state;
};

const selectedIndexesInit = {
	subject: UNSELECT,
	account: UNSELECT
};

// Manual Errand reducer code - any reducer under this branch should be added
// top of this line as not break the init state and array of reducers.
const manualInputsInitState = {
	area: 0,
	tags: [],
	internal_comment: '',
	plain_internal_comment: '',
	internal_comment_uploaded_attachments: [],
	internal_comment_saved_attachments: [],
	answer_state: E_A_UNKNOWN,
	answer_id: 0,
	checkboxes: DEF_ALL_MANUAL_CHECKBOXES_STATE,
	// NOTE: do NOT direct access this field but use selector
	// manualErrandSelectedReplyChannel.
	reply_channel: RC_EMAIL,
	update_area_id: 0,
	update_channel: 0,
	update_from: '', // string or integer
	update_tags: '', // update from array TODO: seem useless
	selected_group_tags: '',
	selected_social_media: '',
	selected_indexes: selectedIndexesInit,
	translate_to: "",
	update_subject: '',
	update_attachment_total: 0,
	update_salutation: 0,
	update_signature: 0,
	update_signature_default: false,
	update_salutation_default: false,
	update_to: [],
	update_cc: [],
	update_bcc: [],
	// update_lock: false,
	// update_close: false,
	update_priority: false,
	// update_library: '',
	update_question: '',
	plain_answer: '',
	update_answer: '',
	update_answer_forward: '',
	update_question_subject: '',
	uploaded_attachments: [],
	saved_attachments: [],
	archive_attachments: [],
	question_attachments: [],
	library_attachments: []
};

const clearManualErrandInputsReducer = (state, action) => update(state,
	{ $set: manualInputsInitState });

const clearManualCallInputsReducer = (state, action) => update(state,
	{ $set: manualInputsInitState });

const manualInputsReducers = {
	[ADD_FILE_ARCHIVE_TO_MANUAL]: addArchiveReducer,
	[DEF_MANUAL_ACC_ADDR]: defManualAccountAddressReducer,
	[DELETE_UPLOADED_MANUAL_ATTACHMENT]: (state, action) => simpleDeleteAttachmentSubReducer(state, action, 'uploaded_attachments'),
	[DELETE_ALL_UPLOADED_MANUAL_ATTACHMENT]: (state, action) => deleteAllUploadedAttachments(state, action),
	[UPLOAD_MANUAL_INTERNAL_COMMENT_ATTACHMENT]: (state, action) => uploadedAttachmentSubReducer(state, action, 'internal_comment_uploaded_attachments'),
	[UPDATE_MANUAL_ERRAND_SAVED_ATTACHMENTS]: (state, action) => uploadedManualErrandSavedAttSubReducer(state, action),
	[DELETE_UPLOADED_MANUAL_INTERNAL_COMMENT_ATTACHMENT]: (state, action) => simpleDeleteAttachmentSubReducer(state, action, 'internal_comment_uploaded_attachments'),
	[CLEAR_MANUAL_ERRAND_INPUTS]: clearManualErrandInputsReducer,
	[CLEAR_SELECTED_MANUAL_ARCHIVE]: (state, action) => simpleDeleteAttachmentSubReducer(state, action, 'archive_attachments'),
	[MANUAL_FOOTER_CHECKBOXES]: updateCheckboxesReducer,
	[SELECT_MANUAL_ERRAND]: manualErrandCreateLimitedChannelReducer,
	[SELECT_MANUAL_SOCIAL_MEDIA_ACCOUNT]: selectSocialMediaAccountReducer,
	[UPLOAD_MANUAL_ERRAND_ATTACHMENT]: uploadAttachmentsReducer,
	[ADD_LIBRARY_FILE_TO_MANUAL]: addLibraryReducer,
	[DELETE_KB_MANUAL_ATTACHMENT]: (state, action) => simpleDeleteAttachmentSubReducer(state, action, 'library_attachments'),
	[SET_MANUAL_ERRAND_TRANSLATE_TO]: (state, action) => update(state, {
		translate_to: { $set: action.payload }
	})
};

const manualCallInputsReducers = {
	[ADD_FILE_ARCHIVE_TO_MANUAL]: addArchiveReducer,
	[DEF_MANUAL_ACC_ADDR]: defManualAccountAddressReducer,
	[DELETE_UPLOADED_MANUAL_ATTACHMENT]: (state, action) => simpleDeleteAttachmentSubReducer(state, action, 'uploaded_attachments'),
	[DELETE_ALL_UPLOADED_MANUAL_ATTACHMENT]: (state, action) => deleteAllUploadedAttachments(state, action),
	[UPLOAD_MANUAL_INTERNAL_COMMENT_ATTACHMENT]: (state, action) => uploadedAttachmentSubReducer(state, action, 'internal_comment_uploaded_attachments'),
	[UPDATE_MANUAL_ERRAND_SAVED_ATTACHMENTS]: (state, action) => uploadedManualErrandSavedAttSubReducer(state, action),
	[DELETE_UPLOADED_MANUAL_INTERNAL_COMMENT_ATTACHMENT]: (state, action) => simpleDeleteAttachmentSubReducer(state, action, 'internal_comment_uploaded_attachments'),
	[CLEAR_MANUAL_CALL_INPUTS]: clearManualCallInputsReducer,
	[CLEAR_SELECTED_MANUAL_ARCHIVE]: (state, action) => simpleDeleteAttachmentSubReducer(state, action, 'archive_attachments'),
	[MANUAL_FOOTER_CHECKBOXES]: updateCheckboxesReducer,
	[SELECT_MANUAL_ERRAND]: manualErrandCreateLimitedChannelReducer,
	[UPLOAD_MANUAL_CALL_ERRAND_ATTACHMENT]: uploadAttachmentsReducer,
	[ADD_LIBRARY_FILE_TO_MANUAL]: addLibraryReducer,
	[DELETE_KB_MANUAL_ATTACHMENT]: (state, action) => simpleDeleteAttachmentSubReducer(state, action, 'library_attachments'),
	[SET_MANUAL_ERRAND_TRANSLATE_TO]: (state, action) => update(state, {
		translate_to: { $set: action.payload }
	})
};

// Manual errand inputs
const manualInputsReducer = createReducer(manualInputsInitState,
	manualInputsReducers);

// Manual call inputs
const manualCallInputsReducer = createReducer(manualInputsInitState,
	manualCallInputsReducers
)

const clearColInputsReducer = (state, action) => update(state,
	{ $set: colInputsInitState });

const expandCollabThreadReducer = (state, action) => {
	let expand = {expand: action.payload.expand, queryId: action.payload.qId};
	return update(state, {
	expandCollabThread: {
		$merge : {
			[action.payload.threadID]: expand
		}
	}
	})
}

const colInputsReducers = {
	[CKEDITOR_REFORMAT_INPUTEE]: (state, action) => {
		const { plain, value, } = action.payload;
		return update(state, {
			update_answer: { $set: value }
		});
	},
	[ADD_FILE_ARCHIVE_TO_COLLABORATION]: addArchiveReducer,
	[DELETE_SAVED_COL_ATTACHMENT]: (state, action) => deleteAttachmentSubReducer(state, action, 'saved_attachments'),
	[CHANGE_COLLABORATION_ROLE]: (state, action) => {
		const { role, threadID, queryID } = action.payload;
		if (role === ROLE_AGENT) {
			return update(state, {
				replyQuery: { $set: false },
				threadID: { $set: threadID }
			});
		} else {
			return update(state, {
				queryID: { $set: queryID },
				replyQuery: { $set: true },
				threadID: { $set: threadID }
			});
		}
	},
	[CLEAR_COLLABORATION_INPUTS]: clearColInputsReducer,
	[COLLAB_QUERY_THREAD_EXPAND]: expandCollabThreadReducer,
	[COL_CLEAR_SELECTED_ARCHIVE]: clearArchiveReducer,
	[DELETE_UPLOADED_COL_ATTACHMENT]: deleteUploadedAttachmentReducer,
	[SELECT_EXIST_ATTACHMENT]: (state, action) => {
		const { id, value } = action.payload;
		return update(state, { selectedAttachment: { [id]: { $set: value } } });
	},
	[CLEAR_INTERNAL_COLLABORATORS]: (state, action) => update(state, {
		selectedAgents: { $set: [] }
	}), // NOTE: COL#1
	[SET_COLLABORATION_TRANSLATE_TO]: (state, action) => update(state, {
		translateTo: { $set: action.payload }
	}),
	[UPDATE_COLLABORATE_CB]: (state, action) => {
		const { type, value } = action.payload;
		let key;
		switch (type) {
			case COL_COMPLETE_ERRAND_HISTORIES:
				key = 'includeErrandHistory';
				break;
			case COL_SAVE_RECIPIENT:
				key = 'saveRecipient';
				break;
		}
		if (!key) {
			return state;
		}
		return update(state, { [key]: { $set: value } });
	},
	[UPDATE_EXPERT_ANS_STATE]: (state, action) => update(state, {
		answer_state: { $set: action.payload }
	}),
	[UPDATE_EXPERT_IDS]: (state, action) => {
		const { errandID, threadID, queryID, answerID } = action.payload;
		return update(state, {
			errandID: { $set: errandID },
			threadID: { $set: threadID },
			queryID: { $set: queryID },
			answerID: { $set: answerID }
		});
	},
	[ADD_LIBRARY_FILE_TO_COLLABORATE]: addLibraryReducer,
	[COL_CLEAR_SELECTED_LIBRARY]: clearLibraryReducer,
	[SELECT_INCLUDE_COLLAB_HISTORY]: incCollabHistoryReducer,
	[SELECT_INCLUDE_ERRAND_HISTORY]: incErrandHistoryReducer,
	[UPLOAD_COLLABORATION_ATTACHMENT]: uploadAttachmentsReducer,
	[SELECT_ALL_QUESTION_ATTACHMENT]: (state, action) => {
		const { value, reply } = action.payload;
		if (reply === RPLY_COLLABORATE) {
			const field = "selectedAttachment";
			const currentVals = state[field];
			let newData = {};
			$.each(currentVals, function (id, v) {
				newData[id] = value;
			});
			return update(state, { [field]: { $merge: newData } });
		}
		return state;
	},
};

const contactBookUIReducer = (st, act) => {
	if (act.type === SET_CURRENT_ERRAND) {
		return update(st, {
			contactBook: {
				$set: contactBookUIInit
			}
		});
	} else if (act.type === SHOW_CONTACT_BOOK) {
		const p = act.payload;
		if (!p) {
			return update(st, {
				contactBook: {
					$set: contactBookUIInit
				}
			});
		} else {
			return update(st, {
				contactBook: {
					show: { $set: p }
				}
			});
		}
	} else if (act.type === SET_REPLY_ADDRESS_TYPE) {
		const p = act.payload;
		let tab = CB_ALL_CONTACT;
		if (p.context === RPLY_MANUAL
			|| p.context === RPLY_ERRAND
			|| p.context === RPLY_EXT_FWD
			|| p.context === ADD_TO_CONTACT_CARD) {
			tab = CB_CONTACT_CARD;
		}
		return update(st, {
			contactBook: {
				replyType: { $set: p.replyType },
				context: { $set: p.context },
				currentTab: { $set: tab }
			}
		});
	} else if (act.type === SET_CONTACT_BOOK_UI) {
		const p = act.payload;
		return update(st, {
			contactBook: {
				[p.field]: { $set: p.type }
			}
		});
	}
	return st;
};

const chatTranslationReducer = (st, act) => {
	if (act.type === SET_CHAT_TRANSLATION) {
		let id = act.payload.id;
		let obj = act.payload.obj;

		if (st.chatTranslation[id]) {
			return update(st, { chatTranslation: { [id]: { $merge: obj } } });
		}
		return update(st, { chatTranslation: { [id]: { $set: obj } } });
	}
	return st;
}

const gdprReducer = (st, act) => {
	if (act.type === GRANT_EXPORT) {
		const data = act.payload;
		return update(st, {
			gdpr: {
				exportLogs: { $set: data.Logs ? data.Logs : (data.Log ? data.Log : []) },
				exportPath: { $set: data.Path },
				exportSecret: { $set: data.Secret },
				exportError: { $set: data.Error },
				exportTimestampEnabled: { $set: data.TimestampEnabled },
				exportExpirySecs: { $set: data.ExpirySecs },
				exportTimeZones: { $set: data.TimeZones },
				selectedLang: { $set: data.LangCode },
				selectedTZ: { $set: data.TimeZoneId }
			}
		});
	}
	else if (act.type === REVOKE_GRANTED_ACCESS) {
		const data = act.payload;
		return update(st, {
			gdpr: {
				exportLogs: { $set: data.Logs },
				exportPath: { $set: data.Path },
				exportError: { $set: data.Error }
			}
		});
	}
	else if (act.type === SET_SELECTED_TZ) {
		return update(st, {
			gdpr: {
				selectedTZ: { $set: act.payload }
			}
		})
	}
	else if (act.type === SET_SELECTED_LANG) {
		return update(st, {
			gdpr: {
				selectedLang: { $set: act.payload }
			}
		})
	}
	return st;
}

const contactCardUIReducer = (st, act) => {
	if (act.type === SET_CURRENT_ERRAND) {
		return update(st, { contactCard: { $set: contactCardUIInit } });
	} else if (act.type === SHOW_CONTACT_CARD) {
		return update(st, { contactCard: { show: { $set: act.payload } } });
	} else if (act.type === SHOW_CONTACT_CARD_HISTORY) {
		return update(st, { contactCard: { showContactHistory: { $set: act.payload } } });
	} else if (act.type === SHOW_CONTACT_CARD_CHANNEL) {
		return update(st, { contactCard: { showChannelOptions: { $set: act.payload } } });
	} else if (act.type === SET_CONTACT_CARD) {
		const p = act.payload;
		//TK-todo: check refactor compability
		if (p.field === "selectedChannel"
			|| p.field === "channelType"
			|| p.field === "notesButton"
			|| p.field === "customLabel"
			|| p.field === "contactInput"
			|| p.field === "contact"
			|| p.field === "noteText"
			|| p.field === "checkAnsweredHistory"
			|| p.field === "checkUnansweredHistory"
			|| p.field === "showMore"
			|| p.field === "noteAttachment"
			|| p.field === "name"
			|| p.field === "avatar"
			|| p.field === "filename"
			|| p.field === "filetype"
			|| p.field === "city"
			|| p.field === "PostCode" //todo: use lowercase on all contact fields?
			// || p.field === "postcode" //todo: use lowercase on all contact fields?
			|| p.field === "externalId"
			|| p.field === "company"
			|| p.field === "error") {
			return update(st, {
				contactCard: {
					[p.field]: { $set: p.value }
				}
			});
		} else if (p.field === "avatarPreview") {
			return update(st, {
				contactCard: {
					[p.field]: { $set: p.value },
					avatarFilename: { $set: p.filename },
					avatarFiletype: { $set: p.filetype }
				}
			});
		}
	} else if (act.type === SELECT_CONTACT_CARD_ANSWERED_HISTORY) {
		return update(st, {
			contactCard: {
				checkAnsweredHistory: { $set: act.payload }
			}
		});
	} else if (act.type === SELECT_CONTACT_CARD_UNANSWERED_HISTORY) {
		return update(st, {
			contactCard: {
				checkUnansweredHistory: { $set: act.payload }
			}
		});
	} else if (act.type === UPLOAD_CUSTOMER_NOTE_ATTACHMENT) {
		return update(st, {
			contactCard: {
				noteAttachment: { $push: [act.payload] }
			}
		});
	} else if (act.type === DELETE_NOTE_ATTACHMENT) {
		const index = st.contactCard.noteAttachment.findIndex(x => x.id === act.payload);
		return update(st, {
			contactCard: {
				noteAttachment: { $splice: [[index, 1]] }
			}
		});
	} else if (act.type === SHOW_COMPANY_INFO) {
		return update(st, {
			contactCard: {
				showCompanyInfo: { $set: act.payload }
			}
		});
	}
	return st;
};

const setContactCardReducer = (st, act) => {
	if (act.type === UPDATE_CONTACT_BOOK) {
		let p = act.payload;
		if (p.field === "list") {
			return update(st, { currentErrand: { contactBook: { $set: p.obj } } });
		} else if (p.field === "failed") {
			return update(st, { ui: { contactBook: { error: { $set: p.obj } } } });
		}
	} else if (act.type === UPDATE_CONTACT_CARD) {
		let p = act.payload;
		if (p.field === "list") {
			let customer = {}, clist = [], cui = {};
			let channel = [];
			if (typeof p.obj.channel != 'undefined') {
				channel = p.obj.channel;
			}
			channel.push({ id: "0", name: "Custom", type: 0 }); //any service
			if (typeof p.obj.list != 'undefined') {
				clist = p.obj.list;
			}
			if (typeof p.obj.customer != 'undefined') {
				let c = p.obj.customer;
				customer = {
					id: c.id,
					name: c.name,
					city: c.city,
					date: c.date,
					avatar: c.avatar,
					postcode: c.postcode,
					externalId: c.externalId,
					companyId: c.company,
					company: c.company, //todo: re-work naming on both errand and admin reducer
					channels: getUniqueChannels(clist),
					companyName: c.companyName
				};
				cui = {
					avatarPreview: c.avatar,
					name: c.name,
					id: c.id,
					city: c.city,
					date: c.date,
					avatar: c.avatar,
					PostCode: c.PostCode,
					// postcode: c.postcode,
					externalId: c.externalId,
					companyId: c.company,
					company: c.company, //todo: re-work naming on both errand and admin reducer
					channels: getUniqueChannels(clist),
					companyName: c.companyName
				}
			} else {
				let b = st.basic.data;
				if (st.chat != null) {
					b = st.chat.errand;
				}
				let serviceId = 0;
				$.each(p.obj.channel, (k, v) => {
					if (v.type === b.data.service) {
						serviceId = v.id;
						return false;
					}
				});
				customer = {
					id: 0,
					name: b.data.fromName,
					date: "",
					avatar: "",
					companyId: 0,
					companyName: "",
					channels: ""
				};
				cui = {
					channelType: b.data.service,
					selectedChannel: serviceId,
					contactInput: b.data.fromAddress,
					serviceType: b.data.service, //for new UI
					contact: b.data.fromAddress, //for new UI
					name: b.data.fromName,
					error: ""
				};
			}
			return update(st, {
				currentErrand: {
					contactCard: {
						channel: { $set: channel },
						customer: { $merge: customer },
						contactList: { $set: clist }
					}
				},
				ui: { contactCard: { $merge: cui } }
			});
		} else if (p.field === "add") {
			if (p.obj.contact) {
				return update(st, {
					currentErrand: {
						contactCard: {
							contactList: { $push: [p.obj.contact] },
							customer: { $merge: p.obj.customer }
						}
					}
				});
			} else {
				return update(st, {
					currentErrand: {
						contactCard: {
							customer: { $merge: p.obj.customer }
						}
					}
				});
			}
		} else if (p.field === "remove") {
			const index = st.currentErrand.contactCard.contactList.findIndex(x => x.id === p.obj);
			return update(st, { currentErrand: { contactCard: { contactList: { $splice: [[index, 1]] } } } });
		} else if (p.field === "failed" || p.field === "error") {
			return update(st, { ui: { contactCard: { error: { $set: p.obj } } } });
		} else if (p.field === "companyId") {
			return update(st, {
				currentErrand: {
					contactCard: {
						customer: { $merge: p.obj }
					}
				}
			});
		}
	} else if (act.type === SET_CUSTOMER_NOTES) {
		let p = act.payload;
		if (p != null) {
			return update(st, { currentErrand: { customerNotes: { $set: p } } });
		}
	} else if (act.type === UPDATE_CUSTOMER_NOTE) {
		let p = act.payload;
		if (p.field === "add") {
			return update(st, { currentErrand: { customerNotes: { $push: [p.obj] } } });
		} else if (p.field === "remove") {
			const index = st.currentErrand.customerNotes.findIndex(x => x.id === p.obj);
			return update(st, { currentErrand: { customerNotes: { $splice: [[index, 1]] } } });
		} else if (p.field === "edit") {
			let newNotes = st.currentErrand.customerNotes.map(note => {
				if(note.id === p.obj.id) {
					return p.obj;
				}
				return note
			});
			return update(st, { currentErrand: { customerNotes: { $set: newNotes } } });
		}
	}
	return st;
}

const collaborationInputsReducer = createReducer(colInputsInitState, colInputsReducers);

const updateLightSubReducer = (state, action, errandID, light) => {
	const e = state.data;
	if (!e || !e.data.collaboration || e.id != errandID) {
		return state;
	}
	return update(state, {
		data: {
			data: {
				collaboration: {
					light: {
						$set: light
					}
				}
			}
		}
	});
};

const updateLockToMeReducer = (state, action, errandID, lock) => {
	const e = state.data;
	if (!e || e.id != errandID) {
		return state;
	}
	return update(state, {
		data: {
			data: {
				locked: {
					$set: lock
				}
			}
		}
	})
}

const updateMyErrandsReducers = {
	[wfDone(keyLinkErrand)]: (state, action) => {
		if (features['link-errand']) {
			if (state.data && state.data.norm) {
				const {data} = action.payload;
				let newNorm = state.data.norm;

				if ( Object.keys(newNorm).length > 0 ) {
					// link
					if (data && data.eidListAffected && data.eidListAffected.length > 0) {
						$.each(data.eidListAffected, (i,v) => {
							if ( newNorm[v] ) {
								newNorm = update(newNorm, {[v]: {
									linkedId: {$set: data.linkedId},
									linkedErrands: {$set: data.linkedErrands}
								}})
							}
						})
						return update(state, {data: {norm: {$set: newNorm}}});
					}

					// unlink
					if (data && data.unlinkedItems && data.unlinkedItems.length > 0) {
						$.each(data.unlinkedEidListAffected, (i,v) => {
							if (newNorm[v]) {
								newNorm = update(newNorm, {[v]: {
									linkedId: {$set: 0},
									linkedErrands: {$set: null}
								}})
							}
						})

						return update(state, {data: {norm: {$set: newNorm}}});
					}
				}
			}
		}
		return state;
	}
};

const updateLinkedErrandsReducers = {
	[wfDone(keyLinkErrand)]: (state, action) => {
		if (features['link-errand']) {
			if (state.data && state.data.norm) {
				const {data} = action.payload;
				let norm = state.data.norm;
				let preOpr = state.data.linkedOpr;
				let list = state.data.list;

				// link
				if (data && data.linkedItems && data.linkedItems.length > 0) {
					let norm2 = {}
						, newOrder = []
						, newOpr = {}
						, newList = [];

					$.each(data.linkedErrandsState, (i, v) => {
						const id = v.id;
						norm2[id] = v;
						newList.push(v)
						newOrder.push(id);
						if (preOpr) {
							if (preOpr[id]) {
								newOpr[id] = preOpr[id] ;
							} else {
								newOpr[id] = { selected: false };
							}
						} else {
							newOpr[id] = { selected: false };
						}
					});

					return update(state, { data: {
								norm      : {$set: norm2},
								order     : {$set: newOrder},
								linkedOpr : {$set :newOpr},
								list      : {$set: newList}
							}} );
				}

				// unlink
				if (data && data.unlinkedItems && data.unlinkedItems.length > 0) {
					let newNorm = {}
						, newOrder = []
						, newOpr = {}
						, newList = [] ;

					$.each(norm, (i,v) => {
						let found = false
						if (data.unlinkedEidListAffected) {
							found = data.unlinkedEidListAffected.includes(v.id)
						}
						if (found == false) {
							newNorm [v.id]= v
							newOrder.push(v.id)
							newList.push(v)
							newOpr[v.id] = { selected: false };
						}
					})

					return update(state, {data: {
								norm      : {$set: newNorm},
								order     : {$set: newOrder},
								linkedOpr : {$set :newOpr},
								list      : {$set: newList}
							}});
				}

			}
		}
		return state;
	}
};

const updateBasicReducers = {
	[wfDone(keyLinkErrand)]: (state, action) => {
		const { data } = action.payload;
		if (features['link-errand']) {
			// link
			if (data && data.linkedItems && data.linkedItems.length > 0) {
				if (state.data && state.data.data) {
					if (state.data.data.linkedErrands) {
						return update(state, { data: { data: { linkedErrands: { $push: data.linkedItems } } } })
					}
					let newLinkedItems = data.linkedItems;
					if (data.current && data.current.length > 0) {
						newLinkedItems = update(data.linkedItems, {$push: data.current});
					}
					return update(state, { data: { data: {
						linkedErrands: { $set: newLinkedItems },
						linkedId: { $set: data.linkedId },
						errandLinkDate: { $set: data.linkedDate }
					} } })
				}
			}

			// unlink
			if (data && data.unlinkedItems && data.unlinkedItems.length > 0) {
				if (state.data && state.data.data) {
					if (state.data.data.linkedErrands) {
						var linkedId = state.data.data.linkedId
							, errandLinkDate = state.data.data.errandLinkDate
						if (!data.linkedErrands) {
							linkedId = 0
							errandLinkDate = null
						}
						return update(state, { data: { data: {
							linkedErrands: {$set: data.linkedErrands},
							linkedId: { $set: linkedId },
							errandLinkDate: { $set: errandLinkDate }
						} } })
					}
				}
			}
		}
		return state;
	}
	, [done(keyFetchExternalExpertList)]: (state, action) => {
		const { data, param } = action.payload;
		if (data && data.error) {
			return state;
		}
		const id = param.errand, light = data.extra.light;
		return updateLightSubReducer(state, action, id, light);
	}
	, [done(keyTurnExternalExpertLightOff)]: (state, action) => {
		// directly change the light state for collaboration without reloading
		// the light data. BASIC branch.
		const { data, param } = action.payload;
		if (data && data.error) {
			return state;
		}
		const id = param.errand, light = data.light;
		return updateLightSubReducer(state, action, id, light);
	}
	, [SYNC_ALL_BASIC_ERRAND_DATA]: (state, action) => {
		const { data } = action.payload;
		if (state.data && state.data.id === data.id) {
			return update(state, { data: { $set: data } });
		}
		return state;
	}
	, [SYNC_ACQUIRED_STATE]: (state, action) => {
		if (!state.data || !state.data.id) {
			return state;
		}
		const currentID = state.data.id
			, { errandIDs, data } = action.payload
			;
		let found;
		$.each(errandIDs, (i, v) => {
			if (v == currentID) {
				found = true;
				return false;
			}
		});
		if (!found) {
			return state;
		}
		return update(state, { data });
	}
	, [TOGGLE_LOCK_TO_ME]: (state, action) => {
		const { id, lock } = action.payload;
		if (!id) {
			return state;
		}
		return updateLockToMeReducer(state, action, id, lock);
	}
};

const updateBasicReducer = createReducer({}, updateBasicReducers);
const updateMyErrandsReducer = createReducer({}, updateMyErrandsReducers);
const updateLinkedErrandsReducer = createReducer({}, updateLinkedErrandsReducers);

function selectErrandFromAcquireTabReducer(state, action) {
	if (action.type !== ACQUIRE_ERRAND_OPERATION) {
		return state;
	}
	const { opr, value } = action.payload;
	if (opr !== SEL_ACT_OTHER_CONTACT && opr !== SEL_ACT_ACQ_SELECT) {
		return state;
	}
	const { id, select } = value
		, newOpr = { opr: { [id]: { selected: { $set: select } } } }
		;
	if (opr === SEL_ACT_ACQ_SELECT) {
		return update(state, { related: newOpr });
	} else if (opr === SEL_ACT_OTHER_CONTACT) {
		return update(state, { oc: newOpr });
	}
	return state;
}

const initErrandErrand = { isChat: false };
const initialState = {
	summarizedHistory: null,
	loading: false,
	error: null,
};

const agentAssistInitState = {
	agentAssist: null,
	loading: false,
	error: null,
};

const summarizedHistoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_SUMMARIZED_HISTORY_REQUEST':
			return {
				...state,
				loading: true,
				error: null,
			};
		case 'FETCH_SUMMARIZED_HISTORY_SUCCESS':
			return {
				...state,
				loading: false,
				summarizedHistory: action.payload,
			};
		case 'FETCH_SUMMARIZED_HISTORY_FAILURE':
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

let errand = combineReducers({
	sumHistory : summarizedHistoryReducer,
	basic: reduceReducers(
		basicErrand(),
		basicErrandMCAM(),
		updateBasicReducer
	),
	contacts: reduceReducers(
		otherContacts(keyErrandContacts)
		, resetContactsOnOpenErrandReducer
		, acquiringOtherErrandsReducer
		, hasOpenErrandReducer
		, syncOneErrandOnNormalizedList
		, syncRelatedErrandsNormalized
		, moveToAssociateReducer
	),
	myErrands: reduceReducers(
		errandMyErrands(keyErrandMyErrands)
		, updateMyErrandsReducer
	),
	linkedErrands: reduceReducers(
		errandLinkedErrands(keyFetchLinkedErrands)
		, updateLinkedErrandsReducer
	),
	historyContacts: reduceReducers(
		otherContacts(keyErrandContactsHistories, true)
		, resetContactsOnOpenErrandReducer
		, syncOneErrandOnNormalizedList
	),
	companyHistoryContacts: reduceReducers(
		otherContacts(keyCompanyContactHistories, true)
		, resetContactsOnOpenErrandReducer
		, syncOneErrandOnNormalizedList
	),
	companyOtherContacts: reduceReducers(
		otherContacts(keyCompanyOtherContacts, true)
		, resetContactsOnOpenErrandReducer
		, syncOneErrandOnNormalizedList
	),
	acquireOpr: reduceReducers(
		acquireAssociateReducer
		, selectErrandFromAcquireTabReducer
		, (state, action) => {
			const { type, payload } = action;
			if (type !== SHOW_HIDE_OCE_POPUP) {
				return state;
			}
			return update(state, { showPopup: { $set: payload } });
		}
		, (state, action) => {
			const { type, payload } = action;
			if (type !== INITIAL_OPEN_ERRAND_THREAD_ID || !payload) {
				return state;
			}
			return update(state, { initialThreadId: { $set: payload } });
		}
		, (state, action) => {
			const { type, payload } = action;
			if (type !== SET_CURRENT_ERRAND) {
				return state;
			}
			const { id, isSwitching, chat } = payload;
			let previousState
				, updater = { ocId: { $set: id } }
				;
			if (isSwitching) {
				previousState = state;
			} else {
				previousState = defAssociate;
				if (chat) {
					updater.related = {
						opr: { [id]: { $set: { selected: true } } }
						, order: { $push: [id] }
					};
				}
			}
			return update(previousState, updater);
		}
		, (state, action) => {
			const { type, payload } = action;
			if (type !== CHAT_SWITCH_OC_ERRAND) {
				return state;
			}
			return update(state, { ocId: { $set: payload.id } });
		}
		, (state, action) => {
			if (action.type !== SYNC_RELATED_ERRANDS) {
				return state;
			}
			const { id, errands: es, isSwitching } = action.payload
				, errands = es.slice()
				, firstOpen = !isSwitching
				, selected = { selected: firstOpen }
				, { related, oc } = state
				, [rOpr, rOrder] = arrayOfOperandAndShallowOrder(related)
				, [oOpr, oOrder] = arrayOfOperandAndShallowOrder(oc)
				;
			let newOpr = {}
				, newOrder = []
				, ocUnset = []
				;
			$.each(errands, (i, v) => {
				let foundInRelated
					, foundInOC
					;
				$.each(rOrder, (j, w) => {
					if (v === w) {
						rOrder.splice(j, 1);
						foundInRelated = true;
						return false;
					}
				});
				$.each(oOrder, (j, w) => {
					if (v === w) {
						oOrder.splice(j, 1);
						ocUnset.push(w);
						foundInOC = true;
						return false;
					}
				});
				if (v === id) {
					newOpr[v] = { selected: true };
				} else {
					let preOpr;
					if (foundInRelated) {
						preOpr = rOpr[v];
					}
					if (!preOpr && foundInOC) {
						preOpr = oOpr[v];
					}
					if (firstOpen || !preOpr) {
						newOpr[v] = selected;
					} else {
						newOpr[v] = preOpr;
					}
				}
				newOrder.push(v);
			});
			return update(state, {
				related: {
					opr: { $set: newOpr }
					, order: { $set: newOrder }
				}
				, oc: {
					opr: {
						$apply: opr => {
							let newOpr;
							if (ocUnset.length) {
								newOpr = update(opr, { $unset: ocUnset });
							} else {
								newOpr = opr;
							}
							if (rOrder.length) {
								const mergeOpr = {};
								$.each(rOrder, (i, v) => {
									mergeOpr[v] = rOpr[v];
								});
								newOpr = update(newOpr, { $merge: mergeOpr });
							}
							return newOpr;
						}
					}
					, order: { $set: oOrder.concat(rOrder) }
				}
			});
		}
		, (state, action) => {
			if (action.type !== SYNC_OTHER_CONTACT_ERRANDS) {
				return state;
			}
			const { id, errands: es, isSwitching } = action.payload
				, errands = es.slice()
				, firstOpen = !isSwitching
				, selected = { selected: firstOpen }
				, { related, oc } = state
				, [rOpr, rOrder] = arrayOfOperandAndShallowOrder(related)
				, [oOpr, oOrder] = arrayOfOperandAndShallowOrder(oc)
				;
			let newOpr = {}
				, newOrder = []
				, unset = []
				;
			$.each(errands, (i, v) => {
				let foundInRelated
					, foundInOC
					;
				$.each(oOrder, (j, w) => {
					if (v === w) {
						oOrder.splice(j, 1);
						foundInOC = true;
						return false;
					}
				});
				$.each(rOrder, (j, w) => {
					if (v === w) {
						rOrder.splice(j, 1);
						unset.push(w);
						foundInRelated = true;
						return false;
					}
				});
				// do not include current openned errand id into bottom other
				// contact errands.
				if (v !== id) {
					let preOpr;
					if (foundInOC) {
						preOpr = oOpr[v];
					}
					if (!preOpr && foundInRelated) {
						preOpr = rOpr[v];
					}
					if (firstOpen || !preOpr) {
						newOpr[v] = selected;
					} else {
						newOpr[v] = preOpr;
					}
					newOrder.push(v);
				}
			});
			return update(state, {
				related: {
					opr: {
						$apply: opr => {
							if (!unset || !unset.length) {
								return opr;
							}
							return update(opr, { $unset: unset });
						}
					}
					, order: { $set: rOrder }
				}
				, oc: {
					$apply: oc => {
						newOrder = newOrder.concat(oOrder);
						newOrder.sort((a, b) => b - a); // reverse sort
						return update(oc, {
							opr: { $merge: newOpr }
							, order: { $set: newOrder }
						});
					}
				}
			});
		}
		, (state, action) => {
			const { type, payload } = action;
			if (type !== CHANGE_OTHER_CONTACT_SHOW_IDS || !payload) {
				return state;
			}
			const { errand, thread } = payload;
			if (!errand && !thread) {
				return state;
			}
			let updateState = {};
			if (errand) {
				updateState.showId = { $set: errand };
			}
			if (thread) {
				updateState.showThreadId = { $set: thread };
			}
			return update(state, updateState);
		}
	),
	workingOnErrand: workingOnErrand(),
	suggestedAnswer: suggestedAnswer(),
	changeErrandArea: changeErrandArea(),
	changeErrandPostponedate: changeErrandArea(),
	externalExpertEdit: externalExpertEdit(),
	fetchClientAddress: fetchClientAddress(),
	fetchClientsAddressList: reduceReducers(
		fetchClientsAddressList(),
		clientAddressListWithOtherContact()
	),
	fetchExtendedData: reduceReducers(
		fetchExtendedData()
		, fetchExtendedDataMCAM()
		, (state, action) => {
			const { type, payload } = action;
			if (type !== SYNC_EXTENDED_DATA) {
				return state;
			}
			return update(state, { data: { data: { $set: payload.data } } });
		}
		, (state, action) => {
			const { type, payload } = action;
			if (type !== ERRAND_NOTES_COUNT) {
				return state;
			}
			return update(state, { data: { data: { errand_notes: { $set: payload.errand_notes } } } });
		}
	),
	fetchExternalQueries: fetchExternalQueries(),
	fetchHistory: reduceReducers(
		fetchHistory()
		, fetchHistoryMCAM()
		, selectFwdReducer
		, (state, { type, payload }) => {
			if (type !== SYNC_REVIEW_DATA) {
				return state;
			}
			const data = payload.update_history;
			if (!data) {
				return state;
			}
			const newOpr = {};
			if (data == "all") {
				newOpr.all = true;
			} else {
				const ints = csvStringToIntArray(data)
					, selected = { selected: true }
					;
				$.each(ints, (i, v) => {
					newOpr[v] = selected;
				});
			}
			return update(state, { data: { opr: { $merge: newOpr } } });
		}
		, createReducerAsyncDataBranch(
			createDataSyncReducer(createSetupCondtionReducer(
				(state, action, checker, changer) => {
					const result = checker(state, action)
					if (!result) {
						return state
					}
					return changer(state, result.index)
				},
				ERRAND_HISTORIES
			))
		)
	),
	otherContactHistory: defAsyncReducer(keyOtherContactHistory, asyncInitState),
	acquireErrand: reduceReducers(
		acquireErrand()
		, getOneRelatedErrandAsyncReducer
		, (state, action) => {
			const { type, payload } = action;
			if (type == SYNC_ALL_BASIC_ERRAND_DATA && typeof payload.data.data
				!== 'undefined' && typeof payload.data.data.body !== 'undefined'
				&& typeof payload.data.data.answer !== 'undefined') {
					if (features['link-errand']) {
						if (state.data && state.data.acquire  && state.data.acquire.errand === payload.data.id) {
							return update(state, { data: { acquire: { data: { question_body: { $set: payload.data.data.body }, answer_body: { $set: payload.data.data.answer } } } } });
						}
					}
					return state;
			}
			if (type == SYNC_ACQUIRED_OWNER) {
				return update(state, { data: { acquire: { owner: { $set: payload.owner } } } });
			} else if (type != SYNC_ACQUIRE_DATA) {
				return state;
			}
			return update(state, { data: { acquire: { $set: payload.data } } });
		}
	),
	uploadAnswerAttachment: uploadAnswerAttachment(),
	removeTemporaryAttachment: removeTemporaryAttachment(),
	errandAreaData: errandAreaData(),
	turnExternalExpertLightOff: turnExternalExpertLightOff(),
	sendAnswer: sendAnswer(),
	updateAnswer: updateAnswer(),
	updateManualErrand: updateManualErrand(),
	sendEE: sendEE(),
	updateAnswerEE: updateAnswerEE(),
	savedEE: savedEE(),
	reopenErrand: reopenErrand(),
	resendAnswer: resendAnswer(),
	publishErrand: publishErrand(),
	unpublishErrand: unpublishErrand(),
	errandSuggestToLibrary: errandSuggestToLibrary(),
	inputs: inputsReducer,
	manualInputs: manualInputsReducer,
	manualCallInputs: manualCallInputsReducer,
	collaborationInputs: collaborationInputsReducer,
	currentErrand: reduceReducers(openErrandReducer, updateOpenErrandState),
	chat: currentChatReducer,
	currentChatErrandId: (st = -1, act) => {
		if (act.type == SET_CURRENT_ERRAND) {
			if (act.payload.chat) {
				return act.payload.chat.errand.id;
			} else {
				return -1;
			}
		}
		return st;
	},
	errand: (st = initErrandErrand, act) => {
		if (act.type == SET_CURRENT_ERRAND) {
			return update(st, { isChat: { $set: !!act.payload.chat } });
		}
		return st;
	},
	ui: reduceReducers(
		errandUILocalReducer
		, updateLastSaveReducer
		, updateLastSaveCollabReducer
		, contactBookUIReducer
		, contactCardUIReducer
		, chatTranslationReducer
		, gdprReducer
		, (state, action) => {
			const { payload, type } = action;
			if (type !== SET_CLEAR_PREVIEW_SAVE_EML_BUSY) {
				return state;
			}
			return update(state, { previewOrSaveEmlBusy: { $set: !!payload } });
		}
		, createReducerSubBranch(
			(state, value) => update(state, { [KB_UI_STATE]: { $set: value } })
			, ({ [KB_UI_STATE]: state }) => state
			, createReducer(knowledgeBaseUIInit, uiKnowledgebaseReducerMap)
		)
	),
	printContent: fetchPrintContent(),
	fetchAreaTags: fetchAreaTags(),
	ratings: fetchRatings(),
	reactions: fetchReactions(),
	dataAnonymize: errandAnonymize(),
	dataExportLog: errandDataExportLog(),
	dataRevokeAccess: errandDataRevokeAccess(),
	dataExport: errandDataExport(),
	userMembership: userHasMembership(),
	recordEditorSize: recordEditorSize(),
});

const domainTransferReducers = {
	[XFER_DOMAIN_AREA_DATA]: domainTransferReducer('errandAreaData'),
	[XFER_CHANGED_DOMAIN_AREA_DATA]: domainTransferReducer('errandAreaData'),
	[XFER_DOMAIN_EE_EDIT]: reduceReducers(
		domainTransferReducer('externalExpertEdit'),
		updateCollaborationInputsReducer,
		updateInputsCollaborationChannelReducer,
	)
};

errand = reduceReducers(
	errand,
	createReducer({}, domainTransferReducers),
	setCurrentErrand,
	setCurrentChatErrandReducer,
	selectAcquireReducer,
	selectAllAcquireReducer,
	selectAllAcquireLinkErrandReducer,
	selectAllAcquireLinkedReducer,
	updateOpenErrandReadyReducer,
	reduceReducers(errandInputsReducer, postAJAXInputsReducer),
	createReducer({}, uiReducers),
	setContactCardReducer,
	setPreviousErrand,
	setCurrentErrandOpening,
);

export default errand;

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// App Branch Reducers
////////////////////////////////////////////////////////////////////////////////
function updateDefaultLockReducer(state, action) {
	const wf = state.workflow.fetchWfSettings.data;
	if (!wf.lockToMe) {
		return state;
	}
	return update(state, {
		errand: {
			manualInputs: {
				checkboxes: {
					[ECB_LOCK2ME]: {
						$set: wf.lockToMeCheckBoxDefaultState
					}
				}
			}
		}
	});
}

function updateDefaultPartialReducer(state, action) {
	const wf = state.workflow.fetchWfSettings.data;
	if (!wf.closeCheckBoxDefaultState) {
		return state;
	}
	return update(state, {
		errand: {
			inputs: {
				checkboxes: {
					[ECB_PARTIAL_ANSWER]: {
						$set: wf.closeCheckBoxDefaultState
					}
				}
			}
		}
	});
}

function updateDefaultIncludeQuestionReducer(state, action) {
	const wf = state.workflow.fetchWfSettings.data;
	if (!wf.toggleIncludeQuestion || wf.toggleIncludeQuestionDefaultNo) {
		return state;
	}
	// default include question state will change only if the checkbox button
	// appear.
	return update(state, {
		errand: {
			inputs: {
				checkboxes: {
					[ECB_INC_QUESTION]: {
						$set: true
					}
				}
			}
		}
	});
}

function updateDefaultIncludeAllHistoryReducer(state, action) {
	const wf = state.workflow.fetchWfSettings.data;
	if (!wf.showIncludeAllHistories || !wf.includeAllHistorySelected) {
		return state;
	}
	return update(state, { errand: { inputs: { checkboxes: { [ECB_INC_HISTORIES]: { $set: true } } } } });
}

// TODO: what this code doing? Look useless and weird
function updateDefaultReplyTabShow(state, action) {
	const wf = state.workflow.fetchWfSettings.data;
	let showReplyTab = state.errand.ui.reply.show;
	return update(state, {
		errand: {
			ui: {
				reply: {
					show: {
						$set: showReplyTab
					}
				}
			}
		}
	});
}

function updateDefaultReplyUIReducer(state, action) {
	const wf = state.workflow.fetchWfSettings.data;
	let showReplyPanel = false;
	if (wf) {
		showReplyPanel = wf.showReplyPanel;
		let newReplyState = {
			showReplyToolbar: { $set: wf.showReplyToolbar },
			showReplyAssist: { $set: wf.showReplyAssist },
			showRecipients: { $set: wf.showReplyRecipients },
			showSubject: { $set: wf.showReplySubject },
			selectedReplyTab: { $set: wf.selectedReplyTab },
			show: { $set: showReplyPanel }
		};
		let newState = { reply: newReplyState };

		return update(state, { errand: { ui: newState } });
	}
	return state;
}

// NOTE: app is app level reducer which allow the state to access any state
// within app branch. This is need when errand need to access workflow possessed
// state at reducer. Any app reducer always execute later than its source
// branch. For example, errand here is executed first then only app. Do NOT
// abuse this branch, only put reducer under this when needed only. If you do
// not know what function of this reducer then likely you do NOT need it.
export const app = {
	[APP_READY]: reduceReducers(
		updateDefaultLockReducer
		, updateDefaultPartialReducer
		, updateDefaultIncludeAllHistoryReducer
		, updateDefaultIncludeQuestionReducer
		, updateDefaultReplyUIReducer
	)
	, [CLEAR_MANUAL_ERRAND_INPUTS]: updateDefaultLockReducer
	, [SET_ERRAND_WF_SETTINGS]: reduceReducers(
		updateDefaultPartialReducer
		, updateDefaultIncludeAllHistoryReducer
		, updateDefaultIncludeQuestionReducer
		, updateDefaultReplyTabShow
	)
};
